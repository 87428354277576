// import React, { useContext, useEffect, useMemo, useState } from "react";
// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";
// import { DatePicker, Space } from "antd";
// import dayjs from "dayjs";

// import {
//   searchIcon,
//   calendarIcon,
//   projectsIcon,
//   InvoiceIcon,
//   employeeIcon,
//   usersIcon,
// } from "../../../../utils/ImportingImages/ImportingImages";
// import Select from "react-select";
// import { Tooltip } from "react-tooltip";
// import Breadcrumbs from "../../../../templates/Breadcrumbs";
// import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
// import PageHeader from "../../../../templates/PageHeader";
// import ReportsTable from "../../components/ReportsTable";
// import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
// import { reportsIcon } from "../../../../utils/ImportingImages/ImportingImages";
// import { TeamWiseReportModal } from "./TeamWiseReportModel";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
// import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
// import axios from "axios";
// import {
//   handleAPIError,
//   headerOptions,
// } from "../../../../utils/utilities/utilityFunctions";
// import TeamWiseReport from "./TeamWiseReport";

// // Bread Crumbs
// const breadCrumbs = [
//   {
//     pageName: "Home",
//     pageURL: "/dashboard",
//   },
//   {
//     pageName: "Team Wise",
//     pageURL: "/team-wise",
//   },
// ];

// // constructing Headers for React Skelton
// const columnHeaders = [
//   "Sr no.",
//   "Team Code",
//   "Team Leader",
//   "Team Name",
//   "Edit",
// ];

// // constructing headers for CSV Link
// const headers = {
//   headings: [
//     { label: "Team Code", key: "team_code" },
//     { label: "Team Name", key: "team_name" },
//     { label: "Team Leader", key: "leader_name" },
//     { label: "Members Count", key: "member_count" },
//   ],
//   fileName: "Team Wise List",
// };

// const TeamWiseContent = ({
//   teamWiseReportData,
//   setIsUpdated,
//   isLoading,
//   showMore = true,
// }) => {
//   const userRole = localStorage.getItem("userRole");
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const [filters, setFilters] = useState({
//     status: null,
//     assignedTo: null,
//   });

//   const statusOptions = [
//     { label: "In Progress", value: "In Progress" },
//     { label: "On Hold", value: "On Hold" },
//     { label: "Completed", value: "Completed" },
//   ];

//   const tableColumns = [
//     {
//       Header: "Sr no.",
//       accessor: "sr no.",
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       Header: "Team Code",
//       accessor: "team_code",
//     },
//     {
//       Header: "Team Name",
//       accessor: "team_name",
//     },
//     {
//       Header: "Team Leader",
//       accessor: "leader_name",
//       Cell: ({ row }) => {
//         const firstLetter = row.original.leader_name
//           ? row.original.leader_name.slice(0, 1).toUpperCase()
//           : "N.A";
//         return (
//           firstLetter +
//           (row.original.leader_name ? row.original.leader_name.slice(1) : "N.A")
//         );
//         // const firstLetter = row.original.leader_name.slice(0, 1).toUpperCase();
//         // return firstLetter + row.original.leader_name.slice(1);
//       },
//     },
//     {
//       Header: "Members Count",
//       accessor: "member_count",
//       Cell: ({ row }) => +row.original.member_count,
//     },

//     {
//       Header: "Action",
//       Cell: ({ row }) => (
//         <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
//           <Tooltip
//             id="delete-client-tooltip"
//             style={{
//               background: "#000",
//               color: "#fff",
//             }}
//             opacity={0.9}
//           />
//           <div
//             style={{
//               // marginRight: "47px",
//               cursor: "pointer",
//             }}
//             data-tooltip-id="delete-client-tooltip"
//             data-tooltip-content="Show more"
//             data-tooltip-place="top"
//           >
//             <TeamWiseReportModal
//               teamWiseData={row.original}
//               setIsUpdated={setIsUpdated}
//             />
//           </div>
//         </div>
//       ),
//     },
//   ];

//   const { RangePicker } = DatePicker;

//   const [teamWiseList, setTeamWiseList] = useState([]);
//   const { initialState, allMemberReports, mainURL } = useContext(ContextAPI);

//   const userId = localStorage.getItem("userId") ?? null;
//   const [loading, setLoading] = useState(false);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());

//   const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");
//   const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");

//   const [dates, setDates] = useState({
//     rangeStartDate: null,
//     rangeEndDate: null,
//   });

//   // const handleRangeChange = (selectedDates) => {
//   //   if (selectedDates && selectedDates.length > 0) {
//   //     setSelectedStartDate(selectedDates[0]);
//   //     setSelectedEndDate(selectedDates[1]);
//   //     setDates({
//   //       rangeStartDate: selectedDates[0],
//   //       rangeEndDate: selectedDates[1],
//   //     });
//   //   } else {
//   //     setDates({
//   //       rangeStartDate: null,
//   //       rangeEndDate: null,
//   //     });
//   //   }
//   // };
//   const handleRangeChange = (selectedDates) => {
//     if (selectedDates && selectedDates.length === 2) {
//       const [start, end] = selectedDates;
//       const currentDate = dayjs();
//       if (start.isBefore(currentDate) && end.isBefore(currentDate)) {
//         setSelectedStartDate(start);
//         setSelectedEndDate(end);
//         setDates({
//           rangeStartDate: start,
//           rangeEndDate: end,
//         });}
//     } else {
//       setDates({
//         rangeStartDate: null,
//         rangeEndDate: null,
//       });
//     }
//   };

//   useEffect(() => {
//     if (dates.rangeStartDate === null && dates.rangeEndDate === null) {
//       const currentDate = dayjs();
//       const formattedStartDate = currentDate.format("YYYY-MM-DD");
//       const formattedEndDate = currentDate.format("YYYY-MM-DD");
//       const url = `${mainURL}reports/team-wise/${userId}/${formattedStartDate}/${formattedEndDate}`;

//       const fetchData = async () => {
//         try {
//           const result = await axios.get(url, { headers: headerOptions() });
//           const teamWiseReports = result?.data?.Team_wise_reports ?? [];
//           setTeamWiseList(teamWiseReports);
//         } catch (error) {
//           console.error("Error fetching team-wise reports:", error);
//         }
//       };

//       fetchData();
//     }
//   }, [dates]);

//   const disabledFutureDates = (current) => {
//     return current && current > dayjs().endOf("day");
//   };
//   const handleShowTeamWiseList = async () => {
//     setLoading(true);
//     const url = `${mainURL}reports/team-wise/${userId}/${formattedStartDate}/${formattedEndDate}`;

//     try {
//       const result = await axios.get(url, { headers: headerOptions() });
//       const teamWiseReports = result?.data?.Team_wise_reports ?? [];
//       setTeamWiseList(teamWiseReports);
//     } catch (error) {
//       console.error("Error fetching Team-wise reports:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   // useEffect(() => {
//   //   handleShowTeamWiseList();
//   // }, []);

//   // const columns = useMemo(() => tableColumns, []);

//   // const data = useMemo(() => teamWiseReportData, [teamWiseReportData]);
//   const columns = useMemo(() => tableColumns, []);
//   const data = useMemo(() => teamWiseList, [teamWiseList]);

//   const tableInstance = useTable(
//     {
//       columns,
//       data,
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       {/* Top header section */}
//       <div className="mb-5 relative-wrapper zIndex-2">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={usersIcon}
//           // icon={reportsIcon}
//           headerTitle={
//             userRole.includes("team_leaders") ? "Member List " : "Team Wise"
//           }
//         ></PageHeader>
//       </div>
//       <div className="mr-40 ml-30 mt-5 mb-15 d-flex  gap-4">
//         <div className="relative-wrapper ">
//           <img className="search-icon" src={calendarIcon} alt="search-icon" />
//           <Space direction="vertical">
//             <RangePicker
//               disabledDate={disabledFutureDates}
//               onChange={handleRangeChange}
//                 format="DD-MM-YYYY"
//               className="react-select-custom-styling__control"
//             />
//           </Space>
//         </div>
//         <div>
//           <button className="custom-btn" onClick={handleShowTeamWiseList}>
//             {loading ? <SpinningLoader /> : " Show List"}
//           </button>
//         </div>
//       </div>
//       {/* <div className="ml-30 mr-40 relative-wrapper w-75">
//         <img className="search-icon" src={reportsIcon} alt="search-icon" />
//         <Select
//           closeMenuOnSelect={true}
//           isClearable={true}
//           options={statusOptions}
//           onChange={(option) => {
//             setFilters((prev) => ({
//               ...prev,
//               status: option,
//             }));
//           }}
//           value={filters.status}
//           placeholder="Select status"
//           className="react-select-custom-styling__container w-25"
//           classNamePrefix="react-select-custom-styling"
//         />
//       </div> */}

//       {/* Clients Table  */}
//       {/* {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : teamWiseReportData.length > 0 ? (
//         <ReportsTable
//           tableInstance={tableInstance}
//           headers={headers}
//           reportsData={teamWiseReportData}
//         />
//       ) : ( */}
//       {/* Team Wise Table  */}
//       {isLoading ? (
//         <ReactTableSkeleton columnHeaders={columnHeaders} />
//       ) : teamWiseList?.length > 0 ? (
//         <TeamWiseReport
//           tableInstance={tableInstance}
//           headers={headers}
//           teamWise={teamWiseList}
//         />
//       ) : (
//         <div className="mr-40 ml-30 mb-15">
//           <h5>No Team Wise Report Data Found !</h5>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TeamWiseContent;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import {
  searchIcon,
  calendarIcon,
  usersIcon,
} from "../../../../utils/ImportingImages/ImportingImages";

import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";

import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";

import { TeamWiseReportModal } from "./TeamWiseReportModel";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import axios from "axios";
import {
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import TeamWiseReport from "./TeamWiseReport";
import { debounce } from "lodash";

// Bread Crumbs
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Team Wise",
    pageURL: "/team-wise",
  },
];

const TeamWiseContent = ({ setIsUpdated }) => {
  const userRole = localStorage.getItem("userRole");
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { mainURL } = useContext(ContextAPI);
  const [teamWiseList, setTeamWiseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    rangeStartDate: null,
    rangeEndDate: null,
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  const { RangePicker } = DatePicker;
  const userId = localStorage.getItem("userId") ?? null;
  const fetchTeamWiseReports = async (
    startDate,
    endDate,
    page,
    limit,
    searchQuery = ""
  ) => {
    setLoading(true);
    const url = `${mainURL}reports/team-wise/${userId}/${startDate}/${endDate}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
    try {
      const result = await axios.get(url, { headers: headerOptions() });
      setTeamWiseList(result?.data?.Team_wise_reports?.team_data ?? []);
      setTotalPages(result?.data?.Team_wise_reports?.totalPages);
    } catch (error) {
      console.error("Error fetching team-wise reports:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRangeChange = (selectedDates) => {
    if (selectedDates && selectedDates.length === 2) {
      const [start, end] = selectedDates.map((date) =>
        dayjs(date).format("YYYY-MM-DD")
      );
      setDates({ rangeStartDate: start, rangeEndDate: end });
    } else {
      setDates({ rangeStartDate: null, rangeEndDate: null });
      const currentDate = dayjs().format("YYYY-MM-DD");
      fetchTeamWiseReports(
        currentDate,
        currentDate,
        currentPage,
        pageSize,
        searchInput
      );
      setCurrentPage(1);
    }
  };

  const disabledFutureDates = (current) =>
    current && current > dayjs().endOf("day");

  const columns = useMemo(
    () => [
      {
        Header: "Sr no.",
        accessor: "sr_no",
        Cell: ({ row }) => row.original.sr_no,
      },
      { Header: "Team Code", accessor: "team_code" },
      { Header: "Team Name", accessor: "team_name" },
      {
        Header: "Team Leader",
        accessor: "leader_name",
        Cell: ({ row }) =>
          row.original.leader_name
            ? `${row.original.leader_name
                .charAt(0)
                .toUpperCase()}${row.original.leader_name.slice(1)}`
            : "N.A",
      },
      {
        Header: "Members Count",
        accessor: "member_count",
        Cell: ({ row }) => +row.original.member_count,
      },
      {
        Header: "View",
        Cell: ({ row }) => (
          <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
            <TeamWiseReportModal
              teamWiseData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        ),
      },
    ],
    [setIsUpdated]
  );
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      const currentDate = dayjs().format("YYYY-MM-DD");
      fetchTeamWiseReports(
        currentDate,
        currentDate,
        currentPage,
        pageSize,
        searchQuery
      );
    }, 500),
    [currentPage, pageSize]
  );

  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      const currentDate = dayjs().format("YYYY-MM-DD");
      fetchTeamWiseReports(currentDate, currentDate, currentPage, pageSize, "");
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTriggered, debouncedSearch, currentPage, pageSize, searchInput]);

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);
  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };
  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
    if (!dates.rangeStartDate || !dates.rangeEndDate) {
      const currentDate = dayjs().format("YYYY-MM-DD");
      fetchTeamWiseReports(
        currentDate,
        currentDate,
        page,
        pageSize,
        searchInput
      );
    } else {
      setIsUpdated((prev) => !prev);
    }
  };

  const handleShowListClick = () => {
    if (dates.rangeStartDate && dates.rangeEndDate) {
      fetchTeamWiseReports(
        dates.rangeStartDate,
        dates.rangeEndDate,
        currentPage,
        pageSize,
        searchInput
      );
      setCurrentPage(1);
    } else {
      const currentDate = dayjs().format("YYYY-MM-DD");
      fetchTeamWiseReports(
        currentDate,
        currentDate,
        currentPage,
        pageSize,
        searchInput
      );
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data: teamWiseList,
      initialState: {
        pageSize: pageSize,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headers = {
    headings: [
      { label: "Team Code", key: "team_code" },
      { label: "Team Name", key: "team_name" },
      { label: "Team Leader", key: "leader_name" },
      { label: "Members Count", key: "member_count" },
    ],
    fileName: "Team Wise List",
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      {/* <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={usersIcon}
          headerTitle={
            userRole.includes("team_leaders,members") ? "Member List" : "Team Wise"
          }
        />
      </div> */}

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={usersIcon} alt="Invoice" />
          <p className="m-0 fs-4">
            {userRole.includes("team_leaders,members")
              ? "Member List"
              : "Team Wise"}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </section>

      <div className="mr-40 ml-30 mt-5 mb-15 d-flex gap-4">
        <div className="relative-wrapper">
          <img className="search-icon" src={calendarIcon} alt="search-icon" />
          <Space direction="vertical">
            <RangePicker
              disabledDate={disabledFutureDates}
              onChange={handleRangeChange}
              format="DD-MM-YYYY"
              className="react-select-custom-styling__control"
            />
          </Space>
        </div>
        <button className="custom-btn" onClick={handleShowListClick}>
          {loading ? <SpinningLoader /> : "Show List"}
        </button>
      </div>
      {loading ? (
        <ReactTableSkeleton
          columnHeaders={[
            "Sr no.",
            "Team Code",
            "Team Name",
            "Team Leader",
            "Members Count",
            "View",
          ]}
        />
      ) : teamWiseList.length > 0 ? (
        <>
          <TeamWiseReport
            tableInstance={tableInstance}
            teamWise={teamWiseList}
            headers={headers}
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            searchInput={searchInput}
          />
          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <TeamWiseReport
          tableInstance={tableInstance}
          teamWise={teamWiseList}
          headers={headers}
        />
      )}
    </div>
  );
};
export default TeamWiseContent;
