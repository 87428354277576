import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import {
  EditSVG,
  projectsIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import {
  headerOptions,
  handleAPIError,
  timeOptions,
  getTwelveHoursTime,
} from "../../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import TimePickerEditSection from "./TimePickerEditSection";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
const getTime = (entryTime) => {
  return { label: getTwelveHoursTime(entryTime), value: entryTime };
};

const MyVerticallyCenteredModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mainURL, logout, initialState, userId, userDetails } =
    useContext(ContextAPI);

  const [updatedTimeEntryDetails, setUpdatedTimeEntryDetails] = useState({
    job: {
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    },
    jobOptions: [],
    timeEntryType: props.entryDetails.time_entries_type,
    entryDescription: props.entryDetails.work_description,
    entryAs: props.entryDetails.entries_as,
    entryTime: {
      time: dayjs(props.entryDetails.working_time, "HH:mm:ss"),
      timeString: props.entryDetails.working_time,
    },
    isPOstDraft: props.entryDetails.is_post_draft,

    // entryDate: props.entryDetails.working_date,
    entryDate: {
      date: dayjs(props.entryDetails.working_date, "YYYY-MM-DD"),
      dateString: props.entryDetails.working_date,
    },
    startTime: getTime(props.entryDetails.work_start_time),
    endTime: getTime(props.entryDetails.work_end_time),
  });

  const [value, setValue] = useState({
    label: props.entryDetails.task_name,
    value: props.entryDetails.task_id,
  });
  const [valueForIndividual, setValueForIndividual] = useState({
    label: props.entryDetails.task_name,
    value: props.entryDetails.task_id,
  });
  const [valueForAdvanceBilling, setValueForAdvanceBilling] = useState({
    label: props.entryDetails.task_name,
    value: props.entryDetails.task_id,
  });
  const [valueForPostDraftIndividual, setValueForPostDraftIndividual] =
    useState({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
  const [valueForPostDraftJob, setValueForPostDraftJob] = useState({
    label: props.entryDetails.task_name,
    value: props.entryDetails.task_id,
  });

  const [teamId, setTeamId] = useState();

  useEffect(() => {
    setValueForIndividual({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
    setValue({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
    setValueForAdvanceBilling({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
    setValueForPostDraftIndividual({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
    setValueForPostDraftJob({
      label: props.entryDetails.task_name,
      value: props.entryDetails.task_id,
    });
  }, [props]);

  useEffect(() => {
    props.setNewUpdate(true);
  }, [props, updatedTimeEntryDetails]);

  const handleChange = (date, dateString) => {
    const formattedDateString = moment(dateString, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );

    setUpdatedTimeEntryDetails((prev) => ({
      ...prev,
      entryDate: { date, dateString: formattedDateString },
    }));
  };

  // for updating a time entry api
  const updateTimeEntry = async () => {
    const formattedEndDate = moment(
      updatedTimeEntryDetails.entryDate.dateString,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");

    try {
      setIsLoading(() => true);
// console.log("updatedTimeEntryDetails",updatedTimeEntryDetails)
      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        task_id: updatedTimeEntryDetails?.job?.value,
        entries_as:
          updatedTimeEntryDetails?.entryAs === "team" ? "Team" : "Member",
        entry_id: props?.entryDetails?.entries_id,
        // work_time: updatedTimeEntryDetails?.entryTime?.timeString,
        work_start_time: updatedTimeEntryDetails?.startTime?.value,
        work_end_time: updatedTimeEntryDetails?.endTime?.value,
        working_date: formattedEndDate,
        work_description: updatedTimeEntryDetails?.entryDescription,
        // time_entries_type:updatedTimeEntryDetails?.timeEntryType
        time_entries_type:
          updatedTimeEntryDetails?.isPOstDraft === "1"
            ? "post_draft"
            : updatedTimeEntryDetails?.timeEntryType ?? "billable_hours",
      };

      if (updatedTimeEntryDetails?.entryAs === "team") {
        body.team_id = initialState?.myTeamDetails?.id;
      }
      let url;
      if (updatedTimeEntryDetails?.timeEntryType === "side_works") {
        url = `${mainURL}edit/side-works-time-entries`;
      } else if (
        updatedTimeEntryDetails?.timeEntryType === "advanced_billing"
      ) {
        url = `${mainURL}edit/advance-billing-works-time-entries`;
      } else {
        url = `${mainURL}edit/time-entries`;
      }
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
        props.onHide();
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleUpdateTimeEntry = (e) => {
    e.preventDefault();

    const {
      entryAs,
      job,
      entryDescription,
      entryDate,
      startTime,
      endTime,
      timeEntryType,
    } = updatedTimeEntryDetails;

    const bool =
      entryAs &&
      // job?.value &&
      entryDate &&
      entryDescription &&
      startTime?.value &&
      endTime?.value;

    if (bool) {
      updateTimeEntry();
    } else {
      const conditions = {
        [!entryDescription]: "Please input time entry description!",
        [!endTime.value]: "Please select Entry End Time!",
        [!startTime.value]: "Please select Entry Start Time!",
        [!entryDate]: "Please select Entry Date!",
        // [!job.value]: "Please select a job!",
        [!entryAs]: "Please select entry as either team or individual!",
      };

      const errorMessage = conditions[true];

      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };
  useEffect(() => {
    if (props.entryDetails) {
      setUpdatedTimeEntryDetails({
        job: {
          label: props.entryDetails.task_name,
          value: props.entryDetails.task_id,
        },
        jobOptions: [],
        timeEntryType: props.entryDetails.time_entries_type,
        entryDescription: props.entryDetails.work_description,
        entryAs: props.entryDetails.entries_as,
        entryTime: {
          time: dayjs(props.entryDetails.working_time, "HH:mm:ss"),
          timeString: props.entryDetails.working_time,
        },
        isPOstDraft: props.entryDetails.is_post_draft,
        // entryDate: props.entryDetails.working_date,
        entryDate: {
          date: dayjs(props.entryDetails.working_date, "YYYY-MM-DD"),
          dateString: props.entryDetails.working_date,
        },

        startTime: getTime(props.entryDetails.work_start_time),
        endTime: getTime(props.entryDetails.work_end_time),
      });
    }
  }, [props.entryDetails]);

  useEffect(() => {
    setTeamId(initialState?.myTeamID);
  }, [initialState?.myTeamID]);

  //******************** */
  async function loadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            // Safely parse additional_data
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            // Build the label with the specified format
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            }) Period End Date: ${item.due_on || "N/A"}`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value); // Remove any invalid options

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      // Provide meaningful fallback
      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function MemberloadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}my-jobs/${userId || userDetails?.member_id}`,
        {
          params: {
            job_status: "In Progress",
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data.job_data.my_single_jobs.map(
        (item) => {
          try {
            // Parse the additional client data
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};

            // Create the label for the job
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.job_data?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            // Safely parse additional_data
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function postDraftJobOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "post_draft",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"}  -  ${
              item.task_name || "N/A"
            }  -  ${item.assigned_on || "N/A"}`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value); // Remove any invalid options

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      // Provide meaningful fallback
      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function postDraftMemberloadOptions(search, loadedOptions, { page }) {
    try {
      const url = `${mainURL}generated_invoices/${
        userId || userDetails?.member_id
      }`;
      const response = await axios.get(url, {
        params: {
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData =
        response?.data?.post_draft_jobs?.my_single_jobs.map((item) => {
          try {
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            }  ${item.assigned_on || "N/A"}`;
            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.post_draft_jobs?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Edit Time Entry</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdateTimeEntry}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {props?.entryDetails?.time_entries_type == "billable_hours" &&
            props?.entryDetails?.is_post_draft == "0" && (
              <div className="form-group w-100">
                <label className="form-label fw-semibold text-center mb-2 ">
                  {`Entry Type : Billable hours`}
                </label>
                {props?.entryDetails?.entries_as == "member" && (
                  <div className="form-group mt-2 w-100">
                    <label className="form-label fw-medium  mt-2 mb-3">
                      {`Assigned task as : Individual`}
                    </label>
                    <label htmlFor="jobName">Job Name:</label>
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      value={valueForIndividual}
                      loadOptions={MemberloadOptions}
                      onChange={(value) => {
                        setValueForIndividual(value);

                        setUpdatedTimeEntryDetails((prevState) => ({
                          ...prevState,
                          job: value,
                        }));
                      }}
                      additional={{
                        page: 1,
                      }}
                      placeholder={`Select ${
                        updatedTimeEntryDetails?.entryAs === "Team"
                          ? "Team"
                          : "Individual"
                      } Job`}
                      debounceTimeout={300} // Prevent excessive API calls
                      // Enhanced User Experience Features
                      noOptionsMessage={({ inputValue }) =>
                        inputValue
                          ? `No jobs found for "${inputValue}"`
                          : "Start typing to search jobs"
                      }
                      onError={(error) => {
                        ReactHotToast("Error loading jobs", "error");
                        console.error("Async Paginate Error:", error);
                      }}
                      // Custom Styles for Better Readability
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "white",
                          cursor: "pointer",
                          color: state.isSelected ? "white" : "black",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007bff"
                              : "#f1f3f5",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                )}
                {props?.entryDetails?.entries_as == "team" && (
                  <div className="form-group mt-2 w-100">
                    <label className="form-label fw-medium mb-3 ">
                      {`Assigned task as : Team`}
                    </label>
                    <label htmlFor="jobName">Job Name:</label>
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      value={value}
                      loadOptions={loadOptions}
                      defaultInputValue={props?.entryDetail?.entries_as}
                      onChange={(value) => {
                        setValue(value);
                        setUpdatedTimeEntryDetails((prevState) => ({
                          ...prevState,
                          job: value,
                        }));
                      }}
                      additional={{
                        page: 1,
                      }}
                      debounceTimeout={300} // Prevent excessive API calls
                      // Enhanced User Experience Features
                      noOptionsMessage={({ inputValue }) =>
                        inputValue
                          ? `No jobs found for "${inputValue}"`
                          : "Start typing to search jobs"
                      }
                      onError={(error) => {
                        ReactHotToast("Error loading jobs", "error");
                        console.error("Async Paginate Error:", error);
                      }}
                      // Custom Styles for Better Readability
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "white",
                          cursor: "pointer",
                          color: state.isSelected ? "white" : "black",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007bff"
                              : "#f1f3f5",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          {props?.entryDetails?.time_entries_type == "advanced_billing" && (
            <div className="form-group  mb-2 w-100">
              <label className="form-label fw-semibold text-center mb-2 mt-2">
                {`Entry Type : Advance Billing`}
              </label>
              <label htmlFor="jobName">Job Name:</label>
              <AsyncPaginate
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={valueForAdvanceBilling}
                loadOptions={advanceBillingloadOptions}
                onChange={(selectedOption) => {
                  setValueForAdvanceBilling(selectedOption);
                  setUpdatedTimeEntryDetails((prevState) => ({
                    ...prevState,
                    job: selectedOption,
                  }));
                }}
                additional={{
                  page: 1,
                }}
                placeholder={"Select job...."}
                debounceTimeout={300} // Prevent excessive API calls
                // Enhanced User Experience Features
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No jobs found for "${inputValue}"`
                    : "Start typing to search jobs"
                }
                onError={(error) => {
                  ReactHotToast("Error loading jobs", "error");
                  console.error("Async Paginate Error:", error);
                }}
                Custom
                Styles
                for
                Better
                Readability
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            </div>
          )}
          {props?.entryDetails?.is_post_draft == "1" &&
            props?.entryDetails?.time_entries_type == "billable_hours" && (
              <div className="form-group  w-100">
                <label className="form-label fw-semibold text-center mb-2 ">
                  {`Entry Type : Post Draft Changes`}
                </label>
                {props?.entryDetails?.entries_as == "member" && (
                  <div className="form-group mt-2 w-100">
                    <label className="form-label fw-medium  mt-2 mb-3">
                      {`Assigned task as : Individual `}
                    </label>
                    <label htmlFor="jobName">Job Name:</label>
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      value={valueForPostDraftIndividual}
                      loadOptions={postDraftMemberloadOptions}
                      onChange={(value) => {
                        setValueForPostDraftIndividual(value);
                        setUpdatedTimeEntryDetails((prevState) => ({
                          ...prevState,
                          job: value,
                        }));
                      }}
                      additional={{
                        page: 1,
                      }}
                      placeholder={`Select ${
                        updatedTimeEntryDetails?.entryAs === "Team"
                          ? "Team"
                          : "Individual"
                      } Job`}
                      debounceTimeout={300} // Prevent excessive API calls
                      // Enhanced User Experience Features
                      noOptionsMessage={({ inputValue }) =>
                        inputValue
                          ? `No jobs found for "${inputValue}"`
                          : "Start typing to search jobs"
                      }
                      onError={(error) => {
                        ReactHotToast("Error loading jobs", "error");
                        console.error("Async Paginate Error:", error);
                      }}
                      // Custom Styles for Better Readability
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "white",
                          cursor: "pointer",
                          color: state.isSelected ? "white" : "black",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007bff"
                              : "#f1f3f5",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                )}
                {props?.entryDetails?.entries_as === "team" && (
                  <div className="form-group mt-2 w-100">
                    <label className="form-label fw-medium mb-3 ">
                      {`Assigned task as : Team `}
                    </label>
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      value={valueForPostDraftJob}
                      loadOptions={postDraftJobOptions}
                      defaultInputValue={props?.entryDetail?.entries_as}
                      onChange={(value) => {
                        setValueForPostDraftJob(value);
                        setUpdatedTimeEntryDetails((prevState) => ({
                          ...prevState,
                          job: value,
                        }));
                      }}
                      additional={{
                        page: 1,
                      }}
                      debounceTimeout={300} // Prevent excessive API calls
                      // Enhanced User Experience Features
                      noOptionsMessage={({ inputValue }) =>
                        inputValue
                          ? `No jobs found for "${inputValue}"`
                          : "Start typing to search jobs"
                      }
                      onError={(error) => {
                        ReactHotToast("Error loading jobs", "error");
                        console.error("Async Paginate Error:", error);
                      }}
                      // Custom Styles for Better Readability
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "white",
                          color: state.isSelected ? "white" : "black",
                          ":hover": {
                            backgroundColor: state.isSelected
                              ? "#007bff"
                              : "#f1f3f5",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "black",
                        }),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          {props?.entryDetails?.time_entries_type == "side_works" && (
            <div className="form-group mt-2 w-100">
              <label className="form-label fw-semibold text-center mt-0.5 ">
                {`Entry Type: Side work`}
              </label>
              <label className="form-label fw-medium  mt-2 mb-2 ">
                {`Assigned task as : ${
                  props?.entryDetails?.entries_as == "member"
                    ? "Individual"
                    : "Team"
                }`}
              </label>
            </div>
          )}
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDate">Job Date:</label>
            <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              value={updatedTimeEntryDetails.entryDate.date}
              onChange={handleChange}
              name="jobDate"
              allowClear={false}
              format="DD-MM-YYYY"
              disabledDate={(current) => new Date() < new Date(current)}
            />
          </div>
          <div className="d-flex align-items-center flex-column w-100">
            <div className="form-group mt-4 w-100">
              <label htmlFor="startTime">Start Time (hh:mm):</label>
              <TimePickerEditSection
                onChange={(time) => {
                  setUpdatedTimeEntryDetails((prevState) => ({
                    ...prevState,
                    startTime: {
                      value: `${time}`,
                      label: `${time}`,
                    },
                  }));
                }}
                existingTime={updatedTimeEntryDetails.startTime.value}
              />
            </div>
            <div className="form-group mt-4 w-100">
              <label htmlFor="endTime">End Time (hh:mm):</label>
              <TimePickerEditSection
                key={props.entryDetails?.work_end_time}
                onChange={(time) => {
                  setUpdatedTimeEntryDetails({
                    ...updatedTimeEntryDetails,
                    endTime: {
                      value: `${time}`,
                      label: `${time}`,
                    },
                  });
                }}
                existingTime={
                  props.entryDetails?.work_end_time.value ||
                  updatedTimeEntryDetails?.endTime?.value
                }
              />
            </div>
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Work Description:</label>
            <textarea
              id="jobDescription"
              name="jobDescription"
              className="w-100"
              rows={3}
              placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
              value={updatedTimeEntryDetails?.entryDescription}
              onChange={(e) =>
                setUpdatedTimeEntryDetails((prev) => ({
                  ...prev,
                  entryDescription: e.target.value,
                }))
              }
            />
          </div>

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const EditTimeEntryModal = ({
  entryDetails,
  setIsUpdated,
  setNewUpdate,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        {entryDetails?.time_entries_status === "pending" && <EditSVG />}
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
        entryDetails={entryDetails}
        setNewUpdate={setNewUpdate}
      />
    </>
  );
};
