import React from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const MemberListTable = ({
  tableInstance,
  headers,
  invoice,
  memberList,
  columnHeaders,
}) => {
  return (
    <section className="assign-jobs-list-table mt-3 ml-30 mr-40 member-list-table">
      <ReactTable tableInstance={tableInstance} />
      <ReactTableFooter
        headers={headers}
        data={memberList}
        tableInstance={tableInstance}
        text="Members"
      />
    </section>
  );
};

export default MemberListTable;
