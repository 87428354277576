import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import {
  DownloadSVG,
  fileIcon,
  reportsIcon,
  searchIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import JobsTable from "./JobsTable";
import PageHeader from "../../../../../templates/PageHeader";
import { formatDate } from "../../../../../utils/utilities/utilityFunctions";
// import { Select } from "antd";
import Select from "react-select";
import { CSVLink } from "react-csv";

const JobsContent = ({
  jobsData,
  isLoading,
  setIsUpdated,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
  filters,
  onFilterChange,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const breadCrumbs = [
    { pageName: "Home", pageURL: "/dashboard" },
    { pageName: "Jobs", pageURL: "/jobs" },
    { pageName: "My Jobs", pageURL: "/jobs/my-jobs" },
  ];

  const data = useMemo(() => jobsData, [jobsData]);

  const tableColumns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Sr no.",
        accessor: "sr no.",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "BPO No",
        accessor: (row) => {
          try {
            const clientData = JSON.parse(row.client_data);
            return clientData.bpo_no || "N/A";
          } catch (error) {
            return "N/A";
          }
        },
      },
      {
        Header: "Job Name",
        accessor: "job_name",
      },
      {
        Header: "Period Start Date",
        accessor: "assigned_on",
        Cell: ({ row }) => formatDate(row.original.assigned_on),
      },
    ];

    // Conditionally add the Due Date column
    if (!data.some((row) => row.due_on)) {
      baseColumns.push({
        Header: "Allocated Hours",
        accessor: "allocated_hours",
        Cell: ({ row }) => {
          const time = row.original.allocated_hours;
          return typeof time === "string" ? time.slice(0, -3) : "";
        },
      });
    } else {
      baseColumns.push({
        Header: "Period End Date",
        accessor: "due_on",
        Cell: ({ row }) => formatDate(row.original.due_on),
      });
    }

    baseColumns.push({
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    });

    baseColumns.push({
      Header: "Description",
      accessor: "job_description",
    });

    return baseColumns;
  }, [data]);

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Period Start Date", key: "assigned_on" },
      { label: "Period End Date", key: "due_on" },
      { label: "Status", key: "job_status" },
      { label: "Description", key: "job_description" },
    ],
    fileName: "Jobs",
  };

  // Function to transform data for CSV export
  const transformDataForCSV = (invoices) => {
    return invoices.map((invoice) => {
      return {
        job_name: invoice.job_name,
        client_name: invoice.client_name,
        assigned_on: invoice.assigned_on,
        job_status: invoice.job_status,
        allocated_hours: invoice.allocated_hours,
        approved_time: invoice?.approved_time === "yes" ? "Yes" : "No",
      };
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    localStorage.setItem("pageSize", newSize);
    localStorage.setItem("currentPage", 1);
    setIsUpdated((prev) => !prev);
  };
  // Use the function to get CSV data
  const csvAdvanceData = transformDataForCSV(jobsData);

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);
  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      {/* 
      <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={fileIcon}
          // headerTitle={"Jobs"}
          headerTitle={"My Jobs"}
        />
      </div> */}

      <div className="d-flex justify-content-between align-items-center  mr-40 ml-30 mb-15 ">
        {/* Title on the Left */}
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">My Jobs</p>
        </div>
        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper ">
          <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              type="text"
              className="input-field"
              placeholder="Search"
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value.replace(/^\s+/, ''))}
              />
          </div>
        </div>
      </div>

      <div className="mr-40 ml-30 mt-4 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) => onFilterChange("status", option)}
            value={filters.status}
            placeholder="Select status"
            style={{ paddingLeft: "35px" }}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
      </div>

      {isLoading ? (
        <ReactTableSkeleton
          columnHeaders={headers.headings.map((h) => h.label)}
        />
      ) : (
        <>
          <div className="jobs-table">
            <JobsTable
              tableInstance={tableInstance}
              headers={headers}
              jobsData={data}
            />
            <div
              className=" mt-4 d-flex justfy-content-center align-items-center gap-3 smallText"
              style={{
                width: "78%",
                justifyContent: "space-between",
                position: "absolute",
                marginLeft: "90px",
                bottom: "18px",
              }}
            >
              <div className="d-flex gap-1 align-items-center">
                <button
                  className="cursor-pointer"
                  style={{ border: "none", background: "transparent" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                    />
                  </svg>
                </button>
                <input
                  className="gotoPage-input-field"
                  type="number"
                  defaultValue={currentPage}
                  readOnly
                />
                <span className="px-2">/</span>

                <span> {totalPages}</span>
                <button
                  style={{ border: "none", background: "transparent" }}
                  className="cursor-pointer"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={
                    jobsData?.length < pageSize ||
                    totalPages === 1 ||
                    totalPages === currentPage
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="px-1 smallText">Rows /page</span>
                <select value={pageSize} onChange={handlePageSizeChange}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <span>Download List</span>
                <span>
                  {data ? (
                    <CSVLink
                      data={data}
                      headers={headers?.headings}
                      filename={`${headers.fileName}.csv`}
                    >
                      <DownloadSVG />
                    </CSVLink>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JobsContent;

// import React, { useContext, useMemo, useState } from "react";
// import {
//   useTable,
//   useGlobalFilter,
//   useSortBy,
//   usePagination,
// } from "react-table";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";
// import { fileIcon } from "../../../../../utils/ImportingImages/ImportingImages";
// import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
// import Breadcrumbs from "../../../../../templates/Breadcrumbs";
// import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
// import JobsTable from "./JobsTable";
// import PageHeader from "../../../../../templates/PageHeader";
// import { formatDate } from "../../../../../utils/utilities/utilityFunctions";

// const JobsContent = ({
//   jobsData,
//   isLoading,
// }) => {

//   const { sidebarClose } = useContext(ContextSidebarToggler);
// console.log("job data",jobsData);

//   const breadCrumbs = [
//     { pageName: "Home", pageURL: "/dashboard" },
//     { pageName: "Jobs", pageURL: "/jobs" },
//     { pageName: "My Jobs", pageURL: "/jobs/my-jobs" },
//   ];

//   const tableColumns = useMemo(() => {
//     const baseColumns = [
//       {
//         Header: "Sr no.",
//         accessor: "sr no.",
//         Cell: ({ row }) => row.index + 1,
//       },
//       {
//         Header: "BPO No",
//         accessor: (row) => {
//           try {
//             const clientData = JSON.parse(row.client_data);
//             return clientData.bpo_no || "N/A";
//           } catch (error) {
//             return "N/A";
//           }
//         },
//       },
//       {
//         Header: "Job Name",
//         accessor: "job_name",
//       },
//       {
//         Header: "Assigned on",
//         accessor: "assigned_on",
//         Cell: ({ row }) => formatDate(row.original.assigned_on),
//       },
//     ];

//     // Conditionally add the Due Date column
//     if (! jobsData.some((row) => row.due_on)) {
//       baseColumns.push({
//         Header: "Allocated Hours",
//         accessor: "allocated_hours",
//         Cell: ({ row }) => {
//           const time = row.original.allocated_hours;
//           return typeof time === "string" ? time.slice(0, -3) : "";
//         },
//       });
//     } else {
//       baseColumns.push({
//         Header: "Due Date",
//         accessor: "due_on",
//         Cell: ({ row }) => formatDate(row.original.due_on),
//       });
//     }

//     baseColumns.push({
//       Header: "Status",
//       accessor: "job_status",
//       Cell: ({ row }) => (
//         <div className="d-flex justify-content-center align-items-center">
//           <Stack direction="horizontal">
//             {row.original.job_status === "Completed" ? (
//               <Badge bg="success">Completed</Badge>
//             ) : row.original.job_status === "On Hold" ? (
//               <Badge bg="danger">On Hold</Badge>
//             ) : (
//               <Badge bg="warning" text="dark">
//                 In Progress
//               </Badge>
//             )}
//           </Stack>
//         </div>
//       ),
//     });

//     baseColumns.push({
//       Header: "Description",
//       accessor: "job_description",
//     });

//     return baseColumns;
//   }, [ jobsData]);

//   const columns = useMemo(() => tableColumns, [tableColumns]);

//   const tableInstance = useTable(
//     { columns,  jobsData },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   const headers = {
//     headings: [
//       { label: "Job Name", key: "job_name" },
//       { label: "Assigned on", key: "assigned_on" },
//       { label: "Due Date", key: "due_on" },
//       { label: "Status", key: "job_status" },
//       { label: "Description", key: "job_description" },
//     ],
//     fileName: "Jobs",
//   };

//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       <div className="mb-5 relative-wrapper zIndex-2">
//         <PageHeader
//           tableInstance={tableInstance}
//           icon={fileIcon}
//           headerTitle={"My Jobs"}
//         />
//       </div>

//       {isLoading ? (
//         <ReactTableSkeleton
//           columnHeaders={headers.headings.map((h) => h.label)}
//         />
//       ) :  jobsData?.length > 0 ? (
//         <div className="jobs-table">
//           <JobsTable
//             tableInstance={tableInstance}
//             headers={headers}
//             jobsData={ jobsData}
//           />
//         </div>
//       ) : (
//         <div className="mr-40 ml-30 mt-4 mb-15">
//           <h5>No Data found!</h5>
//         </div>
//       )}
//     </div>
//   );
// };

// export default JobsContent;
