import React, { useContext, useState, useEffect, useCallback } from "react";

import ClientsContent from "./components/ClientsContent";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";

export const Clients = () => {
  const { getAllClients, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   if (searchInput === "") {
  //     setSearchTriggered(false);
  //     setCurrentPage(1);
  //   }
  // }, [searchInput]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchInput === "" && !searchTriggered) {
  //       getAllClients(currentPage, pageSize, "");
  //     } else if (searchTriggered && searchInput !== "") {
  //       getAllClients(currentPage, pageSize, searchInput);
  //     }
  //   }, 500);

  //   return () => clearTimeout(timer);
  // }, [currentPage, pageSize, searchInput, searchTriggered, isUpdated]);
  // ===============================================================================

  const debouncedSearch = useCallback(
    debounce((searchInput) => {
      getAllClients(currentPage, pageSize, searchInput);
    }, 500),
    [currentPage, pageSize]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllClients(currentPage, pageSize, "");
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);

  return (
    <ClientsContent
      clientsData={initialState?.clientsList}
      setIsUpdated={setIsUpdated}
      isLoading={initialStateLoading?.getAllClientsIsLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      // handleSearch={handleSearch}
      handleInputChange={handleInputChange}
      // loading={loading}
      // setLoading={setLoading}
    />
  );
};
