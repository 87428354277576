import React, { useContext, useEffect, useState } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { debounce } from "lodash";
import { teamsIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";

import {
  formatDate,
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import { AsyncPaginate } from "react-select-async-paginate";

const animatedComponents = makeAnimated();

const ManageTeam = () => {
  const location = useLocation();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  // const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState({
    teamLeader: false,
    teamSubLeader: false,
    teamMember: false,
    assignJob: false,
    advanceBillingJob: false,
  });
  // const { getAllMembers, logout, mainURL, initialState,getSingleTeamDetails } =
  useContext(ContextAPI);
  const {
    getAllMembers,
    getSingleTeamDetails,
    getMoreAdvancedBillingJobs,
    getMembersFreeFromTeam,
    userDetails,
    logout,
    mainURL,
    initialState,
    getMoreJobs,
  } = useContext(ContextAPI);
  // const { teamData } = location.state ?? "";
  const TeamID = useParams();
  const [loading, setLoading] = useState(false);
  const [arrJobs, setArrJobs] = useState([]);
  const [parentJobFilterData, setParentJobFilterData] = useState([]);
  const [advancedArrJobs, setAdvancedArrJobs] = useState([]);
  const [
    parentAdvancedBillingJobFilterData,
    setParentAdvancedBillingJobFilterData,
  ] = useState([]);

  const userID = localStorage.getItem("userId") || userDetails?.member_id;

  const [arrTeamMembers, setArrTeamMembers] = useState([]);
  const [memberPageNo, setMembersPageNo] = useState(1);
  const [teamMembersListOption, setTeamMembersListOption] = useState([]);
  const [teamLeadersListOption, setTeamLeadersListOption] = useState([]);
  const [teamSubLeadersListOption, setTeamSubLeadersListOption] = useState([]);

  const navigate = useNavigate();
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Teams",
      pageURL: "/teams",
    },
    {
      pageName: "Manage Team",
      pageURL: "/teams/manage-team",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [updatedTeamData, setUpdatedTeamData] = useState({
    teamName: initialState?.singleTeam?.team_name || "",
    teamCode: initialState?.singleTeam?.team_code || "",
    teamLeader: "",
    teamSubLeader: [],
    teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
    teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
    teamMembersCount: initialState?.singleTeam?.member_count || 0,
    teamMembersName: [],
    teamStatus: initialState?.singleTeam?.status || "",

    jobs: initialState?.singleTeam?.assigned_jobs_list,
    advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
    selectedJob: null,
    selectedAdvacedBillingJob: null,
  });
  useEffect(() => {
    if (Object.entries(initialState)) {
      setUpdatedTeamData({
        teamName: initialState?.singleTeam?.team_name || "",
        teamCode: initialState?.singleTeam?.team_code || "",
        teamLeader: "",
        teamSubLeader: [],
        teamMembersName: [],
        teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
        teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
        teamMembersCount: initialState?.singleTeam?.member_count || 0,
        teamStatus: initialState?.singleTeam?.status || "",
        jobs: initialState?.singleTeam?.assigned_jobs_list,
        advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
        selectedJob: null,
        selectedAdvacedBillingJob: null,
      });
    }
  }, [initialState?.singleTeam]);

  const [projectOptions, setProjectOptions] = useState([]);
  const [membersOptions, setMembersOptions] = useState([]); // only responsible for maintaining track of members dropdown options
  const [teamLeaderOptions, setTeamLeaderOptions] = useState([]); // only responsible for maintaining track of team leader dropdown options
  const [teamSubLeaderOptions, setTeamSubLeaderOptions] = useState(); // only responsible for maintaining track of team leader dropdown options

  useEffect(() => {
    if (
      updatedTeamData &&
      updatedTeamData?.jobs &&
      updatedTeamData?.jobs?.data?.length > 0
    ) {
      setProjectOptions(() =>
        updatedTeamData?.jobs?.data?.map((job) => ({
          label: job?.task_name || "N/A",
          value: job?.task_id,
        }))
      );
      setArrJobs(() =>
        updatedTeamData?.jobs?.data?.map((job) => ({
          label: job?.task_name || "N/A",
          value: job?.task_id,
        }))
      );
      // setParentJobFilterData(
      //   initialState?.singleTeam?.assigned_jobs_list?.data
      // );

      setAdvancedArrJobs(() =>
        updatedTeamData?.advancedBillingJobs?.data?.map((job) => ({
          label: job?.task_name || "N/A",
          value: job?.task_id,
        }))
      );

      // setParentAdvancedBillingJobFilterData(
      //   initialState?.singleTeam?.advance_billing_list?.data
      // );
    }
  }, [
    initialState?.singleTeam?.assigned_jobs_list,
    initialState?.singleTeam?.advance_billing_list,
  ]);

  // fetching all members list
  useEffect(() => {
    getAllMembers();
  }, []);

  const getJobDetails = () => {
    return (
      parentJobFilterData &&
      parentJobFilterData?.find(
        (job) => job?.task_id === updatedTeamData?.selectedJob?.value
      )
    );
  };

  // If user role is "member" OR "Team Leader but only from this same team then OK"
  const condition1 = (user) =>
    user.member_role === "members" ||
    user.member_role === "members,team_sub_leader" ||
    (user.member_role === "team_leaders,members" &&
      initialState?.singleTeam?.team_leader === user.member_id);

  // "If member is not in any team" AND "member is not a part of this Team"
  // const condition2 = (user) =>
  //   user.is_added_in_team !== "1" &&
  //   ! initialState?.singleTeam?.team_member.split(",").includes(user.member_id);
  const condition2 = (user) => {
    const teamMembers = initialState?.singleTeam?.team_member?.split(",") || []; // Fallback to empty array if undefined
    return (
      user.is_added_in_team !== "1" && !teamMembers.includes(user.member_id)
    );
  };

  // "If member is assigned a Team" AND "member is a part of this Team"
  // const condition3 = (user) =>
  //   user.is_added_in_team === "1" &&
  //   ( initialState?.singleTeam?.team_member.split(",").includes(user.member_id) ||
  //     // teamData.sub_leaders.split(",").includes(user.member_id)||
  //     ( initialState?.singleTeam?.sub_leaders &&
  //       initialState?.singleTeam?.sub_leaders.split(",").includes(user.member_id)) ||
  //       initialState?.singleTeam?.team_leader === user.member_id);

  const condition3 = (user) => {
    const teamMembers = initialState?.singleTeam?.team_member?.split(",") || [];
    const subLeaders = initialState?.singleTeam?.sub_leaders?.split(",") || [];

    return (
      user.is_added_in_team === "1" &&
      (teamMembers.includes(user.member_id) ||
        subLeaders.includes(user.member_id) ||
        initialState?.singleTeam?.team_leader === user.member_id)
    );
  };

  // helper function for creating Members Dropdown options
  // step 1: taking the complete list of members from initialState
  // step 2: filtering that dataset with 3 conditions
  // step 3: checking that each one of them is active user
  // step 4: lastly mapping over them all and return only specfic object details using map

  const generateOptions = (dataset, excludeMemberId) => {
    return dataset
      ?.filter((member) => {
        return (
          condition1(member) &&
          (condition2(member) || condition3(member)) &&
          member.current_status === "active" &&
          member.member_id !== excludeMemberId
        );
      })
      .map((member) => ({
        label: member.member_name,
        value: member.member_id,
      }));
  };

  useEffect(() => {
    if (initialState?.singleTeam) {
      const teamMembersArray =
        initialState?.singleTeam?.team_member?.split(",") || [];
      const subLeadersArray =
        initialState?.singleTeam?.sub_leaders?.split(",") || [];

      // Filter members based on team_members and sub_leaders
      const defaultMembers = generateOptions(initialState?.membersList).filter(
        (member) => teamMembersArray.includes(member.value)
      );

      // Find the default team leader
      const defaultTeamLeader = generateOptions(
        initialState?.membersList
      )?.find(
        (member) => member.value === initialState?.singleTeam?.team_leader
      );

      // Find the selected sub-team leaders
      const selectedSubTeamLeaders = initialState?.membersList
        .filter((member) => subLeadersArray.includes(member.member_id))
        .map((member) => ({
          label: member.member_name,
          value: member.member_id,
        }));

      // Update the team data with the selected values
      setUpdatedTeamData((prev) => ({
        ...prev,
        teamMembersName: defaultMembers,
        teamLeader: defaultTeamLeader,
        teamSubLeader: selectedSubTeamLeaders,
      }));
    }
  }, [initialState?.membersList, initialState?.singleTeam]);

  const generateMemberOptions = () => {
    return generateOptions(initialState?.membersList).filter((member) => {
      // Filter out team leader
      if (
        updatedTeamData.teamLeader &&
        member.value === updatedTeamData.teamLeader.value
      ) {
        return false;
      }

      // Filter out selected team sub-leaders
      return !updatedTeamData.teamSubLeader.some(
        (subLeader) => subLeader.value === member.value
      );
    });
  };

  useEffect(() => {
    // Generate member options excluding team leader
    setMembersOptions(() => generateMemberOptions());

    // Filter team leader from teamMembersName and set team leader email
    const removeTeamLeaderAndSubLeader = (dataset) =>
      dataset.filter(
        (member) => member.value !== updatedTeamData.teamLeader?.value
      );

    const teamLeader =
      initialState?.membersList.find(
        (member) => member.member_id === updatedTeamData.teamLeader?.value
      )?.member_email ?? "";

    setUpdatedTeamData((prev) => ({
      ...prev,
      teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
      teamLeaderEmail: teamLeader,
    }));
  }, [initialState?.membersList, updatedTeamData.teamLeader]);

  // Sub team leader

  useEffect(() => {
    // Generate member options excluding selected sub-leaders
    setMembersOptions(() => generateMemberOptions());

    // Update team members list to exclude selected sub-leaders
    const removeTeamLeaderAndSubLeader = (dataset) =>
      dataset.filter((member) =>
        updatedTeamData.teamSubLeader
          ? !updatedTeamData.teamSubLeader.some(
              (subLeader) => subLeader.value === member.value
            )
          : true
      );

    setUpdatedTeamData((prev) => ({
      ...prev,
      teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
    }));
  }, [initialState?.membersList, updatedTeamData.teamSubLeader]);

  useEffect(() => {
    const membersArray =
      initialState?.singleTeam?.team_member?.split(",") || [];

    const teamLeaderOptions = generateOptions(initialState?.membersList).filter(
      (member) => {
        return !membersArray.includes(member.value);
      }
    );
    setTeamLeaderOptions(teamLeaderOptions);
  }, [initialState?.membersList, initialState?.singleTeam?.team_member]);

  useEffect(() => {
    const membersArray =
      initialState?.singleTeam?.team_member?.split(",") || [];

    const teamSubLeaderOptions = generateOptions(
      initialState?.membersList
    ).filter((member) => {
      return (
        !membersArray.includes(member.value) &&
        member.value !== updatedTeamData.teamLeader?.value
      );
    });
    setTeamSubLeaderOptions(teamSubLeaderOptions);
  }, [
    initialState?.membersList,
    initialState?.singleTeam?.team_member,
    updatedTeamData.teamLeader,
  ]);

  useEffect(() => {
    getSingleTeamDetails(TeamID?.teamId);
  }, [TeamID?.teamId]);

  const getadvacedBillingJobDetails = () => {
    return parentAdvancedBillingJobFilterData?.find(
      (job) =>
        job?.task_id === updatedTeamData?.selectedAdvacedBillingJob?.value
    );
  };

  // UnAllocated-members dropdown functionality
  const fetchTeamOptions = async () => {
    setLoading(true);
    try {
      if (memberPageNo === 1) {
        await getMembersFreeFromTeam(memberPageNo);
        // setArrTeamMembers(initialState?.unassignedMembersList?.data);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (initialState?.unassignedMembersList?.has_more === true &&
        memberPageNo === 2) ||
      (initialState?.unassignedMembersList?.has_more === true &&
        memberPageNo > 2) ||
      initialState?.unassignedMembersList?.data?.length > 0
    ) {
      setLoading(true);
      getMembersFreeFromTeam(memberPageNo);
      if (
        initialState?.unassignedMembersList?.data ||
        Object.entries(initialState?.unassignedMembersList?.data)?.length > 0
      ) {
        if (arrTeamMembers) {
          setArrTeamMembers(
            ...arrTeamMembers,
            initialState?.unassignedMembersList?.data
          );
        }

        if (arrTeamMembers?.length > 0) {
          let b =
            arrTeamMembers &&
            arrTeamMembers?.concat(initialState?.unassignedMembersList?.data);
          setArrTeamMembers(b);
          if (!initialState?.unassignedMembersList?.has_more) {
            setLoading(false);
          }
        }
      }
    }
  }, [initialState?.unassignedMembersList?.has_more, memberPageNo]);

  // Fetch options on scroll to bottom
  const handleTeamMembersMenuScrollToBottom = () => {
    setMembersPageNo((prevPage) => prevPage + 1); // Load next page
  };

  useEffect(() => {
    if (arrTeamMembers?.length > 0) {
      const teamLeaderId = updatedTeamData?.teamLeader?.value;
      const subLeaderIds =
        updatedTeamData?.teamSubLeader?.map((subLeader) => subLeader?.value) ||
        [];

      // Filter for team members list: exclude selected team leader and sub-leaders
      const teamMembersOptions = arrTeamMembers
        ?.filter(
          (member) =>
            member.id !== teamLeaderId && !subLeaderIds.includes(member.id)
        )
        ?.map((m) => ({
          label: m.name,
          value: m.id,
        }));

      // Filter for team sub-leaders list: exclude the selected team leader
      const teamSubLeadersOptions = arrTeamMembers
        ?.filter((member) => member.id !== teamLeaderId)
        ?.map((m) => ({
          label: m.name,
          value: m.id,
        }));

      // Filter for team leader options (all members can be leaders here)
      const teamLeaderOptions = arrTeamMembers?.map((m) => ({
        label: m.name,
        value: m.id,
      }));

      setTeamMembersListOption(teamMembersOptions);
      setTeamLeadersListOption(teamLeaderOptions);
      setTeamSubLeadersListOption(teamSubLeadersOptions);
    }
  }, [
    arrTeamMembers,
    updatedTeamData?.teamLeader,
    updatedTeamData?.teamSubLeader,
  ]);

  React.useEffect(() => {
    fetchTeamOptions();
  }, [memberPageNo]); // Refetch when page changes

  // Applying debouncing to reduce un-necessary api calling
  // const debouncedHandleSearch = debounce((inputValue, string) => {
  //   if (inputValue && string === "teamLeader") {
  //     searchHandlerFunctionForTeamLeaderName(inputValue);
  //   } else if (inputValue && string === "teamMembers") {
  //     searchHandlerFunctionForTeamMembersName(inputValue);
  //   } else if (inputValue && string === "teamSubLeader") {
  //     searchHandlerFunctionForTeamSubLeadersName(inputValue);
  //   } else if (inputValue && string === "assignJob") {
  //     searchHandlerFunctionForAssignJob(inputValue);
  //   } else if (inputValue && string === "advancedBillingJob") {
  //     searchHandlerFunctionForAdvancedBillingJob(inputValue);
  //   }
  // }, 100);
  const debouncedHandleSearch = debounce((inputValue, string) => {
    if (!inputValue) {
      const teamLeaderId = updatedTeamData?.teamLeader?.value;
      const subLeaderIds =
        updatedTeamData?.teamSubLeader?.map((subLeader) => subLeader?.value) ||
        [];

      // Filter for team members list: exclude selected team leader and sub-leaders
      const teamMembersOptions = arrTeamMembers
        ?.filter(
          (member) =>
            member.id !== teamLeaderId && !subLeaderIds.includes(member.id)
        )
        ?.map((m) => ({
          label: m.name,
          value: m.id,
        }));

      // Filter for team sub-leaders list: exclude the selected team leader
      const teamSubLeadersOptions = arrTeamMembers
        ?.filter((member) => member.id !== teamLeaderId)
        ?.map((m) => ({
          label: m.name,
          value: m.id,
        }));

      // Filter for team leader options (all members can be leaders here)
      const teamLeaderOptions = arrTeamMembers?.map((m) => ({
        label: m.name,
        value: m.id,
      }));

      setTeamMembersListOption(teamMembersOptions);
      setTeamLeadersListOption(teamLeaderOptions);
      setTeamSubLeadersListOption(teamSubLeadersOptions);
    }
    if (inputValue && string === "teamLeader") {
      searchHandlerFunctionForTeamLeaderName(inputValue);
    } else if (inputValue && string === "teamMembers") {
      searchHandlerFunctionForTeamMembersName(inputValue);
    } else if (inputValue && string === "teamSubLeader") {
      searchHandlerFunctionForTeamSubLeadersName(inputValue);
    }
  }, 100);

  const searchHandlerFunctionForTeamLeaderName = async (searchData) => {
    if (!searchData || typeof searchData !== "string") return;
    try {
      setIsDropdownLoading((prev) => ({ ...prev, teamLeader: true }));
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userDetails?.member_id}?&page=1&limit=10&searchQuery=${searchData}`,
        {
          headers: headerOptions(),
        }
      );
      if (response?.status === 200) {
        setTeamLeadersListOption(
          response?.data?.unassigned_member_list?.data &&
            response?.data?.unassigned_member_list?.data?.map((leader) => {
              return {
                label: leader?.name,
                value: leader?.id,
              };
            })
        );
        setIsDropdownLoading((prev) => ({ ...prev, teamLeader: false }));
      }
    } catch (error) {
      // console.log("Error fetching jobs:", error);
    } finally {
      setIsDropdownLoading((prev) => ({ ...prev, teamLeader: false }));
    }
  };
  const searchHandlerFunctionForTeamSubLeadersName = async (searchData) => {
    if (!searchData || typeof searchData !== "string") return;
    try {
      setIsDropdownLoading((prev) => ({ ...prev, teamSubLeader: true }));
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userDetails?.member_id}?&page=1&limit=10&searchQuery=${searchData}`,
        {
          headers: headerOptions(),
        }
      );
      if (response?.status === 200) {
        const teamLeaderId = updatedTeamData?.teamLeader?.value;
        const teamSubLeadersOptions =
          response?.data?.unassigned_member_list?.data &&
          response?.data?.unassigned_member_list?.data
            ?.filter((member) => member.id !== teamLeaderId)
            ?.map((m) => ({
              label: m.name,
              value: m.id,
            }));
        setTeamSubLeadersListOption(teamSubLeadersOptions);
        // setTeamSubLeadersListOption(
        //  response?.data?.unassigned_member_list?.data &&
        //   response?.data?.unassigned_member_list?.data?.map((member) => {
        //     return {
        //       label: member?.name,
        //       value: member?.id,
        //     };
        //   }),
        // )};
        setIsDropdownLoading((prev) => ({ ...prev, teamSubLeader: false }));
      }
    } catch (error) {
      // console.log("Error fetching jobs:", error);
    } finally {
      setIsDropdownLoading((prev) => ({ ...prev, teamSubLeader: false }));
    }
  };
  const searchHandlerFunctionForTeamMembersName = async (searchData) => {
    if (!searchData || typeof searchData !== "string") return;
    try {
      setIsDropdownLoading((prev) => ({ ...prev, teamMember: true }));
      const response = await axios.get(
        `${mainURL}get/member-without-team/${userDetails?.member_id}?&page=1&limit=10&searchQuery=${searchData}`,
        {
          headers: headerOptions(),
        }
      );
      if (response?.status === 200) {
        const teamLeaderId = updatedTeamData?.teamLeader?.value;
        const subLeaderIds =
          updatedTeamData?.teamSubLeader?.map(
            (subLeader) => subLeader?.value
          ) || [];

        const teamMembersOptions =
          response?.data?.unassigned_member_list?.data &&
          response?.data?.unassigned_member_list?.data
            ?.filter(
              (member) =>
                member.id !== teamLeaderId && !subLeaderIds.includes(member.id)
            )
            ?.map((m) => ({
              label: m.name,
              value: m.id,
            }));
        setTeamMembersListOption(teamMembersOptions);
        setIsDropdownLoading((prev) => ({ ...prev, teamMember: false }));

        // setTeamMembersListOption(
        //   response?.data?.unassigned_member_list?.data &&
        //     response?.data?.unassigned_member_list?.data?.map((member) => {
        //       return {
        //         label: member?.name,
        //         value: member?.id,
        //       };
        //     })
        // );
      }
    } catch (error) {
      // console.log("Error fetching jobs:", error);
    } finally {
      setIsDropdownLoading((prev) => ({ ...prev, teamMember: false })); // Hide the loading spinner
    }
  };

  const handleTeamMember = (member) => {
    const teamLeaderId = updatedTeamData?.teamLeader?.value;
    const subLeaderIds =
      updatedTeamData?.teamSubLeader?.map((subLeader) => subLeader?.value) ||
      [];
    setUpdatedTeamData((prev) => ({
      ...prev,
      teamMembersName: member,
    }));
    const teamMembersOptions = arrTeamMembers
      ?.filter(
        (member) =>
          member.id !== teamLeaderId && !subLeaderIds.includes(member.id)
      )
      ?.map((m) => ({
        label: m.name,
        value: m.id,
      }));
    setTeamMembersListOption(teamMembersOptions);
  };

  const handleAdvanceBillingJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedAdvacedBillingJob: option,
    }));
  };
  const handleAssignJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedJob: option,
    }));
    setProjectOptions(arrJobs);
  };

  // ===================================================================
  const updateTeam = async () => {
    setIsLoading(() => true);
    try {
      const body = {
        current_user:
          +localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        team_name: updatedTeamData.teamName,
        team_leader: +updatedTeamData.teamLeader?.value,
        team_sub_leader: updatedTeamData.teamSubLeader
          .map(({ value }) => value)
          .join(","),
        team_member: updatedTeamData.teamMembersName
          .map(({ value }) => value)
          .join(","),
        team_id: +initialState?.singleTeam?.id,
        status: updatedTeamData.teamStatus,
      };

      const url = `${mainURL}update/team`;
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        navigate("/teams");
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleUpdateTeam = (e) => {
    e.preventDefault();
    if (
      updatedTeamData.teamName &&
      updatedTeamData.teamLeader &&
      updatedTeamData.teamLeaderEmail &&
      updatedTeamData.teamMembersName &&
      updatedTeamData.teamStatus
    ) {
      updateTeam();
    } else {
      if (updatedTeamData.teamName === "") {
        ReactHotToast("Please input team name!", "error");
      } else if (updatedTeamData.teamLeader === "") {
        ReactHotToast("Please select team leader!", "error");
        // } else if (updatedTeamData.teamLeaderEmail === "") {
        //   ReactHotToast("Please input team leader email!", "error");
      } else if (updatedTeamData.teamMembersName.length <= 0) {
        ReactHotToast("Please select team members!", "error");
      } else if (updatedTeamData.teamStatus === "") {
        ReactHotToast("Please select team status!", "error");
      }
    }
  };

  // =====================================================================
  // =====================================================================
  // async paginate

  const [selectedAssignedJobValue, setSelectedAssignedJobValue] = useState("");
  const [selectedAdvancedJobValue, setSelectedAdvancedJobValue] = useState("");

  async function assignJobLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${TeamID?.teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // console.log("responseAssign", response);

      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAssignJobs = response.data.filterData.data;

      let combinedAssignedjobs = [...parentJobFilterData, ...newAssignJobs];
      setParentJobFilterData(combinedAssignedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${TeamID?.teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            // Safely parse additional_data
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAdvancedJobs = response?.data?.filterData?.data;

      let combinedAdvancedjobs = [
        ...parentAdvancedBillingJobFilterData,
        ...newAdvancedJobs,
      ];
      setParentAdvancedBillingJobFilterData(combinedAdvancedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={teamsIcon1} alt="Manage Team" />
          <p className="m-0 fs-4">Manage Team</p>
        </div>
      </section>
      <section className="main-content_form-section d-flex justify-content-center align-items-start w-75 m-auto">
        <form
          onSubmit={handleUpdateTeam}
          className="w-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div className="d-flex justify-content-center align-items-start gap-5 w-100">
            <div className="w-50">
              <div className="form-group mt-5">
                <label htmlFor="teamCode">Team Code:</label>
                <input
                  id="teamCode"
                  name="teamCode"
                  placeholder="Eg: 00101"
                  type="text"
                  value={updatedTeamData?.teamCode}
                  required
                  disabled
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamName">Team Name:</label>
                <input
                  id="teamName"
                  name="teamName"
                  placeholder="Eg: Team Titans"
                  type="text"
                  value={updatedTeamData?.teamName}
                  onChange={(e) =>
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamName: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamLeader">Team Leader:</label>
                <Select
                  name="teamLeader"
                  closeMenuOnSelect={true}
                  // options={teamLeaderOptions}
                  options={teamLeadersListOption}
                  onChange={(value) => {
                    const updatedSubLeaders =
                      updatedTeamData.teamSubLeader.filter(
                        (subLeader) => subLeader.value !== value.value
                      );

                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamLeader: value,
                      teamSubLeader: updatedSubLeaders,
                    }));
                  }}
                  onMenuScrollToBottom={
                    initialState?.unassignedMembersList?.has_more === true &&
                    handleTeamMembersMenuScrollToBottom
                  }
                  value={updatedTeamData.teamLeader}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  onInputChange={(inputValue) =>
                    debouncedHandleSearch(inputValue, "teamLeader")
                  }
                  isLoading={isDropdownLoading?.teamLeader}
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamSubLeader">Team Sub-Leader:</label>

                <Select
                  name="teamSubLeader"
                  closeMenuOnSelect={true}
                  isMulti
                  options={teamSubLeadersListOption}
                  onChange={(value) => {
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamSubLeader: value,
                    }));
                  }}
                  onMenuScrollToBottom={
                    initialState?.unassignedMembersList?.has_more === true &&
                    handleTeamMembersMenuScrollToBottom
                  }
                  value={updatedTeamData.teamSubLeader}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  onInputChange={(inputValue) =>
                    debouncedHandleSearch(inputValue, "teamSubLeader")
                  }
                  isLoading={isDropdownLoading?.teamSubLeader}
                  placeholder="Select Team Sub-Leader"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamLeaderEmail">Team Leader Email:</label>
                <input
                  id="teamLeaderEmail"
                  name="teamLeaderEmail"
                  placeholder="Eg: mailto:rajshah@gmail.com"
                  type="email"
                  value={updatedTeamData?.teamLeaderEmail}
                  disabled
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="teamMemberCount">Team Member Count:</label>
                <input
                  id="teamMemberCount"
                  name="teamMemberCount"
                  placeholder="Eg: 12"
                  type="number"
                  value={updatedTeamData?.teamMembersCount}
                  disabled
                  required
                />
              </div>
              <button
                type="submit"
                className="mt-4 w-25 custom-btn d-flex justify-content-center align-items-center"
              >
                {isLoading ? <SpinningLoader /> : "Update"}
              </button>
            </div>
            <div className="w-50">
              {/* <div className="form-group mt-5">
                <label htmlFor="teamMembers">Team Members:</label>

                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={membersOptions}
                  onChange={(member) => {
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamMembersName: member,
                    }));
                  }}
                  name="teamMembers"
                  value={updatedTeamData.teamMembersName}
                  onMenuScrollToBottom={
                    updatedTeamData?.jobs?.has_more && handleTeamMembersMenuScrollToBottom
                  }
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div> */}
              <div className="form-group mt-5">
                <label htmlFor="teamMembers">Team Members:</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  // options={membersOptions}
                  options={teamMembersListOption}
                  // onChange={(member) => {
                  //   setUpdatedTeamData((prev) => ({
                  //     ...prev,
                  //     teamMembersName: member,
                  //   }));
                  // }}
                  onChange={(member) => handleTeamMember(member)}
                  name="teamMembers"
                  value={updatedTeamData.teamMembersName}
                  onMenuScrollToBottom={
                    initialState?.unassignedMembersList?.has_more === true &&
                    handleTeamMembersMenuScrollToBottom
                  }
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  onInputChange={(inputValue) =>
                    debouncedHandleSearch(inputValue, "teamMembers")
                  }
                  isLoading={isDropdownLoading?.teamMember}
                  placeholder="Select Team Members"
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="teamStatus">Team Status:</label>
                <Select
                  name="teamStatus"
                  closeMenuOnSelect={true}
                  options={statusOptions}
                  onChange={({ value }) =>
                    setUpdatedTeamData((prev) => ({
                      ...prev,
                      teamStatus: value,
                    }))
                  }
                  value={
                    updatedTeamData.teamStatus === "active"
                      ? { label: "Active", value: "active" }
                      : { label: "Inactive", value: "inactive" }
                  }
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="clientName">Assigned Job List:</label>
                {/* <Select
                  isClearable={true}
                  name="assignedJobs"
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  options={projectOptions}
                  onMenuScrollToBottom={
                    updatedTeamData?.jobs?.has_more && handleMenuScrollToBottom
                  }
                  // onChange={(option) =>
                  //   setUpdatedTeamData((prev) => ({
                  //     ...prev,
                  //     selectedJob: option,
                  //   }))
                  // }
                  onChange={(option) => handleAssignJobSelectFunction(option)}
                  value={updatedTeamData.selectedJob}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  onInputChange={(inputValue) => {
                    debouncedHandleSearch(inputValue, "assignJob");
                  }}
                  isLoading={isDropdownLoading?.assignJob}
                  placeholder="Select Assigned Job"
                /> */}
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={selectedAssignedJobValue}
                  loadOptions={assignJobLoadOptions}
                  onChange={(selectedOption) => {
                    // setUpdatedTeamData((prev) => ({
                    //   ...prev,
                    //   selectedJob: selectedOption,
                    // }));
                    setSelectedAssignedJobValue(selectedOption);
                    handleAssignJobSelectFunction(selectedOption);
                  }}
                  additional={{
                    page: 1,
                  }}
                  debounceTimeout={300} // Prevent excessive API calls
                  placeholder={`Select Assigned Job`}
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No Job found for "${inputValue}"`
                      : "No Job found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading Job", "error");
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              {updatedTeamData.selectedJob && (
                <div className={`projectDetails mt-4`}>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <p className={`bigText fs-5 m-0`}>
                      Job Name:{" "}
                      <div className={`smallText fs-6 mt-2`}>
                        {" "}
                        {updatedTeamData.selectedJob.label}{" "}
                      </div>{" "}
                    </p>
                    <Stack direction="horizontal">
                      {getJobDetails()?.job_status === "Completed" ? (
                        <Badge bg="success">Completed</Badge>
                      ) : getJobDetails()?.job_status === "On Hold" ? (
                        <Badge bg="danger">On Hold</Badge>
                      ) : (
                        <Badge bg="warning" text="dark">
                          {" "}
                          In Progress{" "}
                        </Badge>
                      )}{" "}
                    </Stack>{" "}
                  </div>{" "}
                  {getJobDetails()?.job_description && (
                    <>
                      <p className="fs-6 m-0 mt-2 fw-semibold">
                        Project Description:{" "}
                      </p>
                      <p>{getJobDetails()?.job_description}</p>
                    </>
                  )}
                  <p className="fs-6 m-0 mt-2 fw-semibold">
                    Estimated Timeline:{" "}
                  </p>
                  <p className="fs-6 m-0">
                    {formatDate(getJobDetails()?.assigned_on)} to{" "}
                    {formatDate(getJobDetails()?.due_on)}
                  </p>
                </div>
              )}

              {/* {/ Advanced Billing Job List /} */}
              <div className="form-group mt-4">
                <label htmlFor="advanceBillingJobs">
                  Advanced Billing Job List:
                </label>
                {/* <Select
                  name="advanceBillingJobs"
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  isClearable={true}
                  options={advancedBillingJobOptions}
                  onMenuScrollToBottom={
                    updatedTeamData?.advancedBillingJobs?.has_more &&
                    handleAdvancedJobMenuScrollToBottom
                  }
                  placeholder="Select Advanced Billing Job"
                  // onChange={(option) =>
                  //   setUpdatedTeamData((prev) => ({
                  //     ...prev,
                  //     selectedAdvacedBillingJob: option,
                  //   }))
                  // }
                  onChange={(option) =>
                    handleAdvanceBillingJobSelectFunction(option)
                  }
                  // onChange={(option) =>
                  //   setUpdatedTeamData((prev) => ({
                  //     ...prev,
                  //     selectedAdvacedBillingJob: option,
                  //   }))
                  // }
                  value={updatedTeamData?.selectedAdvacedBillingJob}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  onInputChange={(inputValue) => {
                    debouncedHandleSearch(inputValue, "advancedBillingJob");
                  }}
                  isLoading={isDropdownLoading?.advanceBillingJob}
                /> */}
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={selectedAdvancedJobValue}
                  loadOptions={advanceBillingloadOptions}
                  onChange={(selectedOption) => {
                    setSelectedAdvancedJobValue(selectedOption);
                    handleAdvanceBillingJobSelectFunction(selectedOption);
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select job...."}
                  debounceTimeout={300} // Prevent excessive API calls
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No jobs found for "${inputValue}"`
                      : "No jobs found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>

              {updatedTeamData?.selectedAdvacedBillingJob && (
                <div className={`projectDetails mt-4`}>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <p className={`bigText fs-5 m-0`}>
                      Job Name:{" "}
                      <div className={`smallText fs-6 mt-1`}>
                        {updatedTeamData.selectedAdvacedBillingJob.label}
                      </div>
                    </p>
                    <Stack direction="horizontal">
                      {getadvacedBillingJobDetails()?.job_status ===
                      "Completed" ? (
                        <Badge bg="success">Completed</Badge>
                      ) : getadvacedBillingJobDetails()?.job_status ===
                        "On Hold" ? (
                        <Badge bg="danger">On Hold</Badge>
                      ) : (
                        <Badge bg="warning" text="dark">
                          In Progress
                        </Badge>
                      )}
                    </Stack>
                  </div>
                  {getadvacedBillingJobDetails()?.job_description && (
                    <p className="fs-6 m-0 mt-3 fw-semibold">
                      Project Description:{" "}
                      <span>
                        {getadvacedBillingJobDetails()?.job_description}
                      </span>
                    </p>
                  )}
                  <p className="fs-6 m-0 mt-3 fw-semibold">
                    Period Start Date:{" "}
                  </p>
                  <p className="fs-6 m-0">
                    {formatDate(getadvacedBillingJobDetails()?.assigned_on)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
export default ManageTeam;

// ======================================================================================
// ======================================================================================

// import React, { useContext, useEffect, useState } from "react";
// import { json, useLocation, useNavigate, useParams } from "react-router-dom";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import axios from "axios";
// import Badge from "react-bootstrap/Badge";
// import Stack from "react-bootstrap/Stack";

// import { teamsIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
// import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
// import Breadcrumbs from "../../../../templates/Breadcrumbs";
// import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";

// import {
//   formatDate,
//   handleAPIError,
//   headerOptions,
// } from "../../../../utils/utilities/utilityFunctions";

// const animatedComponents = makeAnimated();

// const ManageTeam = () => {
//   const TeamID = useParams();
//   const { sidebarClose } = useContext(ContextSidebarToggler);
//   const {
//     getAllMembers,
//     getSingleTeamDetails,
//     getMoreAdvancedBillingJobs,
//     getMembersFreeFromTeam,
//     logout,
//     mainURL,
//     initialState,
//     getMoreJobs,
//   } = useContext(ContextAPI);

//   const navigate = useNavigate();
//   const statusOptions = [
//     { value: "active", label: "Active" },
//     { value: "inactive", label: "Inactive" },
//   ];

//   const breadCrumbs = [
//     {
//       pageName: "Home",
//       pageURL: "/dashboard",
//     },
//     {
//       pageName: "Teams",
//       pageURL: "/teams",
//     },
//     {
//       pageName: "Manage Team",
//       pageURL: "/teams/manage-team",
//     },
//   ];
//   const [isLoading, setIsLoading] = useState(false);

//   const [updatedTeamData, setUpdatedTeamData] = useState({
//     teamName: initialState?.singleTeam?.team_name || "",
//     teamCode: initialState?.singleTeam?.team_code || "",
//     teamLeader: "",
//     teamSubLeader: [],
//     teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
//     teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
//     teamMembersCount: initialState?.singleTeam?.member_count || 0,
//     teamMembersName: [],
//     teamStatus: initialState?.singleTeam?.status || "",
//     jobs: initialState?.singleTeam?.assigned_jobs_list,
//     advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
//     freeMembersList: initialState?.unassignedMembersList,
//     selectedJob: null,
//     selectedAdvacedBillingJob: null,
//   });

//   useEffect(() => {
//     if (Object.entries(initialState)) {
//       setUpdatedTeamData({
//         teamName: initialState?.singleTeam?.team_name || "",
//         teamCode: initialState?.singleTeam?.team_code || "",
//         teamLeader: "",
//         teamSubLeader: [],
//         teamSubLeaderName: initialState?.singleTeam?.team_sub_leader,
//         teamLeaderEmail: initialState?.singleTeam?.leader_email || "",
//         teamMembersCount: initialState?.singleTeam?.member_count || 0,
//         teamMembersName: [],
//         teamStatus: initialState?.singleTeam?.status || "",
//         jobs: initialState?.singleTeam?.assigned_jobs_list,
//         advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
//         freeMembersList: initialState?.unassignedMembersList,
//         selectedJob: null,
//         selectedAdvacedBillingJob: null,
//       });
//     }
//   }, [initialState]);

//   const [membersOptions, setMembersOptions] = useState([]); // only responsible for maintaining track of members dropdown options
//   const [teamLeaderOptions, setTeamLeaderOptions] = useState([]); // only responsible for maintaining track of team leader dropdown options
//   const [teamSubLeaderOptions, setTeamSubLeaderOptions] = useState(); // only responsible for maintaining track of team leader dropdown options
//   const [loading, setLoading] = useState(false); // Loader for dropdown scroll

//   // State handling of Assigned Billing Job
//   const [page, setPage] = useState(1);
//   const [arrJobs, setArrJobs] = useState([]);
//   const [parentJobFilterData, setParentJobFilterData] = useState([]);
//   const [projectOptions, setProjectOptions] = useState([]);

//   // State handling of Advanced Billing Job
//   const [advanceJobpage, setAdvanceJobPage] = useState(1);
//   const [advancedArrJobs, setAdvancedArrJobs] = useState([]);
//   const [
//     parentAdvancedBillingJobFilterData,
//     setParentAdvancedBillingJobFilterData,
//   ] = useState([]);
//   const [advancedBillingJobOptions, setAdvancedBillingJobOptions] = useState(
//     []
//   );
//   // ========================================================
//   // State handling of UnAllocated Members
//   const [unAllocatedPage, setUnAllocatedPage] = useState(1);
//   const [unAllocatesMembers, setUnAllocatesMembers] = useState([]);
//   const [unAllocatesMembersOption, setUnAllocatesMembersOptions] = useState([]);
//   // const [
//   //   parentAdvancedBillingJobFilterData,
//   //   setParentAdvancedBillingJobFilterData,
//   // ] = useState([]);
//   // const [advancedBillingJobOptions, setAdvancedBillingJobOptions] = useState(
//   //   []
//   // );

//   // fetching all members list
//   useEffect(() => {
//     getAllMembers();
//   }, []);

//   useEffect(() => {
//     if (
//       updatedTeamData &&
//       updatedTeamData?.jobs &&
//       updatedTeamData?.jobs?.data?.length > 0
//     ) {
//       setProjectOptions(() =>
//         updatedTeamData?.jobs?.data?.map((job) => ({
//           label: job?.task_name || "N/A",
//           value: job?.task_id,
//         }))
//       );
//       setArrJobs(() =>
//         updatedTeamData?.jobs?.data?.map((job) => ({
//           label: job?.task_name || "N/A",
//           value: job?.task_id,
//         }))
//       );

//       setParentJobFilterData(
//         initialState?.singleTeam?.assigned_jobs_list?.data
//       );

//       setAdvancedBillingJobOptions(() =>
//         updatedTeamData?.advancedBillingJobs?.data?.map((job) => ({
//           label: job?.task_name || "N/A",
//           value: job?.task_id,
//         }))
//       );
//       setAdvancedArrJobs(() =>
//         updatedTeamData?.advancedBillingJobs?.data?.map((job) => ({
//           label: job?.task_name || "N/A",
//           value: job?.task_id,
//         }))
//       );

//       setParentAdvancedBillingJobFilterData(
//         initialState?.singleTeam?.advance_billing_list?.data
//       );
//     }
//   }, [
//     initialState?.singleTeam?.assigned_jobs_list,
//     initialState?.singleTeam?.advance_billing_list,
//     initialState?.unassignedMembersList,
//   ]);

//   // fetching all members list
//   useEffect(() => {
//     getSingleTeamDetails(TeamID?.teamId);
//   }, [TeamID?.teamId]);

//   const getJobDetails = () => {
//     return parentJobFilterData?.find(
//       (job) => job?.task_id === updatedTeamData?.selectedJob?.value
//     );
//   };
//   const getadvacedBillingJobDetails = () => {
//     return parentAdvancedBillingJobFilterData?.find(
//       (job) =>
//         job?.task_id === updatedTeamData?.selectedAdvacedBillingJob?.value
//     );
//   };

//   // If user role is "member" OR "Team Leader but only from this same team then OK"
//   const condition1 = (user) =>
//     user.member_role === "members" ||
//     user.member_role === "members,team_sub_leader" ||
//     (user.member_role === "team_leaders,members" &&
//       initialState?.singleTeam?.team_leader === user.member_id);

//   const condition2 = (user) =>
//     user.is_added_in_team !== "1" &&
//     !(
//       initialState?.singleTeam?.team_member
//         ?.split(",")
//         ?.includes(user.member_id) || []
//     );

//   const condition3 = (user) =>
//     user.is_added_in_team === "1" &&
//     ((initialState?.singleTeam?.team_member?.split(",") || []).includes(
//       user.member_id
//     ) ||
//       // (teamData.sub_leaders?.split(",") || []).includes(user.member_id) ||
//       (initialState?.singleTeam?.sub_leaders?.split(",") || []).includes(
//         user.member_id
//       ) ||
//       initialState?.singleTeam?.team_leader === user.member_id);

//   // const teamMembers = initialState?.membersList?.filter((member) => {
//   //   // Define conditions
//   //   const meetsConditions =
//   //     condition1(member) && condition2(member) && condition3(member);

//   //   // Check if the member is not part of any team
//   //   const notInTeam =
//   //     member.is_added_in_team === "0" || member.is_added_in_team === null;

//   //   // Return members who either meet the conditions or are not part of any team
//   //   return meetsConditions || notInTeam;
//   // });

//   // console.log("teamMembers", teamMembers);

//   const generateOptions = (dataset, excludeMemberId) => {
//     return dataset
//       ?.filter((member) => {
//         return (
//           condition1(member) &&
//           (condition2(member) || condition3(member)) &&
//           member.current_status === "active" &&
//           member.member_id !== excludeMemberId
//         );
//       })
//       .map((member) => ({
//         label: member.member_name,
//         value: member.member_id,
//       }));
//   };
//   const generateUnAssignedMembersList = (dataset) => {
//     return (
//       dataset &&
//       dataset?.map((member) => ({
//         label: member.name,
//         value: member.id,
//       }))
//     );
//   };

//   // setting initial default members, in the members options dropdown
//   // (not checking if the default member is selected team leader that we are checking in below useEffect)
//   useEffect(() => {
//     const defaultMembers = generateOptions(initialState?.membersList).filter(
//       (member) => {
//         const membersArray = initialState?.singleTeam?.team_member.split(",");
//         for (let memberId of membersArray) {
//           if (memberId === member.value) return true;
//         }
//       }
//     );

//     const defaultTeamLeader = generateOptions(initialState?.membersList)?.find(
//       (member) => member.value === initialState?.singleTeam?.team_leader
//     );

//     const selectedSubTeamLeaders = initialState.membersList

//       .filter(
//         (member) =>
//           initialState?.singleTeam?.sub_leaders &&
//           initialState?.singleTeam?.sub_leaders
//             .split(",")
//             .includes(member.member_id)
//       )
//       .map((member) => ({
//         label: member.member_name,
//         value: member.member_id,
//       }));

//     setUpdatedTeamData((prev) => ({
//       ...prev,
//       teamMembersName: defaultMembers,
//       teamLeader: defaultTeamLeader,
//       teamSubLeader: selectedSubTeamLeaders,
//     }));
//   }, [initialState]);

//   const generateMemberOptions = () => {
//     return generateOptions(initialState?.membersList).filter((member) => {
//       // Filter out team leader
//       if (
//         updatedTeamData.teamLeader &&
//         member.value === updatedTeamData.teamLeader.value
//       ) {
//         return false;
//       }

//       // Filter out selected team sub-leaders
//       return !updatedTeamData.teamSubLeader.some(
//         (subLeader) => subLeader.value === member.value
//       );
//     });
//   };
//   const generateUnAssignedMembersOptions = () => {
//     const unassignedMembers = generateUnAssignedMembersList(
//       initialState?.unassignedMembersList?.data || []
//     );
//     return unassignedMembers || []; // Ensure it returns an array
//   };

//   // console.log("finalMembersList()", finalMembersList());

//   useEffect(() => {
//     // Generate member options excluding team leader
//     setMembersOptions(() => generateMemberOptions());
//     // setMembersOptions(() => generateUnAssignedMembersOptions());

//     // Filter team leader from teamMembersName and set team leader email
//     const removeTeamLeaderAndSubLeader = (dataset) =>
//       dataset.filter(
//         (member) => member.value !== updatedTeamData.teamLeader?.value
//       );

//     const teamLeader =
//       initialState?.membersList.find(
//         (member) => member.member_id === updatedTeamData.teamLeader?.value
//       )?.member_email ?? "";

//     setUpdatedTeamData((prev) => ({
//       ...prev,
//       teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
//       teamLeaderEmail: teamLeader,
//     }));
//   }, [
//     initialState?.membersList,
//     updatedTeamData.teamLeader,
//     initialState?.unassignedMembersList?.data,
//   ]);

//   // Sub team leader
//   useEffect(() => {
//     // Generate member options excluding selected sub-leaders
//     setMembersOptions(() => generateMemberOptions());
//     // setMembersOptions(() => generateUnAssignedMembersOptions());

//     // Update team members list to exclude selected sub-leaders
//     const removeTeamLeaderAndSubLeader = (dataset) =>
//       dataset.filter((member) =>
//         updatedTeamData.teamSubLeader
//           ? !updatedTeamData.teamSubLeader.some(
//               (subLeader) => subLeader.value === member.value
//             )
//           : true
//       );

//     setUpdatedTeamData((prev) => ({
//       ...prev,
//       teamMembersName: removeTeamLeaderAndSubLeader(prev.teamMembersName),
//     }));
//   }, [initialState?.membersList, updatedTeamData.teamSubLeader]);

//   useEffect(() => {
//     const teamLeaderOptions = generateOptions(initialState?.membersList).filter(
//       (member) => {
//         const membersArray = initialState?.singleTeam?.team_member.split(",");
//         return !membersArray.includes(member.value);
//       }
//     );
//     setTeamLeaderOptions(teamLeaderOptions);
//   }, [
//     initialState?.membersList,
//     initialState?.singleTeam?.team_member,
//     initialState?.unassignedMembersList?.data,
//   ]);

//   useEffect(() => {
//     const teamSubLeaderOptions = generateOptions(
//       initialState?.membersList
//     ).filter((member) => {
//       const membersArray = initialState?.singleTeam?.team_member.split(",");
//       return (
//         !membersArray.includes(member.value) &&
//         member.value !== updatedTeamData.teamLeader?.value
//       );
//     });
//     setTeamSubLeaderOptions(teamSubLeaderOptions);
//   }, [
//     initialState?.membersList,
//     initialState?.singleTeam?.team_member,
//     updatedTeamData.teamLeader,
//   ]);

//   // ==============================================
//   // For Assign Jobs

//   // Fetch initial options
//   const fetchOptions = async () => {
//     setLoading(true);

//     try {
//       if (
//         updatedTeamData?.jobs?.has_more &&
//         page === 2 &&
//         Object.entries(initialState?.moreJobs)?.length === 0
//       ) {
//         await getMoreJobs(TeamID?.teamId, page);

//         if (initialState?.moreJobs?.data?.length > 0) {
//           let b = initialState?.singleTeam?.assigned_jobs_list?.data?.concat(
//             initialState?.moreJobs?.data
//           );

//           let c = b?.map((job) => ({
//             label: job?.task_name || "N/A",
//             value: job?.task_id,
//           }));

//           setArrJobs(c);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching options:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (
//       (updatedTeamData?.jobs?.has_more === true && page === 2) ||
//       (initialState?.moreJobs?.has_more === true && page > 2) ||
//       initialState?.moreJobs?.data?.length > 0
//     ) {
//       setLoading(true);
//       getMoreJobs(TeamID?.teamId, page);
//       if (
//         (initialState?.moreJobs?.data && initialState?.moreJobs?.has_more) ||
//         Object.entries(initialState?.moreJobs)?.length > 0
//       ) {
//         let setFormatedData = initialState?.moreJobs?.data?.map((job) => ({
//           label: job?.task_name || "N/A",
//           value: job?.task_id,
//         }));
//         let b = arrJobs?.concat(setFormatedData);
//         // let c = b?.map((job) => ({
//         //   label: job?.task_name || "N/A",
//         //   value: job?.task_id,
//         // }));

//         // console.log("cc", c);

//         let d = parentJobFilterData?.concat(initialState?.moreJobs?.data);
//         setParentJobFilterData(d);
//         setArrJobs(b);
//         // If no more data is available, stop loading
//         if (!initialState?.moreJobs?.has_more) {
//           setLoading(false);
//         }
//       }
//     }
//   }, [initialState?.moreJobs?.has_more, page]);

//   const handleMenuScrollToBottom = () => {
//     if (
//       (updatedTeamData?.jobs?.has_more &&
//         Object.entries(initialState?.moreJobs).length === 0) ||
//       initialState?.moreJobs?.has_more
//     ) {
//       setPage((prevPage) => prevPage + 1); // Load next page
//     }
//   };
//   //use Effect page change
//   React.useEffect(() => {
//     fetchOptions();
//   }, [page]); // Refetch when page changes

//   useEffect(() => {
//     setProjectOptions(arrJobs);
//   }, [arrJobs]);

//   // ===============================================================
//   // For Advanced Billing Jobs

//   // Fetch initial options
//   const fetchAdvancedBillingMoreJobOptions = async () => {
//     setLoading(true);
//     try {
//       if (
//         updatedTeamData?.advancedBillingJobs?.has_more &&
//         advanceJobpage === 2 &&
//         Object.entries(initialState?.moreAdvacedBillingJobs)?.length === 0
//       ) {
//         await getMoreAdvancedBillingJobs(TeamID?.teamId, advanceJobpage);
//         if (initialState?.advancedBillingJobs?.data?.length > 0) {
//           let b = initialState?.singleTeam?.advance_billing_list?.data?.concat(
//             initialState?.moreAdvacedBillingJobs?.data
//           );
//           let c = b?.map((job) => ({
//             label: job?.task_name || "N/A",
//             value: job?.task_id,
//           }));
//           setAdvancedArrJobs(c);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching options:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (
//       (updatedTeamData?.advancedBillingJobs?.has_more === true &&
//         advanceJobpage === 2) ||
//       (initialState?.moreAdvacedBillingJobs?.has_more === true &&
//         advanceJobpage > 2) ||
//       initialState?.moreAdvacedBillingJobs?.data?.length > 0
//     ) {
//       setLoading(true);
//       getMoreAdvancedBillingJobs(TeamID?.teamId, advanceJobpage);
//       if (
//         (initialState?.moreAdvacedBillingJobs?.data &&
//           initialState?.moreAdvacedBillingJobs?.has_more) ||
//         Object.entries(initialState?.moreAdvacedBillingJobs)?.length > 0
//       ) {
//         let setFormatedData = initialState?.moreAdvacedBillingJobs?.data?.map(
//           (job) => ({
//             label: job?.task_name || "N/A",
//             value: job?.task_id,
//           })
//         );
//         let b = advancedArrJobs?.concat(setFormatedData);
//         // let c = b?.map((job) => ({
//         //   label: job?.task_name || "N/A",
//         //   value: job?.task_id,
//         // }));

//         // console.log("cc", c);

//         let d = parentAdvancedBillingJobFilterData?.concat(
//           initialState?.moreAdvacedBillingJobs?.data
//         );
//         setParentAdvancedBillingJobFilterData(d);
//         setAdvancedArrJobs(b);
//         // If no more data is available, stop loading
//         if (!initialState?.moreAdvacedBillingJobs?.has_more) {
//           setLoading(false);
//         }
//       }
//     }
//   }, [initialState?.moreAdvacedBillingJobs?.has_more, advanceJobpage]);

//   // Fetch options on scroll to bottom
//   const handleAdvancedJobMenuScrollToBottom = () => {
//     if (
//       (updatedTeamData?.advancedBillingJobs?.has_more &&
//         Object.entries(initialState?.moreAdvacedBillingJobs).length === 0) ||
//       initialState?.moreAdvacedBillingJobs?.has_more
//     ) {
//       setAdvanceJobPage((prevPage) => prevPage + 1); // Load next page
//     }
//   };

//   //use Effect page change
//   React.useEffect(() => {
//     fetchAdvancedBillingMoreJobOptions();
//   }, [advanceJobpage]); // Refetch when page changes

//   useEffect(() => {
//     setAdvancedBillingJobOptions(advancedArrJobs);
//   }, [advancedArrJobs]);
//   // =================================================================
//   // =================================================================

//   // for UnAllocated Free Members
//   const fetchUnallocatedMembersOptions = async () => {
//     setLoading(true);
//     try {
//       if (unAllocatedPage === 1) {
//         console.log("fetchUnallocatedMembersOptions chalaa");
//         await getMembersFreeFromTeam(unAllocatedPage);
//       }
//     } catch (error) {
//       console.error("Error fetching options:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (
//       (initialState?.unassignedMembersList?.has_more === true &&
//         unAllocatedPage > 1) ||
//       (updatedTeamData?.freeMembersList?.has_more === true &&
//         unAllocatedPage > 2) ||
//       initialState?.unassignedMembersList?.data?.length > 0
//     ) {
//       setLoading(true);

//       let setFormatedData = generateUnAssignedMembersList(
//         initialState?.unassignedMembersList?.data &&
//           initialState?.unassignedMembersList?.data
//       );
//       console.log("setFormatedData", setFormatedData);

//       let b = unAllocatesMembers?.concat(setFormatedData);
//       setUnAllocatesMembers(b);
//       getMembersFreeFromTeam(unAllocatedPage);

//       // let setFormatedData = generateUnAssignedMembersList(
//       //   initialState?.unassignedMembersList?.data &&
//       //     initialState?.unassignedMembersList?.data
//       // );
//       // console.log("setFormatedData", setFormatedData);

//       // let b = unAllocatesMembers?.concat(setFormatedData);
//       // setUnAllocatesMembers(b);
//     }
//   }, [initialState?.unassignedMembersList?.has_more, unAllocatedPage]);

//   // Fetch options on scroll to bottom
//   const handleUnallocatedMembersMenuScrollToBottom = () => {
//     if (
//       updatedTeamData?.freeMembersList?.has_more &&
//       Object.entries(initialState?.unassignedMembersList).length > 0
//     ) {
//       setUnAllocatedPage((prevPage) => prevPage + 1); // Load next page
//     }
//   };

//   //use Effect page change
//   React.useEffect(() => {
//     fetchUnallocatedMembersOptions();
//   }, [unAllocatedPage]); // Refetch when page changes

//   useEffect(() => {
//     setUnAllocatesMembersOptions(unAllocatesMembers);
//   }, [unAllocatesMembers]);

//   console.log("unAllocatesMembers", unAllocatesMembers);
//   console.log(
//     " updatedTeamData?.freeMembersList",
//     updatedTeamData?.freeMembersList
//   );

//   // =============================================================
//   // for updating a team api
//   const updateTeam = async () => {
//     setIsLoading(() => true);
//     try {
//       const body = {
//         current_user: +localStorage.getItem("userId") ?? null,
//         team_name: updatedTeamData.teamName,
//         team_leader: +updatedTeamData.teamLeader?.value,
//         team_sub_leader: updatedTeamData.teamSubLeader
//           .map(({ value }) => value)
//           .join(","),
//         team_member: updatedTeamData.teamMembersName
//           .map(({ value }) => value)
//           .join(","),

//         team_id: +initialState?.singleTeam.id,
//         status: updatedTeamData.teamStatus,
//       };

//       const url = `${mainURL}update/team`;
//       const result = await axios.put(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");
//         navigate("/teams");
//         setUnAllocatedPage("");
//         setUnAllocatesMembersOptions([]);
//         setUnAllocatesMembers([]);
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//     }
//   };

//   const handleUpdateTeam = (e) => {
//     e.preventDefault();
//     if (
//       updatedTeamData.teamName &&
//       updatedTeamData.teamLeader &&
//       updatedTeamData.teamLeaderEmail &&
//       updatedTeamData.teamMembersName &&
//       updatedTeamData.teamStatus
//     ) {
//       updateTeam();
//     } else {
//       if (updatedTeamData.teamName === "") {
//         ReactHotToast("Please input team name!", "error");
//       } else if (updatedTeamData.teamLeader === "") {
//         ReactHotToast("Please select team leader!", "error");
//         // } else if (updatedTeamData.teamLeaderEmail === "") {
//         //   ReactHotToast("Please input team leader email!", "error");
//       } else if (updatedTeamData.teamMembersName.length <= 0) {
//         ReactHotToast("Please select team members!", "error");
//       } else if (updatedTeamData.teamStatus === "") {
//         ReactHotToast("Please select team status!", "error");
//       }
//     }
//   };

//   return (
//     <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
//       <div className="mr-40 ml-30 mb-15">
//         <Breadcrumbs crumbs={breadCrumbs} />
//       </div>

//       <section className="main-content_header">
//         <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
//           <img src={teamsIcon1} alt="Manage Team" />
//           <p className="m-0 fs-4">Manage Team</p>
//         </div>
//       </section>
//       <section className="main-content_form-section d-flex justify-content-center align-items-start w-75 m-auto">
//         <form
//           onSubmit={handleUpdateTeam}
//           className="w-100 d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="d-flex justify-content-center align-items-start gap-5 w-100">
//             <div className="w-50">
//               <div className="form-group mt-5">
//                 <label htmlFor="teamCode">Team Code:</label>
//                 <input
//                   id="teamCode"
//                   name="teamCode"
//                   placeholder="Eg: 00101"
//                   type="text"
//                   value={updatedTeamData?.teamCode}
//                   required
//                   disabled
//                 />
//               </div>
//               <div className="form-group mt-4">
//                 <label htmlFor="teamName">Team Name:</label>
//                 <input
//                   id="teamName"
//                   name="teamName"
//                   placeholder="Eg: Team Titans"
//                   type="text"
//                   value={updatedTeamData?.teamName}
//                   onChange={(e) =>
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       teamName: e.target.value,
//                     }))
//                   }
//                   required
//                 />
//               </div>
//               <div className="form-group mt-4">
//                 <label htmlFor="teamLeader">Team Leader:</label>
//                 <Select
//                   name="teamLeader"
//                   closeMenuOnSelect={true}
//                   options={teamLeaderOptions}
//                   onChange={(value) => {
//                     const updatedSubLeaders =
//                       updatedTeamData.teamSubLeader.filter(
//                         (subLeader) => subLeader.value !== value.value
//                       );

//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       teamLeader: value,
//                       teamSubLeader: updatedSubLeaders,
//                     }));
//                   }}
//                   value={updatedTeamData.teamLeader}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>

//               <div className="form-group mt-4">
//                 <label htmlFor="teamSubLeader">Team Sub-Leader:</label>

//                 <Select
//                   name="teamSubLeader"
//                   closeMenuOnSelect={true}
//                   isMulti
//                   options={unAllocatesMembersOption}
//                   //  options={generateMemberOptions()}
//                   onChange={(value) => {
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       teamSubLeader: value,
//                     }));
//                   }}
//                   value={updatedTeamData.teamSubLeader}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>
//               {/* <div className="form-group mt-4">
//               <label htmlFor="teamSubLeaderName">
//                 Current Sub Leader Name:
//               </label>
//               <input
//                 id="teamSubLeaderName"
//                 name="teamSubLeaderName"
//                 value={updatedTeamData?.teamSubLeaderName}
//                 disabled
//               />
//             </div> */}

//               <div className="form-group mt-4">
//                 <label htmlFor="teamLeaderEmail">Team Leader Email:</label>
//                 <input
//                   id="teamLeaderEmail"
//                   name="teamLeaderEmail"
//                   placeholder="Eg: mailto:rajshah@gmail.com"
//                   type="email"
//                   value={updatedTeamData?.teamLeaderEmail}
//                   disabled
//                 />
//               </div>

//               <div className="form-group mt-4">
//                 <label htmlFor="teamMemberCount">Team Member Count:</label>
//                 <input
//                   id="teamMemberCount"
//                   name="teamMemberCount"
//                   placeholder="Eg: 12"
//                   type="number"
//                   value={updatedTeamData?.teamMembersCount}
//                   disabled
//                   required
//                 />
//               </div>
//               <button
//                 type="submit"
//                 className="mt-4 w-25 custom-btn d-flex justify-content-center align-items-center"
//               >
//                 {isLoading ? <SpinningLoader /> : "Update"}
//               </button>
//             </div>
//             <div className="w-50">
//               <div className="form-group mt-5">
//                 <label htmlFor="teamMembers">Team Members:</label>
//                 {/* {/ {JSON.stringify(membersOptions)} /} */}

//                 <Select
//                   closeMenuOnSelect={false}
//                   components={animatedComponents}
//                   isMulti
//                   options={unAllocatesMembersOption}
//                   onChange={(member) => {
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       teamMembersName: member,
//                     }));
//                   }}
//                   onMenuScrollToBottom={
//                     // updatedTeamData?.jobs?.has_more && handleMenuScrollToBottom
//                     handleUnallocatedMembersMenuScrollToBottom
//                   }
//                   name="teamMembers"
//                   value={updatedTeamData.teamMembersName}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>
//               <div className="form-group mt-4">
//                 <label htmlFor="teamStatus">Team Status:</label>
//                 <Select
//                   name="teamStatus"
//                   closeMenuOnSelect={true}
//                   options={statusOptions}
//                   onChange={({ value }) =>
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       teamStatus: value,
//                     }))
//                   }
//                   value={
//                     updatedTeamData.teamStatus === "active"
//                       ? { label: "Active", value: "active" }
//                       : { label: "Inactive", value: "inactive" }
//                   }
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>
//               {/* {/ Assigned Jobs List /} */}
//               <div className="form-group mt-4">
//                 <label htmlFor="clientName">Assigned Jobs List:</label>
//                 <Select
//                   name="teamStatus"
//                   closeMenuOnSelect={true}
//                   // options={arrJobs}
//                   options={projectOptions}
//                   // options={[
//                   //   ...projectOptions,
//                   //   {
//                   //     label: (
//                   //       <span>
//                   //         {/ <SpinningLoader /> /}
//                   //         Loading.....
//                   //       </span>
//                   //     ),
//                   //     value: null,
//                   //   },
//                   // ]}
//                   onMenuScrollToBottom={
//                     updatedTeamData?.jobs?.has_more && handleMenuScrollToBottom
//                   }
//                   onChange={(option) =>
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       selectedJob: option,
//                     }))
//                   }
//                   value={updatedTeamData.selectedJob}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>
//               {updatedTeamData.selectedJob && (
//                 <div className={`projectDetails mt-4`}>
//                   <div className="d-flex align-items-center gap-3 mt-2">
//                     <p className={`bigText fs-5 m-0`}>
//                       Project Name:{" "}
//                       <span className={`smallText fs-6`}>
//                         {updatedTeamData.selectedJob.label}
//                       </span>
//                     </p>
//                     <Stack direction="horizontal">
//                       {getJobDetails()?.job_status === "Completed" ? (
//                         <Badge bg="success">Completed</Badge>
//                       ) : getJobDetails()?.job_status === "On Hold" ? (
//                         <Badge bg="danger">On Hold</Badge>
//                       ) : (
//                         <Badge bg="warning" text="dark">
//                           In Progress
//                         </Badge>
//                       )}
//                     </Stack>
//                   </div>
//                   {getJobDetails()?.job_description && (
//                     <p className="fs-6 m-0 mt-3 fw-semibold">
//                       Project Description:{" "}
//                       <span>{getJobDetails()?.job_description}</span>
//                     </p>
//                   )}
//                   <p className="fs-5 m-0 mt-3">Estimated Timeline: </p>
//                   <p className="fs-6 m-0">
//                     {formatDate(getJobDetails()?.assigned_on)} to{" "}
//                     {formatDate(getJobDetails()?.due_on)}
//                   </p>
//                 </div>
//               )}

//               {/* {/ Advanced Billing Job List /} */}
//               <div className="form-group mt-4">
//                 <label htmlFor="advanceBillingJobs">
//                   Advanced Billing Job List:
//                 </label>
//                 <Select
//                   name="advanceBillingJobs"
//                   closeMenuOnSelect={true}
//                   // options={arrJobs}
//                   options={advancedBillingJobOptions}
//                   // options={[
//                   //   ...projectOptions,
//                   //   {
//                   //     label: (
//                   //       <span>
//                   //         {/ <SpinningLoader /> /}
//                   //         Loading.....
//                   //       </span>
//                   //     ),
//                   //     value: null,
//                   //   },
//                   // ]}
//                   onMenuScrollToBottom={
//                     updatedTeamData?.advancedBillingJobs?.has_more &&
//                     handleAdvancedJobMenuScrollToBottom
//                   }
//                   onChange={(option) =>
//                     setUpdatedTeamData((prev) => ({
//                       ...prev,
//                       selectedAdvacedBillingJob: option,
//                     }))
//                   }
//                   value={updatedTeamData?.selectedAdvacedBillingJob}
//                   className="react-select-custom-styling__container"
//                   classNamePrefix="react-select-custom-styling"
//                 />
//               </div>

//               {updatedTeamData?.selectedAdvacedBillingJob && (
//                 <div className={`projectDetails mt-4`}>
//                   <div className="d-flex align-items-center gap-3 mt-2">
//                     <p className={`bigText fs-5 m-0`}>
//                       Project Name:{" "}
//                       <span className={`smallText fs-6`}>
//                         {updatedTeamData.selectedAdvacedBillingJob.label}
//                       </span>
//                     </p>
//                     <Stack direction="horizontal">
//                       {getadvacedBillingJobDetails()?.job_status ===
//                       "Completed" ? (
//                         <Badge bg="success">Completed</Badge>
//                       ) : getadvacedBillingJobDetails()?.job_status ===
//                         "On Hold" ? (
//                         <Badge bg="danger">On Hold</Badge>
//                       ) : (
//                         <Badge bg="warning" text="dark">
//                           In Progress
//                         </Badge>
//                       )}
//                     </Stack>
//                   </div>
//                   {getadvacedBillingJobDetails()?.job_description && (
//                     <p className="fs-6 m-0 mt-3 fw-semibold">
//                       Project Description:{" "}
//                       <span>
//                         {getadvacedBillingJobDetails()?.job_description}
//                       </span>
//                     </p>
//                   )}
//                   <p className="fs-5 m-0 mt-3">Assigned On: </p>
//                   <p className="fs-6 m-0">
//                     {formatDate(getadvacedBillingJobDetails()?.assigned_on)}
//                     {/* to{" "}
//                   {formatDate(getadvacedBillingJobDetails()?.due_on)} */}
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//         </form>
//       </section>
//     </div>
//   );
// };
// export default ManageTeam;
