import React, { useContext, useMemo, useEffect, useState } from "react";
import Select from "react-select";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";

import Breadcrumbs from "../../../../templates/Breadcrumbs";
import {
  DownSVG,
  reportsIcon,
  fileIcon,
  teamsIcon1,
  searchIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../templates/PageHeader";

import styles from "./TeamsContent.module.css";
// import { formatDate } from "../../../../utils/utilities/utilityFunctions";
// import { EditTeamJobModal } from "./EditTeamJobModal";
import { EditTeamJobModal } from "./EditTeamJobModal";

import TeamTable from "./TeamTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import { Tooltip } from "react-tooltip";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

const TeamsContent = React.memo(
  ({
    teamData,
    setIsUpdated,
    handleTabChange,
    isUpdated,
    activeTab,
    setActiveTab,
    isLoading,
    setIsLoadingData,
    isLoadingData,
    content,
    advanceBillingTeamJob,
    filters,
    setFilters,
    searchInput,
    handleInputChange,
    handleSearch,
    currentPage,
    setCurrentPage,
    pageSize,
    totalPages,
    setPageSize,
  }) => {
    const { sidebarClose } = useContext(ContextSidebarToggler);
    const [teamJobs, setTeamJobs] = useState([]);
    const userRole = localStorage.getItem("userRole");
    const [currentRow, setCurrentRow] = useState("");
    // const [isLoadingData, setIsLoadingData] = useState(false);
    const [advanceBillingJobs, setAdvanceBillingJobs] = useState([]);

    const statusOptions = [
      { label: "In Progress", value: "In Progress" },
      { label: "On Hold", value: "On Hold" },
      { label: "Completed", value: "Completed" },
    ];
    const formatDate = (date) => {
      if (!date) return "N.A";

      let dateObj;

      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        dateObj = new Date(date);
      } else if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
        const [day, month, year] = date.split("-").map(Number);
        dateObj = new Date(year, month - 1, day);
      } else {
        return "N.A";
      }

      if (isNaN(dateObj.getTime())) return "N.A";

      const day = dateObj.getDate();
      const month = dateObj.toLocaleString("default", { month: "short" });
      const year = dateObj.getFullYear();
      const suffix = (day) => {
        if (day >= 11 && day <= 13) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      return `${day}${suffix(day)} ${month} ${year}`;
    };

    const breadCrumbs = [
      {
        pageName: "Home",
        pageURL: "/dashboard",
      },
      {
        pageName: "Teams",
        pageURL: "/teams",
      },
    ];

    const filteredJobs = useMemo(() => {
      if (Array.isArray(teamData)) {
        if (activeTab === "myJobs") {
          return filters?.status?.value
            ? teamData.filter((job) => job.job_status === filters.status.value)
            : teamData;
        } else if (activeTab === "advanceBilling") {
          return filters?.status?.value
            ? teamData.filter((job) => job.job_status === filters.status.value)
            : teamData;
        }
      }
      return [];
    }, [activeTab, filters, teamData]);

    useEffect(() => {
      if (Array.isArray(teamData)) {
        if (activeTab === "myJobs") {
          setAdvanceBillingJobs([]);
          setTeamJobs(filteredJobs);
        } else if (activeTab === "advanceBilling") {
          setTeamJobs([]);
          setAdvanceBillingJobs(filteredJobs);
        }
      }
    }, [activeTab, filteredJobs, teamData]);
    
    const data = useMemo(() => {
      return activeTab === "myJobs" ? teamJobs : advanceBillingJobs;
    }, [teamJobs, advanceBillingJobs, activeTab]);

    // Table code
    const tableColumns = useMemo(
      () => [
        {
          Header: "Sr no.",
          accessor: "sr no.",
          Cell: ({ row }) => row.index + 1,
        },
        // ...(activeTab === "myJobs"
        //   ? [
        //       {
        //         Header: "Job Code",
        //         accessor: "job_code",
        //         Cell: ({ row }) => (
        //           <span>
        //             {row.original?.job_code || (
        //               <span style={{ color: "grey" }}> {"N/A"}</span>
        //             )}
        //           </span>
        //         ),
        //       },
        //     ]
        //   : []),
        {
          Header: "Job Name",
          accessor: "task_name",
          Cell: ({ row }) =>
            row.original?.task_name || (
              <span style={{ color: "grey" }}> {"N/A"}</span>
            ),
        },
        {
          Header: "BPO No",
          accessor: "bpo_no",
          Cell: ({ row }) => {
            const additionalData = row.original.additional_data
              ? JSON.parse(row.original.additional_data)
              : {};
            return (
              row.original.bpo_no ||
              additionalData.bpo_no || (
                <span style={{ color: "grey" }}> {"N/A"}</span>
              )
            );
          },
        },

        {
          Header: "Period Start Date",
          accessor: "assigned_on",
          Cell: ({ row }) =>
            formatDate(row.original.assigned_on) || (
              <span style={{ color: "grey" }}> {"N/A"}</span>
            ),
        },

        ...(activeTab === "myJobs"
          ? [
              {
                Header: "Period End Date",
                accessor: "due_on",
                Cell: ({ row }) =>
                  formatDate(row.original.due_on) || (
                    <span style={{ color: "grey" }}> {"N/A"}</span>
                  ),
              },
            ]
          : []),
        {
          Header: "Job Description",
          accessor: "job_description",
          Cell: ({ row }) =>
            row.original.job_description || (
              <span style={{ color: "grey" }}> {"N/A"}</span>
            ),
        },
        ...(activeTab === "myJobs"
          ? [
              {
                Header: "Hours worked",
                accessor: "worked_hours",
                Cell: ({ row }) =>
                  row.original.total_billable_hours || (
                    <span style={{ color: "grey" }}> {"0"}</span>
                  ),
              },
            ]
          : []),
        ...(activeTab === "advanceBilling"
          ? [
              {
                Header: "Hours Worked",
                accessor: "total_advanced_billing_hours",

                Cell: ({ row }) =>
                  row.original.total_advanced_billing_hours || (
                    <span style={{ color: "grey" }}> {"0"}</span>
                  ),
              },
            ]
          : []),
        {
          Header: "Total Adjusted Hours",
          accessor: "adjust_hours",

          Cell: ({ row }) =>
            row.original.total_adjust_hours?.adjust_hours || (
              <span style={{ color: "grey" }}> {"0"}</span>
            ),
        },

        // ...(activeTab === "myJobs"
        //   ? [
        {
          Header: "Total Approved Hours",
          accessor: "approved_hours",

          Cell: ({ row }) =>
            row.original.total_approved_hours?.approved_hours || (
              <span style={{ color: "grey" }}> {"0"}</span>
            ),
        },

        //   ]
        // : []),

        {
          Header: "Status",
          accessor: "job_status",
          Cell: ({ row }) => (
            <div className="d-flex justify-content-center align-items-center">
              <Stack direction="horizontal">
                {row.original.job_status === "Completed" ? (
                  <Badge bg="success">Completed</Badge>
                ) : row.original.job_status === "On Hold" ? (
                  <Badge bg="danger">On Hold</Badge>
                ) : (
                  <Badge bg="warning" text="dark">
                    In Progress
                  </Badge>
                )}
              </Stack>
            </div>
          ),
        },

        ...(userRole !== "members"
          ? [
              {
                Header: "Action",
                Cell: ({ row }) => (
                  <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
                    <Tooltip
                      id="update"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      data-tooltip-id="update"
                      data-tooltip-content="Update"
                      data-tooltip-place="top"
                    >
                      {userRole === "team_leaders,members" ||
                      userRole === "members,team_sub_leader" ? (
                        <EditTeamJobModal
                          teamJobData={row.original}
                          teamId={teamData?.id}
                          setIsUpdated={setIsUpdated}
                        />
                      ) : null}
                    </div>
                    {row.original.time_entries_for_task !==
                      "No time entries made for this job" && (
                      <div
                        style={{ cursor: "pointer" }}
                        data-tooltip-id="show more"
                        data-tooltip-content="Show More"
                        data-tooltip-place="top"
                      >
                        {userRole !== "members" && (
                          <div
                            onClick={() => {
                              setCurrentRow((prev) => {
                                if (prev === row.original.task_id) {
                                  return "";
                                } else {
                                  return row.original.task_id;
                                }
                              });
                            }}
                          >
                            <DownSVG />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ),
              },
            ]
          : []),
      ],
      [activeTab]
    );

    const columnHeaders = [
      "Sr no",
      // "Job Code",
      "Task Name",
      "Period Start Date",
      "Period End Date",
      "Job Description",
      "Status",
      "Entered By",
    ];

    const columns = useMemo(() => tableColumns, [activeTab, teamJobs]);
    const tableInstance = useTable(
      {
        columns: tableColumns,
        data: filteredJobs,
        // initialState: { pageSize },
        initialState: { pageSize: pageSize },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    const teamJobHeaders = {
      headings: [
        // { label: "Job Code", key: "job_code" },
        { label: "Task Name", key: "task_name" },
        { label: "Period Start Date", key: "assigned_on" },
        { label: "Period End Date", key: "due_on" },
        { label: "Job Description", key: "job_description" },
        { label: "Status", key: "job_status" },
      ],
      fileName: "Team Data",
    };

    const advanceBillingHeaders = {
      headings: [
        { label: "Job Name", key: "task_name" },
        { label: "Job Description", key: "job_description" },
        { label: "Period Start Date", key: "assigned_on" },
        { label: "Status", key: "job_status" },
      ],
      fileName: "Advance Billing Data",
    };
    const currentHeaders =
      activeTab === "myJobs" ? teamJobHeaders : advanceBillingHeaders;

    const handleStatusChange = (selectedOption) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        status: selectedOption,
      }));
    };

    const handlePageChange = (page) => {
      if (page > 0 && page <= totalPages) {
        setCurrentPage(page);
        setIsUpdated((prev) => !prev);
      }
    };

    const handlePageSizeChange = (event) => {
      const newSize = Number(event.target.value);
      setPageSize(newSize);
      setCurrentPage(1);
      setIsUpdated((prev) => !prev);
    };
    // const handlePageChange = (page) => {
    //   if (page > 0 && page <= totalPages) {
    //     setCurrentPage(page);
    //     localStorage.setItem("currentPage", page);
    //     setIsUpdated((prev) => !prev);
    //   }
    // };

    // const handlePageSizeChange = (event) => {
    //   const newSize = Number(event.target.value);
    //   setPageSize(newSize); // Update the component's state
    //   setCurrentPage(1); // Reset to the first page
    //   localStorage.setItem("pageSize", newSize); // Save the page size
    //   localStorage.setItem("currentPage", 1); // Save the new page number
    //   setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
    // };
    useEffect(() => {
      tableInstance.setPageSize(pageSize);
    }, [pageSize, tableInstance]);


    return (
      <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
        <div className="mr-40 ml-30 mb-15">
          <Breadcrumbs crumbs={breadCrumbs} />
        </div>

        <section className="main-content_header add-border-bottom">
          <div className="d-flex justify-content-center align-items-center page-heading">
            <img src={fileIcon} alt="Invoice" />
            <p className="m-0 fs-4">Team Jobs</p>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <div className="relative-wrapper">
              <img className="search-icon" src={searchIcon} alt="search-icon" />
              <input
                className="input-field"
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </section>

        <div className="mt-5">
          {(userRole === "team_leaders,members" ||
            userRole === "members" ||
            userRole === "members,team_sub_leader") && (
            <div className="mr-40 ml-30 mb-4 d-flex justify-content-start align-items-center gap-4">
              <div className="relative-wrapper w-25">
                <img
                  className="search-icon"
                  src={reportsIcon}
                  alt="search-icon"
                />

                <Select
                  closeMenuOnSelect={true}
                  isClearable={true}
                  options={statusOptions}
                  value={filters.status || null}
                  onChange={handleStatusChange}
                  placeholder="Select status"
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
            </div>
          )}
        </div>

        <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-1">
          <button
            className={`btn ${
              activeTab === "myJobs" ? "btn-light" : "btn-secondary"
            }`}
            style={{
              backgroundColor: activeTab === "myJobs" ? "#00263d" : "",
              color: activeTab === "myJobs" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => handleTabChange("myJobs")}
          >
            Team Jobs
          </button>
          <button
            className={`btn ${
              activeTab === "advanceBilling" ? "btn-light" : "btn-secondary"
            }`}
            style={{
              backgroundColor: activeTab === "advanceBilling" ? "#00263d" : "",
              color: activeTab === "advanceBilling" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => handleTabChange("advanceBilling")}
          >
            Advance Billing
          </button>
        </div>
        {userRole === "team_leaders,members" ||
        userRole === "members" ||
        userRole === "members,team_sub_leader" ? (
          <>
            {isLoading ? (
              <ReactTableSkeleton columnHeaders={columnHeaders} />
            ) : (
              <>
                <div className="table-container">
                  <TeamTable
                    teamData={
                      activeTab === "myJobs"
                        ? teamJobs
                        : advanceBillingJobs || []
                    }
                    currentRow={currentRow}
                    tableInstance={tableInstance}
                    headers={currentHeaders}
                    setIsUpdated={setIsUpdated}
                  />
                  {/* {activeTab === "myJobs" && (
                    <TeamTable
                      currentRow={currentRow}
                      tableInstance={tableInstance}
                      headers={currentHeaders}
                      teamData={teamJobs}
                      setIsUpdated={setIsUpdated}
                    />
                  )}

                  {activeTab === "advanceBilling" && (
                    <TeamTable
                      currentRow={currentRow}
                      tableInstance={tableInstance}
                      headers={currentHeaders}
                      teamData={advanceBillingJobs}
                      setIsUpdated={setIsUpdated}
                    />
                  )} */}
                </div>

       

                <div
                  className="d-flex justfy-content-center align-items-center gap-3 smallText"
                  style={{
                    width: "45%",
                    justifyContent: "space-between",
                    position: "absolute",
                    marginLeft: "90px",
                    bottom: "48px",
                    marginTop: "15px",
                  }}
                >
                  <div className="d-flex gap-1 align-items-center">
                    <button
                      className="cursor-pointer"
                      style={{ border: "none", background: "transparent" }}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                        />
                      </svg>
                    </button>

           
                    <input
                      className="gotoPage-input-field"
                      type="number"
                      value={currentPage}
                      readOnly
                    />
                    <span className="px-2">/</span>
                    <span>{totalPages}</span>

               
                    <button
                      style={{ border: "none", background: "transparent" }}
                      className="cursor-pointer"
                      // onClick={() => handlePageChange(currentPage + 1)}
                      // disabled={
                      //   teamData?.length < pageSize ||
                      //   totalPages === 1
                      // }
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        teamData?.length < pageSize ||
                        totalPages === 11 ||
                        currentPage === totalPages
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                        />
                      </svg>
                    </button>
                  </div>

             
                  {/* <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div> */}
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    );
  }
);

export default TeamsContent;
