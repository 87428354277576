import React, { useCallback, useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import InvoiceList from "./components/InvoiceList";
import { debounce } from "lodash";
// import BillingServicesContent from "./components/BillingServicesContent";

const GetAllInvoice = () => {
  const { getAllInvoice, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);

  // useEffect(() => {
  //   getAllInvoice();
  // }, [isUpdated]);

  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
  });

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);
  const [loading1, setLoading1] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getAllJobCategories();
  //     getAllBillableTimeEntries(currentPage, pageSize);
  //   }, 100);

  //   // Cleanup function to clear the timer
  //   return () => clearTimeout(timer);
  // }, [isUpdated, currentPage, pageSize]);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllInvoice(currentPage, pageSize, searchQuery, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllInvoice(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchInput === "" && !searchTriggered) {
  //       getAllInvoice(currentPage, pageSize, "", filters);
  //     } else if (searchTriggered && searchInput !== "") {
  //       getAllInvoice(currentPage, pageSize, searchInput, filters);
  //     }
  //   }, 500); // 500ms debounce delay

  //   // Cleanup timeout if input changes before delay is over
  //   return () => clearTimeout(timer);
  // }, [currentPage, pageSize, searchInput, searchTriggered, filters, isUpdated]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchInput === "" && !searchTriggered) {
  //       getAllInvoice(currentPage, pageSize, "", filters);
  //     } else if (searchTriggered && searchInput !== "") {
  //       getAllInvoice(currentPage, pageSize, searchInput, filters);
  //     }
  //   }, 500); // 500ms debounce delay

  //   // Cleanup timeout if input changes before delay is over
  //   return () => clearTimeout(timer);
  // }, [currentPage, pageSize, searchInput, searchTriggered, filters, isUpdated]);

  // ============================================

  return (
    <InvoiceList
      invoiceData={initialState?.getAllInvoice}
      setIsUpdated={setIsUpdated}
      isLoading={initialStateLoading?.getAllInvoiceIsLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      // handleSearch={handleSearch}
      handleInputChange={handleInputChange}
      setFilters={setFilters}
      filters={filters}
    />
  );
};

export default GetAllInvoice;
