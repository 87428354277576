import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import removeImg from "../../../assets/images/remove.png";
import {
  headerOptions,
  handleAPIError,
} from "../../../utils/utilities/utilityFunctions";
import {
  PlusIconSVG,
  settingsIcon1,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";

const IpAddress = () => {
  const { mainURL, logout, userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [ipAddress, setIpAddress] = useState([]); // For handling dynamic IP inputs
  const [ipAddressList, setIpAddressList] = useState([]); // For fetching and displaying existing IP addresses
  const [hideIpButton, setHideIpButton] = useState(false); // For showing "Add" button when IPs are added
  const breadCrumbs = [
    { pageName: "Home", pageURL: "/dashboard" },
    { pageName: "Ip Address", pageURL: "/ip-address" },
  ];
  const [isLoading, setIsLoading] = useState(false);

  // Fetch allowed IP addresses
  const showIpAddress = async () => {
    setIsLoading(true);
    const userId =
      localStorage.getItem("userId") ?? userDetails?.member_id ?? null;
    const url = `${mainURL}get/allow-ip-address/${userId}`;
    try {
      const result = await axios.get(url, { headers: headerOptions() });
      const ipAddress = result?.data?.ip_addresses ?? [];
      setIpAddressList(ipAddress);
    } catch (error) {
      handleAPIError(error);
      console.error("Error fetching IP address:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    showIpAddress();
  }, []);

  // Add IP addresses
  const addIpAddressData = async () => {
    try {
      const url = `${mainURL}add/allow-ip-address`;
      const body = {
        ip_address: ipAddress.map((item) => item.ipAddress.trim()),
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
      };
      const result = await axios.post(url, body, { headers: headerOptions() });
      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setIpAddress([]); // Reset input fields
        showIpAddress(); // Refresh the list of IPs
        setHideIpButton(false); // Hide the add button
      }
    } catch (e) {
      handleAPIError(e, logout);
    }
  };

  // Handle form submit (adding IP addresses)
  const handleAddIpAddress = (e) => {
    e.preventDefault();
    if (!ipAddress.length) {
      ReactHotToast("Please enter an IP address!", "error");
      return;
    }
    addIpAddressData();
  };

  // Handle input change for each IP address field
  const handleInputChange = (index, value) => {
    const updatedIpAddress = [...ipAddress];
    updatedIpAddress[index] = { ipAddress: value };
    setIpAddress(updatedIpAddress);
  };

  // Handle removal of an IP address input
  const handleRemoveInput = (index) => {
    const updatedIpAddress = [...ipAddress];
    updatedIpAddress.splice(index, 1);
    setIpAddress(updatedIpAddress);

    // Hide the "Add IP Address" button if no inputs are left
    if (updatedIpAddress.length === 0) {
      setHideIpButton(false);
    }
  };

  // Handle adding a new IP address input
  const handleAddInput = () => {
    setIpAddress([...ipAddress, { ipAddress: "" }]);
    setHideIpButton(true); // Show the "Add" button when a new input is added
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={settingsIcon1} alt="clients" />
          <p className="m-0 fs-4">IP Address</p>
        </div>
      </section>

      <div className="mb-5 relative-wrapper zIndex-2"></div>

      <div className="m-auto mt-3 gap-5 main-content_form-section d-flex w-50">
        <div className="dashboard-block d-flex flex-1 flex-column gap-1">
          <div className="block-header d-flex justify-content-between w-100">
            <p className="block-title m-0">Allowed IP Address</p>
          </div>

          <div className="block-content w-95 mt-1">
            <span
              className="ipAddress-btn d-flex justify-content-center align-items-center gap-2"
              onClick={handleAddInput}
            >
              Add IP Address <span className="fw-light fs-4">+</span>
            </span>
          </div>

          <div className="w-100">
            <form onSubmit={handleAddIpAddress}>
              {ipAddress.map((value, index) => (
                <div className="d-flex" key={index}>
                  <div className="form-group w-50 mb-2">
                    <label>IP Address:</label>
                    <input
                      id={`ipAddress-${index}`}
                      name={`ipAddress-${index}`}
                      placeholder="Enter IP address"
                      type="text"
                      required
                      value={value.ipAddress}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "21px", padding: "0px 22px" }}>
                    <span onClick={() => handleRemoveInput(index)}>
                      <img
                        src={removeImg}
                        style={{ width: "32px", cursor: "pointer" }}
                        alt="Remove"
                      />
                    </span>
                  </div>
                </div>
              ))}
              {hideIpButton && (
                <button type="submit" className="mt-2 custom-btn">
                  Add
                </button>
              )}
            </form>
          </div>

          <div>
            {isLoading ? (
              <tr>
                <td
                  colSpan={3}
                  style={{
                    backgroundColor: "#00263d",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "7px",
                    }}
                  >
                    <SpinningLoader />
                  </div>
                </td>
              </tr>
            ) : (
              ipAddressList &&
              ipAddressList.map((value, index) => (
                <div className="ipAddressList" key={index}>
                  <p>IP Address: {value.allow_ips}</p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IpAddress;
