// import React, { useContext } from "react";

// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext"
// import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";

// const BillableTimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const shouldShowContent =
//   userDetails?.member_role === "it_member" ||
//   userDetails?.member_role === "operation_member";

//   return (
//     <>

//      {shouldShowContent && <BillableTimeEntriesContent />}

//     </>
//   );
// };

// export default BillableTimeEntries;

import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";

import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import BillableTimeEntriesContent from "../../AdminOrManager/TimeEntries/components/BillableTimeEntriesContent";

const BillableTimeEntries = () => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    getAllBillableTimeEntries,
    getAllJobCategories,
    initialState,
    initialStateLoading,
    userDetails,
  } = useContext(ContextAPI);

  const [filters, setFilters] = useState({
    status: null, // status (approved/pending)
    entryAs: null, // member/team
    showDiscountedHoursEntries: false, // Adjusted Hour checkbox
    reviewer: "", // Reviewer name for filtering
  });
  const shouldShowContent =
    userDetails?.member_role === "it_member" ||
    userDetails?.member_role === "operation_member";

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    // If search is cleared, reset the page to 1
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput, filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   const fetchBillableEntries = () => {
  //     if (searchInput === "" && !searchTriggered) {
  //       getAllBillableTimeEntries(currentPage, pageSize, "", filters);

  //     }
  //     else if (searchTriggered && searchInput !== "") {
  //       getAllBillableTimeEntries(currentPage, pageSize, searchInput, filters);
  //     }
  //   };

  //   fetchBillableEntries();
  // }, [currentPage, pageSize, searchInput, searchTriggered, filters, isUpdated]);

  // Effect to trigger debounced search
  // useEffect(() => {
  //   if (searchTriggered && searchInput !== "") {
  //     debouncedSearch(searchInput);
  //   } else {
  //     getAllBillableTimeEntries(currentPage, pageSize, searchInput, filters);
  //   }

  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [
  //   isUpdated,
  //   currentPage,
  //   pageSize,
  //   searchInput,

  //   filters,

  // ]);

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      getAllBillableTimeEntries(currentPage, pageSize, searchInput, filters);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentPage, pageSize, searchInput, filters, isUpdated]);

  return (
    <>
      {shouldShowContent && (
        <BillableTimeEntriesContent
          billableTimeEntriesPropsData={initialState?.allBillableTimeEntries}
          setIsUpdated={setIsUpdated}
          isLoading={initialStateLoading?.getAllBillableTimeEntriesIsLoading}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          // handleSearch={handleSearch}
          handleInputChange={handleInputChange}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </>
  );
};

export default BillableTimeEntries;
