import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";

import JobsContent from "./components/JobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const AdminOrManagerJobs = () => {
  const { getAllJobs, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllJobs(currentPage, pageSize, searchQuery);
    }, 500),
    [currentPage, pageSize]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllJobs(currentPage, pageSize, "");
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);
  // useEffect(() => {
  //   const fetchAllJobs = () => {
  //     if (searchTriggered && searchInput !== "") {
  //       getAllJobs(currentPage, pageSize, searchInput);
  //     } else {
  //       getAllJobs(currentPage, pageSize, "");
  //     }
  //   };

  //   fetchAllJobs();
  // }, [currentPage, pageSize, searchInput, searchTriggered, isUpdated]);

  return (
    <JobsContent
      jobsData={initialState?.jobs}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      isLoading={initialStateLoading?.getAllJobsIsLoading}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      // handleSearch={handleSearch}
      handleInputChange={handleInputChange}
    />
  );
};

export default AdminOrManagerJobs;
