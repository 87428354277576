import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import PageHeader from "../../../../templates/PageHeader";
import ReportsTable from "../../components/ReportsTable";
// import ClientWiseTable from "./ClientWiseTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import {
  InvoiceIcon,
  reportsIcon,
  userIcon1,
  advanceBilling,
  searchIcon,
  fileIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import AdvanceBillingReportTable from "./AdvanceBillingReportTable";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { AdvanceBillingReportModal } from "./AdvanceBillingReportModel";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
import { MarkInvoiceModel } from "../../../Clients/Invoice/components/MarkInvoiceModel";
import { EditTeamJobModal } from "../../../Teams/MemberOrTeamLeader/components/EditTeamJobModal";
import { EditAdvanceBillingReportsStatusModel } from "./EditAdvanceBillingReportsStatusModel";
// import { ClientWiseReportModal } from "./ClientWiseReportModel";

// Bread Crumbs
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },

  {
    pageName: "Advance Billing Report",
    pageURL: "/reports/advance_billing_report",
  },
];

// constructing Headers for React Skelton
const columnHeaders = [
  "Sr no.",
  "Job Name",
  "Client Name",
  "period Start Date",
  "Status",
  "Email ID",
  "Allocated Hours",
  "Payment Status",
  "Action",
];

const AdvanceBillingReportContent = ({
  advancebillingReportData,
  setIsUpdated,
  isUpdated,
  isLoading,
  searchInput,
  handleInputChange,
  handleSearch,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  filters,
  setFilters,
}) => {
  const { getAllAdvanceBillingJobs, mainURL } = useContext(ContextAPI);
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [advanceBillingReportDataList, setAdvanceBillingReportDataList] =
    useState([]);
  // const [filters, setFilters] = useState({
  //   status: null,
  //   assignedTo: null,
  // });

  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];

  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Period Start Date", key: "assigned_on" },
      { label: "Status", key: "job_status" },
      { label: "Email ID", key: "email" },
      { label: "Allocated Hours", key: "allocated_hours" },
    ],
    fileName: "Advance Billing Report",
  };
  useEffect(() => {
    const filterByStatus = advancebillingReportData?.filter((report) => {
      return filters.status ? report.job_status === filters.status.value : true;
    });
    setAdvanceBillingReportDataList(filterByStatus || []);
  }, [filters, advancebillingReportData, pageSize]);

  useEffect(() => {
    setAdvanceBillingReportDataList(advancebillingReportData);
  }, [advancebillingReportData]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },

    {
      Header: "Period Start Date",
      accessor: "assigned_on",
      Cell: ({ row }) => {
        const assigneOnDate = row.original.assigned_on;
        if (!assigneOnDate) return "N/A"; 
    
        const dateObj = new Date(assigneOnDate);
        if (isNaN(dateObj)) return "N/A";
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return dateObj.toLocaleDateString('en-GB', options); 
      },
    },
    
    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Email ID",
      accessor: "email",
      Cell: ({ row }) =>
        JSON.parse(row.original?.additional_data)?.primary_email,
    },
 
    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
      Cell: ({ row }) =>
        row.original.allocated_hours?.split(":")[0] || (
          <span style={{ color: "grey" }}>N/A</span>
        ),
    },
    {
      Header: "Payment Status",
      accessor: "payment_status",
      Cell: ({ row }) => {
        const paymentStatus = row.original.payment_status;

        return (
          <div className="d-flex justify-content-start">
            <Stack direction="horizontal" style={{ alignItems: "center" }}>
              {paymentStatus === "1" ? (
                <Badge bg="danger">Unpaid</Badge>
              ) : paymentStatus === "2" ? (
                <Badge bg="warning">Partially Paid</Badge>
              ) : paymentStatus === "3" ? (
                <Badge bg="success">Paid</Badge>
              ) : null}
            </Stack>
          </div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <div
            style={{
              marginRight: "19%",
              cursor: "pointer",
              display: "flex",
              gap: "5px",
            }}
          >
            {/* Preview Invoice Tooltip */}
            <Tooltip
              id="preview-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            {row.original.invoice_url ? (
              <div
                data-tooltip-id="preview-invoice-tooltip"
                data-tooltip-content="Preview Invoice"
                data-tooltip-place="top"
              >
                <div
                  onClick={() => {
                    const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                    const rowData = row.original;
                    const assignId = row.original.id;
                    const clientEmail = JSON.parse(
                      row.original?.additional_data
                    )?.primary_email;

                    navigate("/advance_billing_report_invoice", {
                      state: {
                        invoice_id: row.original.id,
                        invoice_url: invoiceUrl,
                        row: rowData,
                        assignId: assignId || null,
                        clientEmail: clientEmail,
                      },
                    });
                  }}
                >
                  <InvoiceIcon />
                </div>
              </div>
            ) : null}

            {/* Advance Billing Report Modal Tooltip */}
            <Tooltip
              id="advance-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="advance-tooltip"
              data-tooltip-content="show more"
              data-tooltip-place="top"
            >
              <AdvanceBillingReportModal
                AdvanceBillingReportData={row.original}
                setIsUpdated={setIsUpdated}
              />
            </div>

            {/* Edit Advance Billing Reports Status Tooltip */}
            <Tooltip
              id="edit-status-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            {(row.original.payment_status === "1" ||
              row.original.payment_status === "2") && (
              <div
                data-tooltip-id="edit-status-tooltip"
                data-tooltip-content="Edit Payment Status"
                data-tooltip-place="top"
              >
                <EditAdvanceBillingReportsStatusModel
                  id={row.original.id}
                  setIsUpdated={setIsUpdated}
                />
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);

  const data = useMemo(
    () => advanceBillingReportDataList,
    [advanceBillingReportDataList]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const transformDataForCSV = (advanceBillingReportData) => {
    return advanceBillingReportData.map((billingReportData) => {
      return {
        job_name: billingReportData?.job_name,
        client_name: billingReportData?.client_name,
        assigned_on: billingReportData?.assigned_on,
        job_status: billingReportData?.job_status,
        email: JSON.parse(billingReportData?.additional_data)?.primary_email,
        allocated_hours: billingReportData?.allocated_hours,
      };
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  // Use the function to get CSV data
  const csvAdvanceBillingData = transformDataForCSV(
    advanceBillingReportDataList
  );
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance, isUpdated]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      {/* <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          // icon={userIcon1}
          icon={advanceBilling}
          headerTitle={"Advance Billing Report"}
        ></PageHeader>
      </div> */}
      <section className="main-content_header add-border-bottom mb-5">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={advanceBilling} alt="Advance Billing Report" />
          <p className="m-0 fs-4">Advance Billing Report</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
          {/* <button
            onClick={handleSearch}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            {loading1 ? <SpinningLoader /> : "  Search"}
          </button> */}
        </div>
      </section>
      <div className="ml-30 mr-40 relative-wrapper w-75">
        <img className="search-icon" src={reportsIcon} alt="search-icon" />
        <Select
          closeMenuOnSelect={true}
          isClearable={true}
          options={statusOptions}
          onChange={(option) => {
            setFilters((prev) => ({
              ...prev,
              status: option,
            }));
          }}
          value={filters.status}
          placeholder="Select status"
          className="react-select-custom-styling__container w-25"
          classNamePrefix="react-select-custom-styling"
        />
      </div>

      {/* Report Table  */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <AdvanceBillingReportTable
            tableInstance={tableInstance}
            headers={headers}
            columnHeaders={columnHeaders}
            advancebillingReportData={csvAdvanceBillingData}
          />

          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />

              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                // disabled={
                //   (
                //     advancebillingReportData?.length < pageSize) ||
                //   totalPages === 1
                // }
                // disabled={
                //   (advancebillingReportData && advancebillingReportData?.length < pageSize) ||
                //   totalPages === 1
                // }
                disabled={
                  advanceBillingReportDataList?.length < pageSize ||
                  currentPage === totalPages ||
                  totalPages === 1
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdvanceBillingReportContent;
