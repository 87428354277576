import React from "react";

import ReactTable from "../../../../templates/ReactTable";
import ReactTableFooter from "../../../../templates/ReactTableFooter";

const InvoiceListTable = ({ tableInstance, headers, invoice ,columnHeaders}) => {
  return (
    <section className="invoice-list-table mt-5 ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} columnHeaders={columnHeaders} />
      <ReactTableFooter
        headers={headers}
        data={invoice}
        tableInstance={tableInstance}
        text="invoice-list"
      />
    </section>
  );
};

export default InvoiceListTable;
