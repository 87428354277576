import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { json, useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";

import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

import {
  searchIcon,
  calendarIcon,
  reportsIcon,
  projectsIcon,
  InvoiceIcon,
  employeeIcon,
  usersIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../templates/PageHeader";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { formatDate } from "../../../utils/utilities/utilityFunctions";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import MemberListTable from "./MemberListTable";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import axios from "axios";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import { MemberTimeEntriesModal } from "./MemberTimeEntriesModal";
import { debounce } from "lodash";

const MemberList = ({
  setIsUpdated,
  isLoading,
  allMemberReports,
  currentPage,
  setCurrentPage,
  pageSize,
  isUpdated,
  setPageSize,
}) => {
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState([]);
  const { mainURL, userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const userId =
    localStorage.getItem("userId") ?? userDetails?.member_id ?? null;
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");
  const userRole = localStorage.getItem("userRole");
  const [totalPages, setTotalPages] = useState(0);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    // {
    //   pageName: "Reports",
    //   pageURL: "/reports",
    // },
    {
      pageName: "Member List",
      //   pageURL: "/assign-job",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "User role",
      accessor: "user_role",
      Cell: ({ row }) => {
        const rolesMap = {
          "team_leaders,members": "Team Leader, Member",
          members: "Member",
          "members,team_sub_leader": "Member, Team Sub Leader",
          team_leaders: "Team Leader",
        };
        const userRole = row.original.user_role;
        return userRole ? rolesMap[userRole] || userRole : null;
      },
    },
    {
      Header: "Team Names",
      accessor: "team_names",
    },

    {
      Header: "Billable Hours",
      accessor: "total_billable_hours",
      Cell: ({ row }) => (
        <span
          style={{ color: row.original.total_billable_hours ? "red" : "black" }}
        >
          {row.original.total_billable_hours || (
            <span style={{ color: "grey" }}> {"N/A"} </span>
          )}
        </span>
      ),
    },
    {
      Header: "Side Work Hours",
      accessor: "total_side_works_hours",
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original.total_side_works_hours ? "red" : "black",
          }}
        >
          {row.original.total_side_works_hours || (
            <span style={{ color: "grey" }}> {"N/A"} </span>
          )}
        </span>
      ),
    },
    {
      Header: "Advance Work Hours",
      accessor: "total_advance_hours",
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original.total_advance_hours ? "red" : "black",
          }}
        >
          {row.original.total_advance_hours || (
            <span style={{ color: "grey" }}> {"N/A"} </span>
          )}
        </span>
      ),
    },
    // ...(userRole !== "it_member" && userRole !== "operation_member"
    //   ? [
    {
      id: "action",
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <Tooltip
            id="time-entries-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="time-entries-tooltip"
            data-tooltip-content="Check Time Entries"
            data-tooltip-place="top"
          >
            <MemberTimeEntriesModal memberUserId={row.original.user_id} />
          </div>
        </div>
      ),
    },
    //   ]
    // : []),
  ];

  const columnHeaders = [
    "Sr no",
    "Name",
    "Email",
    "User role",
    "Team Names",
    "Billable Hours",
    "Side Work Hours",
    "Advance Work Hours",
    "Action",
  ];
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Reset the currentPage to 1 whenever searchTerm changes
    setCurrentPage(1);
  }, [searchTerm]);

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => memberList, [memberList]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);

  const headers = {
    headings: [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "User Role", key: "user_role" },
      { label: "Team Name", key: "team_names" },
      { label: "Billable hours", key: "total_billable_hours" },
      { label: "Side Work hours", key: "total_side_works_hours" },
    ],
    fileName: "Member List",
  };
  // Function to transform data for CSV export
  const transformDataForCSV = (memberData) => {
    return memberData.map((member) => {
      return {
        name: member?.name,
        email: member?.email,
        user_role: member?.user_role,
        team_names: member?.team_names,
        total_billable_hours: member?.total_billable_hours
          ? member?.total_billable_hours
          : "N.A.",
        total_side_works_hours: member?.total_side_works_hours
          ? member?.total_side_works_hours
          : "N.A.",
      };
    });
  };

  // Use the function to get CSV data
  const csvMemberData = transformDataForCSV(data);
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState({
    rangeStartDate: null,
    rangeEndDate: null,
  });

  const disabledFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const handleRangeChange = (selectedDates) => {
    if (selectedDates && selectedDates.length === 2) {
      const [start, end] = selectedDates;
      if (start.isBefore(dayjs()) && end.isBefore(dayjs())) {
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        setDates({ rangeStartDate: start, rangeEndDate: end });
      }
    } else {
      setDates({ rangeStartDate: null, rangeEndDate: null });
    }
    setCurrentPage(1);
  };

  const [searchTriggered, setSearchTriggered] = useState(false);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setIsUpdated((prev) => !prev); // This should trigger your data fetch
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    setCurrentPage(1); // Reset to the first page when changing page size
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Reset current page to 1
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance, isUpdated]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const page = currentPage;
  //     const limit = pageSize;

  //     // Determine the start and end dates based on the selected range
  //     let formattedStartDate = dates.rangeStartDate
  //       ? dates.rangeStartDate.format("YYYY-MM-DD")
  //       : dayjs().format("YYYY-MM-DD");
  //     let formattedEndDate = dates.rangeEndDate
  //       ? dates.rangeEndDate.format("YYYY-MM-DD")
  //       : dayjs().format("YYYY-MM-DD");

  //     const url = `${mainURL}reports/member-wise/${userId}/${formattedStartDate}/${formattedEndDate}?page=${page}&limit=${limit}`;
  //     if (searchQuery) {
  //       url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //     }
  //     setLoading(true);
  //     try {
  //       const result = await axios.get(url, { headers: headerOptions() });
  //       const memberWiseReports =
  //         result.data.member_wise_reports.reports_data || [];
  //       setTotalPages(result.data.member_wise_reports.totalPages || 0);
  //       setMemberList(memberWiseReports);
  //     } catch (error) {
  //       console.error("Error fetching member-wise reports:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [currentPage, pageSize, dates]); // Add dates to the dependency array
  // useEffect(() => {
  const fetchData = async (page, limit, searchQuery = "") => {
    let url = `${mainURL}reports/member-wise/${userId}/${formattedStartDate}/${formattedEndDate}?page=${page}&limit=${limit}`;

    if (searchQuery) {
      url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
    }

    setLoading(true);
    try {
      const result = await axios.get(url, { headers: headerOptions() });
      const memberWiseReports =
        result.data.member_wise_reports.reports_data || [];
      setTotalPages(result.data.member_wise_reports.totalPages || 0);
      setMemberList(memberWiseReports);
    } catch (error) {
      console.error("Error fetching member-wise reports:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (searchQuery) => {
      fetchData(currentPage, pageSize, searchQuery);
    }, 500),
    [currentPage, pageSize]
  );

  const handleSearchInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchTerm(trimmedInput);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchTerm === "") {
      fetchData(currentPage, pageSize, "");
    } else {
      debouncedSearch(searchTerm);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, currentPage, pageSize, debouncedSearch, dates]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={usersIcon} alt="members" />
          <p className="m-0 fs-4">Member List</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchInputChange} // Use the debounced version of the search input
            />
          </div>
        </div>
      </section>

      <div className="mr-40 ml-30 mt-5 mb-15 d-flex gap-4">
        <div className="relative-wrapper">
          <img className="search-icon" src={calendarIcon} alt="search-icon" />
          <Space direction="vertical">
            <RangePicker
              disabledDate={disabledFutureDates}
              onChange={handleRangeChange}
              format="DD-MM-YYYY"
              className="react-select-custom-styling__control"
            />
          </Space>
        </div>
        {/* <div>
          <button className="custom-btn" onClick={handleShowList}>
            {loading ? <SpinningLoader /> : " Show List"}
          </button>
        </div> */}
      </div>

      {/* Conditional Rendering based on loading state */}
      {loading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <MemberListTable
            tableInstance={tableInstance}
            headers={headers}
            memberList={csvMemberData}
          />
          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  allMemberReports?.length < pageSize ||
                  totalPages === 1 ||
                  currentPage === totalPages
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MemberList;
