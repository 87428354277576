import React, { useEffect } from "react";

import ReactTable from "../../../../../templates/ReactTable";
import ReactTableFooter from "../../../../../templates/ReactTableFooter";

const JobCategoryTable = ({
  tableInstance,
  headers,
  jobCategories,
  columnHeaders,
}) => {
  // const { setPageSize } = tableInstance;

  // useEffect(() => setPageSize(7), []);

  return (
    <section className="">
      <ReactTable tableInstance={tableInstance} columnHeaders={columnHeaders} />
      <ReactTableFooter
        headers={headers}
        data={jobCategories}
        columnHeaders={columnHeaders}
        tableInstance={tableInstance}
        text="jobCategory"
      />
    </section>
  );
};

export default JobCategoryTable;
