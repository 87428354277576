// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// // import { DatePicker } from "antd";

// import { projectsIcon } from "../../../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
// import SelectElement from "../../../../../templates/SelectElement";
// import Select from "react-select";
// import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../../../utils/utilities/utilityFunctions";
// import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";

// import "react-datepicker/dist/react-datepicker.css";
// import { DatePicker, Space, message } from "antd";
// import moment from "moment";

// const MyVerticallyCenteredModal = (props) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const {
//     mainURL,
//     userDetails,
//     logout,
//     initialState,
//     getAllClients,
//     getAllTeams,
//     getAllBillingServices,
//     getTeamDetailsByMemberId,
//   } = useContext(ContextAPI);
//   const [jobDetails, setJobDetails] = useState({
//     jobName: "",
//     jobTypeId: "",
//     clientId: "",
//     // assignToTeam: false,
//     teamId: "",
//     description: "",
//     // billingService: "",
//     billingServiceSelected: "",
//     billingRate: "",
//     startDate: "",
//     endDate: "",
//   });

//   const [options, setOptions] = useState({
//     jobCategoryOptions: [],
//     clientOptions: [],
//     teamOptions: [],
//     billingServicesOptions: [],
//   });

//   useEffect(() => {
//     getAllClients();
//     getAllTeams();
//     getAllBillingServices();
//   }, []);

//   useEffect(() => {
//     if (
//       userDetails?.member_role === "members,team_sub_leader" ||
//       userDetails?.member_role === "members" ||
//       userDetails?.member_role === "team_leaders,members"
//     ) {
//       getTeamDetailsByMemberId();
//     }
//   }, []);

//   const handleClear = () => {
//     setJobDetails(() => ({
//       jobName: "",
//       jobTypeId: "",
//       clientId: "",
//       // assignToTeam: false,
//       teamId: "",
//       description: "",
//       billingServiceSelected: "",
//       billingRate: "",
//       startDate: "",
//       endDate: "",
//     }));
//   };

//   //  setting Dropdown Options for Jobs, Members List & Teams List
//   useEffect(() => {
//     setOptions((prev) => ({
//       ...prev,
//       jobCategoryOptions: initialState?.jobCategories?.map((category) => ({
//         label: category?.job_category_name,
//         value: category?.job_category_id,
//       })),

//       clientOptions: initialState?.clientsList?.map((client) => {
//         const { client_name, additional_data, client_id } = client;
//         const companyName = additional_data?.company_name || "";
//         const bpoNo = additional_data?.bpo_no?.toString() || "";
//         const label = `${client_name} (${companyName}${
//           bpoNo ? ` - ${bpoNo}` : ""
//         })`;
//         const billingRates = additional_data?.billing_rates || [];

//         return {
//           label,
//           value: client_id,
//           billingRates,
//         };
//       }),

//       teamOptions: initialState?.teamsList
//         ?.filter((team) => team.status === "active")
//         .map(({ id, team_name }) => ({
//           label: team_name,
//           value: id,
//         })),

//       billingServicesOptions: initialState.billingServicesList
//         .filter(({ service_status }) => service_status === "active")
//         .map(({ services_name, services_id }) => ({
//           label: services_name,
//           value: services_id,
//         })),
//     }));
//   }, [
//     initialState.jobCategories,
//     initialState.clientsList,
//     initialState.teamsList,
//     initialState.billingServicesList,
//   ]);

//   // for adding a new job api
//   const addNewJob = async () => {
//     setIsLoading(() => true);
//     try {
//       const formattedStartDate = moment(
//         jobDetails.startDate.dateString,
//         "DD-MM-YYYY"
//       ).format("YYYY-MM-DD");
//       const formattedEndDate = moment(
//         jobDetails.endDate.dateString,
//         "DD-MM-YYYY"
//       ).format("YYYY-MM-DD");

//       let body = {
//         current_user: localStorage.getItem("userId") ?? null,
//         job_name: jobDetails?.jobName,
//         job_type: jobDetails?.jobTypeId?.value,
//         client_id: jobDetails?.clientId?.value,
//         team_id:
//           userDetails?.member_role === "members,team_sub_leader" ||
//           userDetails?.member_role === "members" ||
//           userDetails?.member_role === "team_leaders,members"
//             ? initialState?.myTeamDetails?.id
//             : jobDetails?.teamId?.value,
//         assigned_on: formattedStartDate,
//         due_date: formattedEndDate,
//         billing_services: jobDetails?.billingServiceSelected?.value,
//         // billing_services: selectedBillingServices,
//         billing_rates: jobDetails?.billingRate,
//         job_description: jobDetails?.description,
//         assign_to: "Team",
//       };

//       const url = `${mainURL}add/job`;
//       const result = await axios.post(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 201 || result.status === 200) {
//         ReactHotToast(result.data.message, "success");
//         props.setIsUpdated((prev) => !prev);
//         props.onHide();
//         handleClear();
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(() => false);
//     }
//   };
//   const handleAddNewJob = (e) => {
//     e.preventDefault();
//     const {
//       teamId,
//       jobName,
//       jobTypeId,
//       clientId,
//       billingServiceSelected,
//       billingRate,
//       startDate,
//       endDate,
//     } = jobDetails;

//     const errors = [];

//     if (!jobName) errors.push("Please enter Job Name!");
//     if (!jobTypeId) errors.push("Please select Job Type!");
//     if (!clientId) errors.push("Please select Client!");
//     if (
//       !(
//         userDetails?.member_role === "members,team_sub_leader" ||
//         userDetails?.member_role === "members" ||
//         userDetails?.member_role === "team_leaders,members"
//       )
//     ) {
//       if (!teamId) errors.push("Please select Team!");
//     }
//     // if (!teamId) errors.push("Please select Team!");
//     if (!billingServiceSelected) errors.push("Please select Billing Service");
//     if (!billingRate) errors.push("Please select Billing Rate");
//     if (!startDate) errors.push("Please select Start Date");
//     if (!endDate) errors.push("Please select End Date");

//     if (errors.length === 0) {
//       addNewJob();
//     } else {
//       ReactHotToast(errors[0], "error");
//     }
//   };

//   const onChangeDate = (element, date, dateString) => {
//     setJobDetails((prev) => ({
//       ...prev,
//       [element]: { date, dateString },
//     }));
//   };

//   const handleChange = (date, dateString, element) => {
//     onChangeDate(element, date, dateString);
//   };

//   const handleDropDown = (dropDown, option) => {
//     if (dropDown === "jobTypeId") {
//       setJobDetails((prev) => ({
//         ...prev,
//         jobTypeId: option,
//       }));
//     }
//     if (dropDown === "teamId") {
//       setJobDetails((prev) => ({
//         ...prev,
//         teamId: option,
//       }));
//     }
//     if (dropDown === "clientId") {
//       const selectedClient = initialState?.clientsList.find(
//         (client) => client?.client_id === option?.value
//       );
//       if (selectedClient) {
//         try {
//           const additionalData = selectedClient.additional_data;
//           const serviceLookup = initialState.billingServicesList
//             .filter(({ service_status }) => service_status === "active")
//             .reduce((acc, { services_name, services_id, service_rate }) => {
//               acc[services_id] = { name: services_name, rate: service_rate };
//               return acc;
//             }, {});

//           const billingServices = (additionalData.billing_services || []).map(
//             ({ service_id }) => ({
//               label: serviceLookup[service_id]?.name || `Service ${service_id}`,
//               value: service_id,
//             })
//           );

//           setOptions((prev) => ({
//             ...prev,
//             billingServicesOptions: billingServices,
//           }));

//           setJobDetails((prev) => ({
//             ...prev,
//             clientId: option,
//             billingServiceSelected: null,
//             billingRate: "",
//           }));

//           if (jobDetails.billingServiceSelected) {
//             const selectedServiceRate =
//               serviceLookup[jobDetails.billingServiceSelected.value]?.rate ||
//               "";
//             setJobDetails((prev) => ({
//               ...prev,
//               billingRate: selectedServiceRate,
//             }));
//           }
//         } catch (error) {
//           console.error(
//             `Error parsing additional_data for job_id ${selectedClient.client_id}:`,
//             error
//           );
//         }
//       }
//     } else if (dropDown === "billingServiceSelected") {
//       const selectedServiceId = option.value;
//       const selectedClient = initialState.clientsList.find(
//         (client) => client?.client_id === jobDetails.clientId?.value
//       );

//       if (selectedClient) {
//         try {
//           const additionalData = selectedClient.additional_data;
//           const selectedService = additionalData.billing_services.find(
//             ({ service_id }) => service_id === selectedServiceId
//           );

//           setJobDetails((prev) => ({
//             ...prev,
//             billingServiceSelected: option,
//             billingRate: selectedService?.service_rate || "",
//           }));
//         } catch (error) {}
//       }
//     }
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Add New Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleAddNewJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="form-group mt-3 w-100">
//             <label className="jobType" htmlFor="jobTypeId">
//               Select Job Category:
//             </label>
//             <Select
//               name="jobTypeId"
//               closeMenuOnSelect={true}
//               options={options.jobCategoryOptions}
//               onChange={(option) => handleDropDown("jobTypeId", option)}
//               value={jobDetails.jobTypeId}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobName">Job Name:</label>
//             <input
//               id="jobName"
//               name="jobName"
//               placeholder="Eg: Neel Networks ITR Filing - Ongoing Job"
//               type="text"
//               // required
//               onChange={(e) =>
//                 setJobDetails((prev) => ({ ...prev, jobName: e.target.value }))
//               }
//               value={jobDetails.jobName}
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="clientId">Select Client:</label>
//             <Select
//               name="clientId"
//               closeMenuOnSelect={true}
//               options={options.clientOptions}
//               onChange={(option) => handleDropDown("clientId", option)}
//               value={jobDetails.clientId}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>

//           {userDetails?.member_role === "members,team_sub_leader" ||
//           userDetails?.member_role === "members" ||
//           userDetails?.member_role === "team_leaders,members" ? (
//             ""
//           ) : (
//             <div className="form-group mt-4 w-100">
//               <label htmlFor="teamId">Select Team:</label>
//               <Select
//                 name="teamId"
//                 closeMenuOnSelect={true}
//                 options={options.teamOptions}
//                 onChange={(option) => handleDropDown("teamId", option)}
//                 value={jobDetails.teamId}
//                 className="react-select-custom-styling__container"
//                 classNamePrefix="react-select-custom-styling"
//               />
//             </div>
//           )}

//           <div className="form-group mt-3 w-100">
//             <label htmlFor="billingServiceSelected">Billing Service:</label>
//             <Select
//               name="billingServiceSelected"
//               closeMenuOnSelect={true}
//               options={options.billingServicesOptions}
//               onChange={(option) =>
//                 handleDropDown("billingServiceSelected", option)
//               }
//               value={jobDetails.billingServiceSelected}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>

//           <div className="form-group mt-3 w-100">
//             <label htmlFor="billingRate">Billing Rate (per hr):</label>
//             <input
//               id="billingRate"
//               name="billingRate"
//               placeholder="Eg: 999"
//               type="number"
//               // required
//               onChange={(e) =>
//                 setJobDetails((prev) => ({
//                   ...prev,
//                   billingRate: e.target.value,
//                 }))
//               }
//               value={jobDetails.billingRate}
//             />
//           </div>

//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobDescription">Job Description:</label>
//             <textarea
//               id="jobDescription"
//               name="jobDescription"
//               className="w-100"
//               rows={3}
//               placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
//               value={jobDetails.description}
//               onChange={(e) =>
//                 setJobDetails((prev) => ({
//                   ...prev,
//                   description: e.target.value,
//                 }))
//               }
//             />
//           </div>

//           <div className="form-group mt-3 w-100">
//             <label htmlFor="startDate">Processing Period Start Date:</label>

//             <DatePicker
//               className="form-control datepicker"
//               popupClassName="pop-up-box"
//               onChange={(date, dateString) =>
//                 handleChange(date, dateString, "startDate")
//               }
//               value={jobDetails.startDate.date || null}
//               format="DD-MM-YYYY"
//               placeholder="Select or enter start date (DD-MM-YYYY)"
//               // disabledDate={(current) =>
//               //   current && current < moment().startOf("day")
//               // }
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="endDate">Processing Period End Date:</label>

//             <DatePicker
//               className="form-control datepicker"
//               popupClassName="pop-up-box"
//               onChange={(date, dateString) =>
//                 handleChange(date, dateString, "endDate")
//               }
//               value={jobDetails.endDate.date || null}
//               format="DD-MM-YYYY"
//               placeholder="Select or enter end date (DD-MM-YYYY)"
//               // disabledDate={(current) =>
//               //   current && current < moment().startOf("day")
//               // }
//             />
//           </div>
//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Add Job"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const AddJobsModal = ({ setIsUpdated }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <div
//         onClick={() => {
//           setModalShow(true);
//         }}
//       >
//         <button className="custom-btn d-flex justify-content-center align-items-center gap-2">
//           Add Job <span className="fw-light fs-4">+</span>
//         </button>
//       </div>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         setIsUpdated={setIsUpdated}
//       />
//     </>
//   );
// };

import React, { useState, useContext, useEffect, useMemo } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
// import { DatePicker } from "antd";
import { debounce } from "lodash";
import { projectsIcon } from "../../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import SelectElement from "../../../../../templates/SelectElement";
import Select from "react-select";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import {
  headerOptions,
  handleAPIError,
} from "../../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";

import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Space, message } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { AsyncPaginate } from "react-select-async-paginate";

const MyVerticallyCenteredModal = (props) => {
  const {
    mainURL,
    userDetails,
    logout,
    initialState,
    getAllClientsList,
    getAllClients,
    getAllTeams,
    getAllBillingServices,
    getTeamDetailsByMemberId,
  } = useContext(ContextAPI);
  const userID = localStorage.getItem("userId") || userDetails?.member_id;
  const [clients, setClients] = useState([]);
  // const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // const [nextPageScroll, setNextPageScroll] = useState(true);
  // const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    jobName: "",
    jobTypeId: "",
    clientId: "",
    // assignToTeam: false,
    teamId: "",
    description: "",
    // billingService: "",
    billingServiceSelected: "",
    billingRate: "",
    startDate: "",
    endDate: "",
  });

  const [options, setOptions] = useState({
    jobCategoryOptions: [],
    clientOptions: [],
    teamOptions: [],
    billingServicesOptions: [],
  });

  useEffect(() => {
    getAllClientsList();
    getAllTeams();
    getAllBillingServices();
  }, []);

  useEffect(() => {
    if (
      userDetails?.member_role === "members,team_sub_leader" ||
      userDetails?.member_role === "members" ||
      userDetails?.member_role === "team_leaders,members"
    ) {
      getTeamDetailsByMemberId();
    }
  }, [userDetails?.member_role]);

  const handleClear = () => {
    setJobDetails(() => ({
      jobName: "",
      jobTypeId: "",
      clientId: "",
      // assignToTeam: false,
      teamId: "",
      description: "",
      billingServiceSelected: "",
      billingRate: "",
      startDate: "",
      endDate: "",
    }));
  };

  const JobCategoriesMemo = useMemo(
    () => initialState?.jobCategories,
    [initialState?.jobCategories]
  );
  const AllTeamListMemo = useMemo(
    () => initialState.teamsList,
    [initialState.teamsList]
  );
  const BillingServicesListMemo = useMemo(
    () => initialState.billingServicesList,
    [initialState.billingServicesList]
  );

  //  setting Dropdown Options for Jobs, Members List & Teams List
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      jobCategoryOptions:
        JobCategoriesMemo &&
        JobCategoriesMemo?.map((category) => ({
          label: category?.job_category_name,
          value: category?.job_category_id,
        })),

      clientOptions:
        clients &&
        clients?.map((client) => {
          const { client_name, additional_data, client_id } = client;
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          const billingRates = additional_data?.billing_rates || [];

          return {
            label,
            value: client_id,
            billingRates,
          };
        }),

      teamOptions:
        AllTeamListMemo &&
        AllTeamListMemo?.filter((team) => team.status === "active").map(
          ({ id, team_name }) => ({
            label: team_name,
            value: id,
          })
        ),

      billingServicesOptions:
        BillingServicesListMemo &&
        BillingServicesListMemo?.filter(
          ({ service_status }) => service_status === "active"
        ).map(({ services_name, services_id }) => ({
          label: services_name,
          value: services_id,
        })),
    }));
  }, [JobCategoriesMemo, clients, AllTeamListMemo, BillingServicesListMemo]);

  // for adding a new job api
  const addNewJob = async () => {
    setIsLoading(() => true);
    try {
      const formattedStartDate = moment(
        jobDetails.startDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      const formattedEndDate = moment(
        jobDetails.endDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");

      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        job_name: jobDetails?.jobName,
        job_type: jobDetails?.jobTypeId?.value,
        client_id: jobDetails?.clientId?.value,
        team_id:
          userDetails?.member_role === "members,team_sub_leader" ||
          userDetails?.member_role === "members" ||
          userDetails?.member_role === "team_leaders,members"
            ? initialState?.myTeamDetails?.id
            : jobDetails?.teamId?.value,
        assigned_on: formattedStartDate,
        due_date: formattedEndDate,
        billing_services: jobDetails?.billingServiceSelected?.value,
        // billing_services: selectedBillingServices,
        billing_rates: jobDetails?.billingRate,
        job_description: jobDetails?.description,
        assign_to: "Team",
      };

      const url = `${mainURL}add/job`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 201 || result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
        props.onHide();
        handleClear();
        setValueForClient(null);
        setValueForTeam(null);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };
  const handleAddNewJob = (e) => {
    e.preventDefault();
    const {
      teamId,
      jobName,
      jobTypeId,
      clientId,
      billingServiceSelected,
      billingRate,
      startDate,
      endDate,
    } = jobDetails;

    const errors = [];

    if (!jobTypeId) errors.push("Please select Job Category!");
    if (!jobName) errors.push("Please enter Job Name!");
    if (!clientId) errors.push("Please select Client!");
    if (
      !(
        userDetails?.member_role === "members,team_sub_leader" ||
        userDetails?.member_role === "members" ||
        userDetails?.member_role === "team_leaders,members"
      )
    ) {
      if (!teamId) errors.push("Please select Team!");
    }
    // if (!teamId) errors.push("Please select Team!");
    if (!billingServiceSelected) errors.push("Please select Billing Service");
    if (!billingRate) errors.push("Please select Billing Rate");
    if (!startDate) errors.push("Please select Start Date");
    if (!endDate) errors.push("Please select End Date");

    if (errors.length === 0) {
      addNewJob();
    } else {
      ReactHotToast(errors[0], "error");
    }
  };

  const onChangeDate = (element, date, dateString) => {
    setJobDetails((prev) => ({
      ...prev,
      [element]: { date, dateString },
    }));
  };

  const handleChange = (date, dateString, element) => {
    onChangeDate(element, date, dateString);
  };

  const handleDropDown = (dropDown, option) => {
    if (dropDown === "jobTypeId") {
      setJobDetails((prev) => ({
        ...prev,
        jobTypeId: option,
      }));
    }
    if (dropDown === "teamId") {
      setJobDetails((prev) => ({
        ...prev,
        teamId: option,
      }));
    }
    if (dropDown === "clientId") {
      const selectedClient =
        initialState?.allClientsList &&
        initialState?.allClientsList?.find(
          (client) => client?.client_id === option?.value
        );

      if (selectedClient) {
        try {
          const additionalData = selectedClient.additional_data;
          const serviceLookup = initialState.billingServicesList
            .filter(({ service_status }) => service_status === "active")
            .reduce((acc, { services_name, services_id, service_rate }) => {
              acc[services_id] = { name: services_name, rate: service_rate };
              return acc;
            }, {});

          const billingServices = (additionalData.billing_services || []).map(
            ({ service_id }) => ({
              label: serviceLookup[service_id]?.name || `Service ${service_id}`,
              value: service_id,
            })
          );

          setOptions((prev) => ({
            ...prev,
            billingServicesOptions: billingServices,
          }));

          setJobDetails((prev) => ({
            ...prev,
            clientId: option,
            billingServiceSelected: null,
            billingRate: "",
          }));

          if (jobDetails.billingServiceSelected) {
            const selectedServiceRate =
              serviceLookup[jobDetails?.billingServiceSelected?.value]?.rate ||
              "";
            setJobDetails((prev) => ({
              ...prev,
              billingRate: selectedServiceRate,
            }));
          }
        } catch (error) {
          console.error(
            `Error parsing additional_data for job_id ${selectedClient.client_id}:`,
            error
          );
        }
      }
      setOptions((prev) => ({
        ...prev,
        clientOptions:
          clients &&
          clients?.map((client) => {
            const { client_name, additional_data, client_id } = client;
            const companyName = additional_data?.company_name || "";
            const bpoNo = additional_data?.bpo_no?.toString() || "";
            const label = `${client_name} (${companyName}${
              bpoNo ? ` - ${bpoNo}` : ""
            })`;
            const billingRates = additional_data?.billing_rates || [];

            return {
              label,
              value: client_id,
              billingRates,
            };
          }),
      }));
    } else if (dropDown === "billingServiceSelected") {
      const selectedServiceId = option?.value;
      const selectedClient =
        initialState?.allClientsList &&
        initialState?.allClientsList?.find(
          (client) => client?.client_id === jobDetails?.clientId?.value
        );

      if (selectedClient) {
        try {
          const additionalData = selectedClient?.additional_data;
          const selectedService = additionalData?.billing_services.find(
            ({ service_id }) => service_id === selectedServiceId
          );

          setJobDetails((prev) => ({
            ...prev,
            billingServiceSelected: option,
            billingRate: selectedService?.service_rate || "",
          }));
        } catch (error) {}
      }
    }
  };

  // // Applying debouncing to reduce un-necessary api calling
  // const debouncedHandleSearch = debounce((inputValue) => {
  //   if (inputValue) {
  //     searchHandlerFunctionForClientName(inputValue);
  //   } else {
  //     setOptions((prev) => ({
  //       ...prev,
  //       clientOptions:
  //         clients &&
  //         clients?.map((client) => {
  //           const { client_name, additional_data, client_id } = client;
  //           const companyName = additional_data?.company_name || "";
  //           const bpoNo = additional_data?.bpo_no?.toString() || "";
  //           const label = `${client_name} (${companyName}${
  //             bpoNo ? ` - ${bpoNo}` : ""
  //           })`;
  //           const billingRates = additional_data?.billing_rates || [];

  //           return {
  //             label,
  //             value: client_id,
  //             billingRates,
  //           };
  //         }),
  //     }));
  //   }
  // }, 200);

  // const searchHandlerFunctionForClientName = async (searchData) => {
  //   if (!searchData || typeof searchData !== "string") return;
  //   try {
  //     setIsDropdownLoading(true);
  //     const response = await axios.get(
  //       `${mainURL}list/all-client/${userDetails?.member_id}?limit=10&page=1&searchQuery=${searchData}`,
  //       {
  //         headers: headerOptions(),
  //       }
  //     );
  //     setOptions((prev) => ({
  //       ...prev,
  //       clientOptions:
  //         response?.data?.client_list?.client_data &&
  //         response?.data?.client_list?.client_data?.map((client) => {
  //           const { client_name, additional_data, client_id } = client;
  //           const companyName = additional_data?.company_name || "";
  //           const bpoNo = additional_data?.bpo_no?.toString() || "";
  //           const label = `${client_name} (${companyName}${
  //             bpoNo ? ` - ${bpoNo}` : ""
  //           })`;
  //           // const billingRates = additional_data?.billing_rates || [];
  //           return {
  //             label: label,
  //             value: client_id,
  //             // billingRates,
  //           };
  //         }),
  //     }));
  //   } catch (error) {
  //     console.log("Error fetching jobs:", error);
  //   } finally {
  //     setIsDropdownLoading(false);
  //   }
  // };

  // // Fetch clients based on page and limit
  // const fetchClients = async (currentPage) => {
  //   setIsDropdownLoading(true);
  //   try {
  //     let URL = `${mainURL}list/all-client/${
  //       userDetails?.member_id
  //     }?limit=${10}&page=${currentPage}`;

  //     const response = await axios.get(URL, {
  //       headers: headerOptions(),
  //     });
  //     // const response = await getAllClients();
  //     const newClients = response?.data?.client_list?.client_data; // Update according to your API response structure
  //     if (newClients.length === 0) {
  //       setNextPageScroll(false);
  //     }
  //     //
  //     // Append new data to existing list
  //     setClients((prev) => [...prev, ...newClients]);
  //     // setOptions((prev) => ({
  //     //   ...prev,
  //     //   clientOptions: clients,
  //     // }));
  //     setOptions((prev) => ({
  //       ...prev,
  //       clientOptions:
  //         clients &&
  //         clients?.map((client) => {
  //           const { client_name, additional_data, client_id } = client;
  //           const companyName = additional_data?.company_name || "";
  //           const bpoNo = additional_data?.bpo_no?.toString() || "";
  //           const label = `${client_name} (${companyName}${
  //             bpoNo ? ` - ${bpoNo}` : ""
  //           })`;
  //           const billingRates = additional_data?.billing_rates || [];

  //           return {
  //             label,
  //             value: client_id,
  //             billingRates,
  //           };
  //         }),
  //     }));
  //   } catch (error) {
  //     console.error("Error fetching clients:", error);
  //   } finally {
  //     setIsDropdownLoading(false);
  //   }
  // };

  // // Initial fetch
  // useEffect(() => {
  //   fetchClients(page);
  // }, [page]);

  // // Handle scroll to bottom
  // const handleScroll = () => {
  //   if (!isLoading) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };
  // ==================================================

  const [valueForClient, setValueForClient] = useState(null);
  const [valueForTeam, setValueForTeam] = useState(null);

  async function LoadClientsOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}/list/all-client/${userID || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data?.client_list?.client_data.map(
        (item) => {
          try {
            // Parse the additional client data
            const { client_name, additional_data, client_id } = item;
            const companyName = additional_data?.company_name || "";
            const bpoNo = additional_data?.bpo_no?.toString() || "";
            const label = `${client_name} (${companyName}${
              bpoNo ? ` - ${bpoNo}` : ""
            })`;

            // Create the label for the job

            return {
              value: client_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.client_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response.data?.client_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  async function LoadTeamsOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}get/team/${userID}`, {
        params: {
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData = response.data?.team_list?.team_data
        ?.filter((item) => item?.status !== "inactive")
        .map((item) => {
          try {
            // Create the label for the job
            const label = `${item?.team_name || "N/A"}`;
            return {
              value: item?.id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item?.id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.team_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Add New Job</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleAddNewJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label className="jobType" htmlFor="jobTypeId">
              Select Job Category:
            </label>
            <Select
              name="jobTypeId"
              closeMenuOnSelect={true}
              options={options.jobCategoryOptions}
              onChange={(option) => handleDropDown("jobTypeId", option)}
              value={jobDetails.jobTypeId}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobName">Job Name:</label>
            <input
              id="jobName"
              name="jobName"
              placeholder="Eg: Neel Networks ITR Filing - Ongoing Job"
              type="text"
              // required
              onChange={(e) =>
                setJobDetails((prev) => ({ ...prev, jobName: e.target.value }))
              }
              value={jobDetails.jobName}
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="clientId">Select Client:</label>
            {/* <Select
              name="clientId"
              closeMenuOnSelect={true}
              options={options.clientOptions}
              onChange={(option) => handleDropDown("clientId", option)}
              onMenuScrollToBottom={nextPageScroll && handleScroll}
              value={jobDetails.clientId}
              isLoading={isDropdownLoading}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              onInputChange={(inputValue) => debouncedHandleSearch(inputValue)}
            /> */}
            <AsyncPaginate
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={valueForClient}
              loadOptions={LoadClientsOptions}
              onChange={(selectedOption) => {
                setValueForClient(selectedOption);
                handleDropDown("clientId", selectedOption);
              }}
              additional={{
                page: 1,
              }}
              placeholder="Select Client"
              debounceTimeout={300}
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Client found for "${inputValue}"`
                  : "No Client found"
              }
              onError={(error) => {
                ReactHotToast("Error loading Client", "error");
                console.error("Async Paginate Client:", error);
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>

          {userDetails?.member_role === "members,team_sub_leader" ||
          userDetails?.member_role === "members" ||
          userDetails?.member_role === "team_leaders,members" ? (
            ""
          ) : (
            <div className="form-group mt-4 w-100">
              <label htmlFor="teamId">Select Team:</label>
              {/* <Select
                name="teamId"
                closeMenuOnSelect={true}
                options={options.teamOptions}
                onChange={(option) => handleDropDown("teamId", option)}
                value={jobDetails.teamId}
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
              /> */}
              <AsyncPaginate
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={valueForTeam}
                loadOptions={LoadTeamsOptions}
                onChange={(selectedOption) => {
                  setValueForTeam(selectedOption);
                  handleDropDown("teamId", selectedOption);
                }}
                additional={{
                  page: 1,
                }}
                placeholder="Select Team"
                debounceTimeout={300}
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No Team found for "${inputValue}"`
                    : "No Team found"
                }
                onError={(error) => {
                  ReactHotToast("Error loading Teams", "error");
                  console.error("Async Paginate Error:", error);
                }}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            </div>
          )}

          <div className="form-group mt-3 w-100">
            <label htmlFor="billingServiceSelected">Billing Service:</label>
            <Select
              name="billingServiceSelected"
              closeMenuOnSelect={true}
              options={options.billingServicesOptions}
              onChange={(option) =>
                handleDropDown("billingServiceSelected", option)
              }
              value={jobDetails.billingServiceSelected}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="billingRate">Billing Rate (per hr):</label>
            <input
              id="billingRate"
              name="billingRate"
              placeholder="Eg: 999"
              type="number"
              // required
              onChange={(e) =>
                setJobDetails((prev) => ({
                  ...prev,
                  billingRate: e.target.value,
                }))
              }
              value={jobDetails.billingRate}
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Job Description:</label>
            <textarea
              id="jobDescription"
              name="jobDescription"
              className="w-100"
              rows={3}
              placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
              value={jobDetails.description}
              onChange={(e) =>
                setJobDetails((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="startDate">Processing Period Start Date:</label>
            <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "startDate")
              }
              value={
                jobDetails.startDate.date
                  ? dayjs(jobDetails.startDate.date)
                  : null
              }
              format="DD-MM-YYYY"
              placeholder="Select or enter start date (DD-MM-YYYY)"
            />
            {/* <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "startDate")
              }
              value={jobDetails.startDate.date || null}
              format="DD-MM-YYYY"
              placeholder="Select or enter start date (DD-MM-YYYY)"
              // disabledDate={(current) =>
              //   current && current < moment().startOf("day")
              // }
            /> */}
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="endDate">Processing Period End Date:</label>
            <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "endDate")
              }
              value={
                jobDetails.endDate.date ? dayjs(jobDetails.endDate.date) : null
              }
              format="DD-MM-YYYY"
              placeholder="Select or enter end date (DD-MM-YYYY)"
              disabledDate={(current) => {
                // Disable dates before the selected start date using dayjs
                return (
                  current &&
                  current.isBefore(dayjs(jobDetails.startDate.date), "day")
                );
              }}
            />
            {/* <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "endDate")
              }
              value={jobDetails.endDate.date || null}
              format="DD-MM-YYYY"
              placeholder="Select or enter end date (DD-MM-YYYY)"
              // disabledDate={(current) =>
              //   current && current < moment().startOf("day")
              // }
            /> */}
          </div>
          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Add Job"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const AddJobsModal = ({ setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <button className="custom-btn d-flex justify-content-center align-items-center gap-2">
          Add Job <span className="fw-light fs-4">+</span>
        </button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
