import React, { useState, useContext } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import CreatableSelect from "react-select/creatable";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import {
  headerOptions,
  handleAPIError,
} from "../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import { invoiceIcon } from "../../../../utils/ImportingImages/ImportingImages";
import "react-quill/dist/quill.snow.css";
import styles from "./Invoice.module.css";

const MyVerticallyCenteredModal = ({
  show,
  onHide,
  clientId,
  invoiceIds,
  activeTab,
  emailOption,
  resetSelectedJobs,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { logout, getAllInvoice, mainURL, userDetails } =
    useContext(ContextAPI);
  const [mailDetails, setMailDetails] = useState({ to: "", subject: "" });
  const [emailBody, setEmailBody] = useState("");

  const clientEmailOptions = emailOption
    ? emailOption
        .split(",")
        .map((email) => ({ label: email.trim(), value: email.trim() }))
    : [];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  const handleClear = () => {
    setEmailBody("");
    setMailDetails({ to: "", subject: "" });
  };

  const mailInvoice = async () => {
    try {
      setIsLoading(true);
      const userId =
        localStorage.getItem("userId") ?? userDetails?.member_id ?? null;
      const body = {
        current_user: userId,
        client_id: clientId,
        invoice_ids: invoiceIds.join(","),
        send_to: mailDetails.to.value,
        email_subject: mailDetails.subject,
        email_body: emailBody,
      };

      const endpoint =
        activeTab === "invoice"
          ? `${mainURL}generate-statement`
          : activeTab === "advanceBilling"
          ? `${mainURL}generate-statement-for-advance-billing`
          : `${mainURL}generate-statement-for-ad-hoc-billing`;

      const result = await axios.post(endpoint, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        handleClear();
        resetSelectedJobs();
      }
    } catch (e) {
      console.error("Error sending email:", e);
      handleAPIError(e, logout);
    } finally {
      setIsLoading(false);
      onHide();
    }
  };

  const handleMailInvoice = (e) => {
    e.preventDefault();
    const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,20}$/;

    if (mailDetails.to?.value && mailDetails.subject && emailBody) {
      if (emailRegex.test(mailDetails.to.value)) {
        mailInvoice();
      } else {
        ReactHotToast(
          "Invalid email address. Please enter a valid email.",
          "error"
        );
      }
    } else {
      if (!mailDetails.to?.value) {
        ReactHotToast(
          "Please provide an email address to whom you want to send the invoice",
          "error"
        );
      } else if (!emailRegex.test(mailDetails.to.value)) {
        ReactHotToast(
          "Invalid email address. Please enter a valid email.",
          "error"
        );
      } else if (mailDetails.subject.trim() === "") {
        ReactHotToast("Please input the email subject", "error");
      } else if (emailBody.trim() === "") {
        ReactHotToast("Please input the email body", "error");
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={invoiceIcon} height={20} width={20} alt="invoice-icon" />
            <span className="modal-title">Mail Invoice</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleMailInvoice}
          className="d-flex flex-column align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label htmlFor="from">From:</label>
            <input
              id="from"
              type="email"
              value="invoice@doshioutsourcing.com"
              disabled
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="to">To:</label>
            <CreatableSelect
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable
              isSearchable
              name="to"
              value={mailDetails.to}
              onChange={(option) =>
                setMailDetails((prevState) => ({ ...prevState, to: option }))
              }
              options={clientEmailOptions}
              placeholder="Select or add new email"
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="subject">Subject:</label>
            <input
              id="subject"
              type="text"
              placeholder="Eg: Invoice Copy for ITR Filling service"
              value={mailDetails.subject}
              onChange={(e) =>
                setMailDetails((prev) => ({ ...prev, subject: e.target.value }))
              }
              required
            />
          </div>

          <div className="form-group mt-3 w-100">
            <label htmlFor="message">Message:</label>
            <ReactQuill
              theme="snow"
              value={emailBody}
              onChange={setEmailBody}
              placeholder="Write a short brief about the invoice..."
              modules={modules}
              className={styles.reactQuillEditor}
            />
          </div>

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Send"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const InvoiceSendMail = ({
  show,
  onHide,
  emailOption,
  invoiceIds,
  clientId,
  activeTab,
  resetSelectedJobs,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <button className="e-statement-btn" onClick={() => setModalShow(true)}>
        E-Statment
      </button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          onHide();
        }}
        invoiceIds={invoiceIds}
        clientId={clientId}
        activeTab={activeTab}
        emailOption={emailOption}
        resetSelectedJobs={resetSelectedJobs}
      />
    </>
  );
};
