// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllMembers();
//   }, [isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };
import React, { useCallback, useContext, useEffect, useState } from "react";

import MembersContent from "./components/MembersContent";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";

export const Members = () => {
  const { getAllMembers, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false);
  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.addEventListener("beforeunload", handleRefresh);
    };
  }, []);



  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getAllMembers(currentPage, pageSize);
  //   }, 100);

  //   // Cleanup function to clear the timer
  //   return () => clearTimeout(timer);
  // }, [isUpdated, currentPage, pageSize]);

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [filters]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, '');
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      getAllMembers(currentPage, pageSize, searchQuery);
    }, 500),
    [currentPage, pageSize]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchTriggered && searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      getAllMembers(currentPage, pageSize, "");
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [
    searchInput,
    searchTriggered,
    debouncedSearch,
    currentPage,
    pageSize,
    isUpdated,
  ]);

  return (
    <MembersContent
      membersData={initialState.membersList}
      totalPages={initialState?.totalPages}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      isLoading={initialStateLoading?.getAllMembersIsLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      // handleSearch={handleSearch}
      handleInputChange={handleInputChange}
      // setFilters={setFilters}
      // filters={filters}
    />
  );
};
// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllMembers();
//   }, [isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };
// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // Retrieve page number from localStorage, default to 1 if not found
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(
//     storedPage ? Number(storedPage) : 1
//   );

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(
//     storedPageSize ? Number(storedPageSize) : 10
//   );

//   useEffect(() => {
//     const handleRefresh = (event) => {
//       setCurrentPage(1);
//       setPageSize(10);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 10);
//     };
//     // Reset pagination on component mount
//     setCurrentPage(1);
//     setPageSize(10);
//     localStorage.setItem("currentPage", 1);
//     localStorage.setItem("pageSize", 10);
//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       // Clear localStorage values when component unmounts
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.addEventListener("beforeunload", handleRefresh);
//     };
//   }, []);

//   useEffect(() => {
//     // const timer = setTimeout(() => {
//       getAllMembers(currentPage, pageSize);
//     // }, 100);

//     // Cleanup function to clear the timer
//     // return () => clearTimeout(timer);
//   }, [ currentPage, pageSize,isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       totalPages={initialState?.totalPages}
//       setIsUpdated={setIsUpdated}
//       isUpdated={isUpdated}
//       isLoading={initialState.isLoading}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//     />
//   );
// };
