// import React, { useState, useEffect, useContext, useCallback } from "react";
// import { debounce } from "lodash";
// import AssignJobsContent from "./components/AssignJobsContent";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

// const AssignJobs = () => {
//   const { getAllAssignJobsForPagination, initialState, initialStateLoading } =
//     useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filters, setFilters] = useState({
//     status: null,
//     assignedTo: null,
//   });

//   // To track if it's the initial load
//   const [isFirstLoad, setIsFirstLoad] = useState(true);

//   // Pagination state
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((query, currentFilters, page, limit) => {
//       getAllAssignJobsForPagination(page, limit, query, currentFilters);
//     }, 500),
//     []
//   );

//   // Reset pagination when filters or search changes
//   useEffect(() => {
//     setCurrentPage(1);
//   }, [searchQuery, filters]);

//   // Effect for data fetching
//   // useEffect(() => {
//   //   debouncedSearch(searchQuery, filters, currentPage, pageSize);

//   //   return () => {
//   //     debouncedSearch.cancel();
//   //   };
//   // }, [searchQuery, filters, currentPage, pageSize, isUpdated]);

//   // Effect for data fetching
//   useEffect(() => {
//     if (isFirstLoad) {
//       // On initial load, directly fetch without debounce
//       getAllAssignJobsForPagination(
//         currentPage,
//         pageSize,
//         searchQuery,
//         filters
//       );
//       setIsFirstLoad(false);
//     } else {
//       // Apply debounced search after initial load
//       debouncedSearch(searchQuery, filters, currentPage, pageSize);
//     }

//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [searchQuery, filters, currentPage, pageSize, isUpdated, isFirstLoad]);

//   // Handle filter changes
//   const handleFilterChange = (type, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [type]: value,
//     }));
//   };

//   // Handle search changes
//   const handleSearchChange = (value) => {
//     setSearchQuery(value);
//   };

//   return (
//     <AssignJobsContent
//       setIsUpdated={setIsUpdated}
//       isLoading={initialStateLoading?.getAllAssignJobsForPaginationIsLoading}
//       fetchedAssignJobList={initialState?.assignJobsList}
//       totalPages={initialState?.totalPages}
//       currentPage={currentPage}
//       setCurrentPage={setCurrentPage}
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//       searchQuery={searchQuery}
//       onSearchChange={handleSearchChange}
//       filters={filters}
//       onFilterChange={handleFilterChange}
//     />
//   );
// };

// export default AssignJobs;

import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";
import AssignJobsContent from "./components/AssignJobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const AssignJobs = () => {
  const { getAllAssignJobsForPagination, initialState, initialStateLoading } =
    useContext(ContextAPI);
  const [searchQuery, setSearchQuery] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
  });

  // To track if it's the initial load
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query, currentFilters, page, limit) => {
      getAllAssignJobsForPagination(page, limit, query, currentFilters);
    }, 500),
    [isUpdated]
  );

  // Reset pagination when filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);

  // Effect for data fetching
  useEffect(() => {
    if (isFirstLoad) {
      // On initial load, directly fetch without debounce
      getAllAssignJobsForPagination(
        currentPage,
        pageSize,
        searchQuery,
        filters
      );
      setIsFirstLoad(false);
    } else {
      // Apply debounced search after initial load
      debouncedSearch(searchQuery, filters, currentPage, pageSize);
    }

    // Clean up function to cancel the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, filters, currentPage, pageSize, isFirstLoad, isUpdated]);

  // Handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  // Handle search changes
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  return (
    <AssignJobsContent
      isLoading={initialStateLoading?.getAllAssignJobsForPaginationIsLoading}
      fetchedAssignJobList={initialState?.assignJobsList}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      filters={filters}
      onFilterChange={handleFilterChange}
      setIsUpdated={setIsUpdated}
    />
  );
};

export default AssignJobs;
