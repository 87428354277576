import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { projectsIcon } from "../../../../../utils/ImportingImages/ImportingImages";

import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import {
  headerOptions,
  handleAPIError,
} from "../../../../../utils/utilities/utilityFunctions";
import moment from "moment";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
import TimePickerSection from "./TimePickerSection";
import { AsyncPaginate } from "react-select-async-paginate";

const MyVerticallyCenteredModal = (props) => {
  const { logout, mainURL, userId, initialState, userDetails } =
    useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [showBillableForm, setShowBillableForm] = useState(false);
  const [showsideWorkForm, setShowsideWorkForm] = useState(false);
  const [showPostDraftChangesForm, setShowPostDraftChangesForm] =
    useState(false);
  const [showAdvanceBillingForm, setShowAdvanceBillingForm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [value, setValue] = useState(null);
  const [valueForIndividual, setValueForIndividual] = useState(null);
  const [valueForPostDraftIndividual, setValueForPostDraftIndividual] =
    useState(null);
  const [valueForPostDraftJob, setValueForPostDraftJob] = useState(null);
  const [valueForAdvanceBilling, setValueForAdvanceBilling] = useState(null);
  const [teamId, setTeamId] = useState();
  useEffect(() => {
    setTeamId(initialState?.myTeamID);
  }, [initialState?.myTeamID]);

  const [entryDetails, setEntryDetails] = useState({
    startTime: "",
    endTime: "",
    entryDescription: "",
    job: null,
    entryAs: "",
    teamId: "" || teamId,
    jobOptions: [],
    advancedBillingJobOptions: [],
    postDraftJobListOptions: [],
    entryDate: "",
  });
  useEffect(() => {
    if (entryDetails?.entryAs === "Member") {
      setValue(null);
      setValueForAdvanceBilling(null);
      setValueForPostDraftJob(null);
      setValueForAdvanceBilling(null);
    }
    if (entryDetails?.entryAs === "Team") {
      setValueForIndividual(null);
      setValueForPostDraftIndividual(null);
      setValueForAdvanceBilling(null);
    }
  }, [entryDetails.entryAs]);

  useEffect(() => {
    setValueForAdvanceBilling(null);
  }, [showPostDraftChangesForm, showsideWorkForm, showBillableForm]);

  const handleClear = () => {
    setEntryDetails((prev) => ({
      ...prev,
      entryDescription: "",
      job: "",
      entryAs: "",
      startTime: "",
      endTime: "",
    }));
  };
  const onChangeDate = (element, date, dateString) => {
    setEntryDetails((prev) => ({
      ...prev,
      [element]: { date, dateString },
    }));
  };
  const handleEntryAs = (e) => {
    setEntryDetails((prev) => ({ ...prev, entryAs: e.target.value, job: "" }));
  };
  const handleChange = (date, dateString, element) => {
    onChangeDate(element, date, dateString);
  };
  const addNewEntry = async () => {
    try {
      const formattedDate = moment(
        entryDetails.entryDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      setIsLoading(true);
      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        entries_as: entryDetails?.entryAs,
        work_start_time: entryDetails?.startTime?.value,
        work_end_time: entryDetails?.endTime?.value,
        working_date: await formattedDate,
        work_description: entryDetails?.entryDescription,
      };

      if (
        showBillableForm ||
        showPostDraftChangesForm ||
        showAdvanceBillingForm
      ) {
        body.task_id = +entryDetails?.job?.value;
      }

      if (showPostDraftChangesForm) {
        body.post_draft = "yes";
      }
      // if (entryDetails?.entryAs === "Team") {
      //   body.team_id = initialState?.myTeamID;
      // } else {
      //   body.team_id = initialState?.myTeamID;
      // }
      if (entryDetails?.entryAs === "Team") {
        body.team_id = teamId;
      } else {
        body.team_id = teamId;
      }
      const url = showAdvanceBillingForm
        ? `${mainURL}add/advanced-billing/time-entries`
        : showBillableForm || showPostDraftChangesForm
        ? `${mainURL}add/billable_hours/time-entries`
        : `${mainURL}add/side_works/time-entries`;

      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result.data.message, "success");
        window.location.reload(true);
        handleClear();
        setIsUpdated((prev) => !prev);
        props.onHide();
        props.setNewUpdate(true);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      setIsUpdated((prev) => !prev);
    }
  };
  const handleClickForm = (formType) => {
    setShowsideWorkForm(formType === "sidework");
    setShowBillableForm(formType === "billable");
    setShowPostDraftChangesForm(formType === "postDraftChanges");
    setShowAdvanceBillingForm(formType === "advanceBilling");
    handleClear();
  };
  const handleAddEntry = (e) => {
    e.preventDefault();

    const { entryDescription, job, entryAs, startTime, endTime } = entryDetails;

    // Ensure all required fields are filled
    const isDescriptionValid = Boolean(entryDescription);
    const isStartTimeValid = Boolean(startTime?.value);
    const isEndTimeValid = Boolean(endTime?.value);
    const isJobValid = showBillableForm ? Boolean(job?.value) : true;
    const isJobValid1 = showPostDraftChangesForm ? Boolean(job?.value) : true;
    const isJobValid2 = showAdvanceBillingForm ? Boolean(job?.value) : true;
    const isEntryAsValid = showAdvanceBillingForm ? true : Boolean(entryAs);

    // Check if all validations pass
    if (
      isDescriptionValid &&
      isStartTimeValid &&
      isEndTimeValid &&
      isJobValid &&
      isEntryAsValid &&
      isJobValid1 &&
      isJobValid2
    ) {
      addNewEntry();
    } else {
      const conditions = {
        "Please input time entry description!": !isDescriptionValid,
        "Please select Entry Start Time!": !isStartTimeValid,
        "Please select Entry End Time!": !isEndTimeValid,
        "Please select a job!": showBillableForm && !isJobValid,
        "Please select a job!! ": showPostDraftChangesForm && !isJobValid1,
        "Please select a job !": showAdvanceBillingForm && !isJobValid2,
        "Please select entry as either team or individual!": !isEntryAsValid,
      };

      // Find the first error message that is true
      const errorMessage = Object.keys(conditions).find(
        (key) => conditions[key]
      );

      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };
  async function loadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            }) Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  async function MemberloadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}my-jobs/${userId || userDetails?.member_id}`,
        {
          params: {
            job_status: "In Progress",
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Transform the data into the required format
      const transformedData = response.data.job_data.my_single_jobs.map(
        (item) => {
          try {
            // Parse the additional client data
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};

            // Create the label for the job
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.job_data?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function postDraftChangesMemberloadOptions(
    search,
    loadedOptions,
    { page }
  ) {
    try {
      const url = `${mainURL}generated_invoices/${
        userId || userDetails?.member_id
      }`;
      const response = await axios.get(url, {
        params: {
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData =
        response?.data?.post_draft_jobs?.my_single_jobs.map((item) => {
          try {
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            }  ${item.assigned_on || "N/A"}`;
            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.post_draft_jobs?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }
  async function postDraftChangesJobloadOptions(
    search,
    loadedOptions,
    { page }
  ) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userId || userDetails?.member_id}/${teamId}`,
        {
          params: {
            page,
            limit: 20,
            task_type: "post_draft",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = (
        Array.isArray(response.data.filterData.data)
          ? response.data.filterData.data
          : []
      )
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"}  -  ${
              item.task_name || "N/A"
            }  -  ${item.assigned_on || "N/A"}`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);
      // BP335 - hiKari Moving Media Ltd - 31-05-2024
      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  //************For Selected Option   End  ******************/

  useEffect(() => {
    if (!props.show) {
      setEntryDetails({
        startTime: "",
        endTime: "",
        entryDescription: "",
        job: "",
        entryAs: "",
        teamId: "",
        jobOptions: [],
        advancedBillingJobOptions: [],
        postDraftJobListOptions: [],
        entryDate: {
          date: dayjs(),
          dateString: dayjs().format("DD-MM-YYYY"),
        },
      });
      setShowBillableForm(false);
      setShowsideWorkForm(false);
      setShowPostDraftChangesForm(false);
      setShowAdvanceBillingForm(false);
      setValue("");
    }
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Add Job Entry</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="form-group w-100 mt-1">
          <label htmlFor="">Entry Type:</label>
          <div
            name="assignee-radio-group"
            className="radio-group mt-2 d-flex justify-content-start"
          >
            <label htmlFor="billable">
              <input
                type="radio"
                id="billable"
                value="billable"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("billable")}
              />
              <span>Billable Hours</span>
            </label>
            <label htmlFor="sideWork">
              <input
                type="radio"
                id="sideWork"
                value="sideWork"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("sidework")}
              />
              <span>Side Work</span>
            </label>
            <label htmlFor="postDraftChanges">
              <input
                type="radio"
                id="postDraftChanges"
                value="postDraftChanges"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("postDraftChanges")}
              />
              <span>Post Draft Changes</span>
            </label>
            <label htmlFor="advanceBilling ">
              <input
                type="radio"
                id="advanceBilling"
                value="advanceBilling"
                name="entryType"
                className="radio-btn"
                onClick={() => handleClickForm("advanceBilling")}
              />
              <span>Advance Billing </span>
            </label>
          </div>
        </div>

        {showBillableForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>
                {showBillableForm && !entryDetails?.entryAs && (
                  <div className="form-group  w-100">
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      placeholder={`Select Individual or Team `}
                    />
                  </div>
                )}
                {entryDetails?.entryAs === "Member" && (
                  <AsyncPaginate
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={value}
                    loadOptions={MemberloadOptions}
                    onChange={(selectedOption) => {
                      setValue(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                    }}
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    debounceTimeout={300} // Prevent excessive API calls
                    // Enhanced User Experience Features
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                      console.error("Async Paginate Error:", error);
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
                {entryDetails?.entryAs === "Team" && (
                  <AsyncPaginate
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForIndividual}
                    loadOptions={loadOptions}
                    onChange={(selectedOption) => {
                      setValueForIndividual(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                    }}
                    debounceTimeout={300} // Prevent excessive API calls
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    // Enhanced User Experience Features
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>
                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <div className="form-group mt-4 w-100">
                  <label htmlFor="startTime">Start Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        startTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />

                  <div className="form-group mt-4 w-100">
                    <label htmlFor="endTime">End Time (hh:mm):</label>
                    <TimePickerSection
                      onChange={(time) => {
                        setEntryDetails((prevState) => ({
                          ...prevState,
                          endTime: {
                            value: `${time}`,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group mt-4 w-100">
                  <label htmlFor="jobDescription">Job Description:</label>
                  <textarea
                    id="jobDescription"
                    name="jobDescription"
                    className="w-100"
                    rows={3}
                    placeholder="Enter job description"
                    value={entryDetails?.entryDescription}
                    onChange={(e) =>
                      setEntryDetails((prev) => ({
                        ...prev,
                        entryDescription: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {showsideWorkForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>
                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>

              <div className="d-flex align-items-center flex-column w-100">
                {/*Start Time Picker for Enter time mannually  */}
                <div className="form-group mt-4 w-100">
                  <label htmlFor="startTime">Start Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        startTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>

                <div className="form-group mt-4 w-100">
                  <label htmlFor="endTime">End Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        endTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {/*Post Draft Changes  */}
        {showPostDraftChangesForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              {/*Radio Button section for select Entry ( Individual or Team ) */}
              <div className="form-group mt-4 w-100">
                <label htmlFor="assignee-radio-group">Assigned task as:</label>
                <div
                  name="assignee-radio-group"
                  className="radio-group mt-2 d-flex justify-content-start"
                >
                  <label htmlFor="Individual">
                    <input
                      type="radio"
                      id="Individual"
                      value="Member"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Member"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Individual</span>
                  </label>
                  <label htmlFor="Team">
                    <input
                      type="radio"
                      id="Team"
                      value="Team"
                      name="assignee"
                      className="radio-btn"
                      checked={entryDetails?.entryAs === "Team"}
                      onChange={(e) => handleEntryAs(e)}
                    />
                    <span>Team</span>
                  </label>
                </div>
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>
                {showPostDraftChangesForm && !entryDetails?.entryAs && (
                  <div className="form-group  w-100">
                    <AsyncPaginate
                      className="react-select-custom-styling__container"
                      classNamePrefix="react-select-custom-styling"
                      placeholder={`Select Individual or Team `}
                    />
                  </div>
                )}
                {entryDetails?.entryAs === "Member" && (
                  <AsyncPaginate
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForPostDraftIndividual}
                    loadOptions={postDraftChangesMemberloadOptions}
                    onChange={(selectedOption) => {
                      // setValue(selectedOption);
                      setValueForPostDraftIndividual(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                    }}
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    debounceTimeout={300}
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                      console.error("Async Paginate Error:", error);
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
                {entryDetails?.entryAs === "Team" && (
                  <AsyncPaginate
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                    value={valueForPostDraftJob}
                    loadOptions={postDraftChangesJobloadOptions}
                    onChange={(selectedOption) => {
                      setValueForPostDraftJob(selectedOption);
                      setEntryDetails((prev) => ({
                        ...prev,
                        job: selectedOption,
                      }));
                    }}
                    additional={{
                      page: 1,
                    }}
                    debounceTimeout={300} // Prevent excessive API calls
                    placeholder={`Select ${
                      entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                    } Job`}
                    // Enhanced User Experience Features
                    noOptionsMessage={({ inputValue }) =>
                      inputValue
                        ? `No jobs found for "${inputValue}"`
                        : "No jobs found"
                    }
                    onError={(error) => {
                      ReactHotToast("Error loading jobs", "error");
                    }}
                    // Custom Styles for Better Readability
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? "#007bff" : "white",
                        cursor: "pointer",
                        color: state.isSelected ? "white" : "black",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "#f1f3f5",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                )}
              </div>
              {/* <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>
                <Select
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  isClearable={true}
                  isSearchable={true}
                  name="jobName"
                  onChange={(value) => {
                    setEntryDetails((prevState) => ({
                      ...prevState,
                      job: value,
                    }));
                  }}
                  value={entryDetails?.job}
                  // options={entryDetails?.jobOptions}
                  options={entryDetails?.postDraftJobListOptions}
                />
              </div> */}

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>

                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>

              <div className="d-flex align-items-center flex-column w-100">
                <div className="form-group mt-4 w-100">
                  <label htmlFor="startTime">Start Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        startTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>

                <div className="form-group mt-4 w-100">
                  <label htmlFor="endTime">End Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        endTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
        {showAdvanceBillingForm && (
          <form className="d-flex flex-column" onSubmit={handleAddEntry}>
            <div className="d-flex flex-column justify-content-center align-items-start w-100">
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobName">Job Name:</label>

                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  value={valueForAdvanceBilling}
                  loadOptions={advanceBillingloadOptions}
                  onChange={(selectedOption) => {
                    setValueForAdvanceBilling(selectedOption);
                    setEntryDetails((prev) => ({
                      ...prev,
                      job: selectedOption,
                    }));
                  }}
                  additional={{
                    page: 1,
                  }}
                  placeholder={"Select job...."}
                  debounceTimeout={300} // Prevent excessive API calls
                  // Enhanced User Experience Features
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? `No jobs found for "${inputValue}"`
                      : "No jobs found"
                  }
                  onError={(error) => {
                    ReactHotToast("Error loading jobs", "error");
                    console.error("Async Paginate Error:", error);
                  }}
                  // Custom Styles for Better Readability
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#007bff" : "white",
                      cursor: "pointer",
                      color: state.isSelected ? "white" : "black",
                      ":hover": {
                        backgroundColor: state.isSelected
                          ? "#007bff"
                          : "#f1f3f5",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                />
              </div>
              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDate">Job Date:</label>

                <DatePicker
                  defaultValue={dayjs()}
                  className="form-control datepicker"
                  popupClassName="pop-up-box"
                  onChange={(date, dateString) =>
                    handleChange(date, dateString, "entryDate")
                  }
                  format="DD-MM-YYYY"
                  value={entryDetails.entryDate.date}
                  name="entryDate"
                  placeholder="Select / Enter date "
                  disabledDate={(current) => new Date() < new Date(current)}
                />
              </div>

              <div className="d-flex align-items-center flex-column w-100">
                <div className="form-group mt-4 w-100">
                  <label htmlFor="startTime">Start Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        startTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>

                <div className="form-group mt-4 w-100">
                  <label htmlFor="endTime">End Time (hh:mm):</label>
                  <TimePickerSection
                    onChange={(time) => {
                      setEntryDetails((prevState) => ({
                        ...prevState,
                        endTime: {
                          value: `${time}`,
                        },
                      }));
                    }}
                  />
                </div>
              </div>

              <div className="form-group mt-4 w-100">
                <label htmlFor="jobDescription">Job Description:</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  className="w-100"
                  rows={3}
                  placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
                  value={entryDetails?.entryDescription}
                  onChange={(e) =>
                    setEntryDetails((prev) => ({
                      ...prev,
                      entryDescription: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-4 custom-btn m-auto">
              {isLoading ? <SpinningLoader /> : "Add Job Entry"}
            </button>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const AdddJobEntryModal = ({
  entryDetails,
  setIsUpdated,
  setNewUpdate,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        style={{ cursor: "pointer", height: "30px" }}
        onClick={() => {
          setModalShow(true);
        }}
      >
        <button className="custom-btn ">Add Job Entry</button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
        entryDetails={entryDetails}
        setNewUpdate={setNewUpdate}
      />
    </>
  );
};
