import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import {
  EditSVG,
  projectsIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import {
  headerOptions,
  handleAPIError,
} from "../../../utils/utilities/utilityFunctions";

const statusOptions = [
  {
    label: "In Progress",
    value: 1,
  },
  {
    label: "Completed",
    value: 2,
  },
  {
    label: "On Hold",
    value: 3,
  },
];

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, logout, userDetails } = useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState("");

  useEffect(() => {
    const status = statusOptions.find(({ label }) => label === props?.status);
    setJobStatus(() => status);
  }, [props?.job_status]);

  const updateAdvanceJob = async () => {
    const body = {
      current_user: localStorage.getItem("userId") || userDetails?.member_id,
      assign_job_id: props.id,
      team_id: props.teamId,
      status: jobStatus?.value,
    };

    const url = `${mainURL}update/assign-advance-billing-job-status`;

    setIsLoading(() => true);

    try {
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      props.onHide();
    }
  };

  const handleUpdateJob = (e) => {
    e.preventDefault();

    if (jobStatus?.value) {
      updateAdvanceJob();
    } else {
      if (!jobStatus?.value) {
        ReactHotToast("Please select status", "error");
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Update Advance Billing Job</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdateJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignJobName">Job Name:</label>
            <input
              id="assignJobName"
              name="assignJobName"
              placeholder="Eg: Audit Report"
              type="text"
              value={props.jobName}
              disabled
              readOnly
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignJobStatus">Status:</label>
            <Select
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable={false}
              isSearchable={true}
              name="assignJobStatus"
              value={jobStatus}
              onChange={(option) => setJobStatus(option)}
              options={statusOptions}
            />
          </div>
          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const UpdateAdvanceBillingJobModal = ({
  id,
  status,
  teamId,
  setIsUpdated,
  jobName,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <EditSVG />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        status={status}
        id={id}
        jobName={jobName}
        teamId={teamId}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};

// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import {
//   EditSVG,
//   projectsIcon,
// } from "../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../utils/utilities/utilityFunctions";

// const animatedComponents = makeAnimated();
// const statusOptions = [
//   { label: "In Progress", value: 1 },
//   { label: "Completed", value: 2 },
//   { label: "On Hold", value: 3 },
// ];
// const taxOptions = [
//   { label: "VAT 5%", value: "5" },
//   { label: "VAT 10%", value: "10" },
//   { label: "VAT 15%", value: "15" },
//   { label: "VAT 20%", value: "20" },
// ];

// const MyVerticallyCenteredModal = (props) => {
//   const { mainURL, logout, initialState } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);
//   const [jobStatus, setJobStatus] = useState(null);
//   const [advanceJobDetails, setAdvanceJobDetails] = useState({
//     jobName: props.jobName,
//     jobDescription: props.jobDescription,
//     allocatedHours: props.allocatedHours,
//     amount: props.amount,
//     jobTypeId: null,
//     clientId: null,
//     teamId: null,
//     tax: [],
//   });

//   const [options, setOptions] = useState({
//     jobCategoryOptions: [],
//     clientOptions: [],
//     teamOptions: [],
//   });

//   useEffect(() => {
//     // Fetch options for job categories, clients, and teams
//     setOptions({
//       jobCategoryOptions:
//         initialState?.jobCategories?.map((category) => ({
//           label: category?.job_category_name,
//           value: category?.job_category_id,
//         })) || [],
//       clientOptions:
//         initialState?.clientsList?.map((client) => {
//           const { client_name, additional_data, client_id } = client;
//           const companyName = additional_data?.company_name || "";
//           const bpoNo = additional_data?.bpo_no?.toString() || "";
//           return {
//             label: `${client_name} (${companyName}${
//               bpoNo ? ` - ${bpoNo}` : ""
//             })`,
//             value: client_id,
//             billingRates: additional_data?.billing_rates || [],
//           };
//         }) || [],
//       teamOptions:
//         initialState?.teamsList?.map(({ id, team_name }) => ({
//           label: team_name,
//           value: id,
//         })) || [],
//     });
//   }, [initialState]);

//   useEffect(() => {
//     const status = statusOptions.find(({ label }) => label === props?.status);
//     setJobStatus(status);
//   }, [props?.status]);

//   const handleDropDown = (field, option) => {
//     setAdvanceJobDetails((prev) => ({
//       ...prev,
//       [field]: option ? option.value : null,
//     }));
//   };

//   const updateAdvanceJob = async () => {
//     const body = {
//       current_user: localStorage.getItem("userId"),
//       assign_job_id: props.id,
//       team_id: advanceJobDetails.teamId,
//       status: jobStatus?.value,
//       tax: advanceJobDetails.tax.map((tax) => tax.value), // Ensure tax is sent as an array of values
//       job_name: advanceJobDetails.jobName,
//       job_type: advanceJobDetails.jobTypeId,
//       client_id: advanceJobDetails.clientId,
//       allocated_hours: advanceJobDetails.allocatedHours,
//       job_description: advanceJobDetails.jobDescription,
//       amount: advanceJobDetails.amount,
//     };

//     setIsLoading(true);
//     try {
//       const result = await axios.put(
//         `${mainURL}update/assign-advance-billing-job-status`,
//         body,
//         {
//           headers: headerOptions(),
//         }
//       );
//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");
//         props.setIsUpdated((prev) => !prev);
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(false);
//       props.onHide();
//     }
//   };

//   const handleUpdateJob = (e) => {
//     e.preventDefault();
//     if (jobStatus?.value) {
//       updateAdvanceJob();
//     } else {
//       ReactHotToast("Please select status", "error");
//     }
//   };

//   return (
//     <Modal show={props.show} onHide={props.onHide} size="md" centered>
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Update Advance Billing Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleUpdateJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           {/* Job Category */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobTypeId">Select Job Category:</label>
//             <Select
//               name="jobTypeId"
//               options={options.jobCategoryOptions}
//               onChange={(option) => handleDropDown("jobTypeId", option)}
//               value={
//                 options.jobCategoryOptions.find(
//                   (opt) => opt.value === advanceJobDetails.jobTypeId
//                 ) || null
//               }
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>

//           {/* Client Selection */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="clientId">Select Client:</label>
//             <Select
//               name="clientId"
//               options={options.clientOptions}
//               onChange={(option) => handleDropDown("clientId", option)}
//               value={
//                 options.clientOptions.find(
//                   (opt) => opt.value === advanceJobDetails.clientId
//                 ) || null
//               }
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>

//           {/* Team Selection */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="teamId">Select Team:</label>
//             <Select
//               name="teamId"
//               options={options.teamOptions}
//               onChange={(option) => handleDropDown("teamId", option)}
//               value={
//                 options.teamOptions.find(
//                   (opt) => opt.value === advanceJobDetails.teamId
//                 ) || null
//               }
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           <div className="form-group mt-3 w-100">
//             <label>Tax:</label>
//             <Select
//               isMulti
//               closeMenuOnSelect={false}
//               components={animatedComponents}
//               value={advanceJobDetails.tax}
//               onChange={(option) =>
//                 setAdvanceJobDetails((prev) => ({ ...prev, tax: option }))
//               }
//               options={taxOptions}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>
//           {/* Job Status */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobStatus">Status:</label>
//             <Select
//               isClearable={false}
//               name="assignJobStatus"
//               value={jobStatus}
//               onChange={(option) => setJobStatus(option)}
//               options={statusOptions}
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//             />
//           </div>

//           {/* Job Name */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobName">Job Name:</label>
//             <input
//               id="jobName"
//               type="text"
//               required
//               placeholder="Enter job name"
//               value={advanceJobDetails.jobName}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobName: e.target.value,
//                 }))
//               }
//             />
//           </div>

//           {/* Job Description */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobDescription">Job Description:</label>
//             <textarea
//               id="jobDescription"
//               rows={3}
//               placeholder="Enter job description"
//               value={advanceJobDetails.jobDescription}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   jobDescription: e.target.value,
//                 }))
//               }
//             />
//           </div>

//           {/* Allocated Hours */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="allocatedHours">Allocated Hours:</label>
//             <input
//               id="allocatedHours"
//               type="number"
//               placeholder="Enter allocated hours"
//               value={advanceJobDetails.allocatedHours}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   allocatedHours: e.target.value,
//                 }))
//               }
//             />
//           </div>

//           {/* Amount */}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="amount">Amount:</label>
//             <input
//               id="amount"
//               type="number"
//               placeholder="Enter amount"
//               value={advanceJobDetails.amount}
//               onChange={(e) =>
//                 setAdvanceJobDetails((prev) => ({
//                   ...prev,
//                   amount: e.target.value,
//                 }))
//               }
//             />
//           </div>

//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Update"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const UpdateAdvanceBillingJobModal = ({
//   id,
//   status,
//   teamId,
//   setIsUpdated,
//   jobName,
//   jobDescription,
//   amount,
//   allocatedHours,
//   jobDetails,
// }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <div onClick={() => setModalShow(true)}>
//         <EditSVG />
//       </div>
//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         status={status}
//         id={id}
//         jobDetails={jobDetails}
//         jobName={jobName}
//         amount={amount}
//         jobDescription={jobDescription}
//         allocatedHours={allocatedHours}
//         teamId={teamId}
//         setIsUpdated={setIsUpdated}
//       />
//     </>
//   );
// };
