import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  EditSVG,
  projectsIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import {
  headerOptions,
  handleAPIError,
} from "../../../utils/utilities/utilityFunctions";
const animatedComponents = makeAnimated();
const statusOptions = [
  { label: "Unpaid", value: "1" },
  { label: "Partially Paid", value: "2" },
  { label: "Paid", value: "3" },
];
const taxOptions = [
  { label: "VAT 5%", value: "5" },
  { label: "VAT 10%", value: "10" },
  { label: "VAT 15%", value: "15" },
  { label: "VAT 20%", value: "20" },
  { label: "VAT 25%", value: "25" },
  { label: "VAT 30%", value: "30" },
  { label: "VAT 35%", value: "35" },
  { label: "VAT 40%", value: "40" },
];
const MyVerticallyCenteredModal = (props) => {
  const { mainURL, logout, userDetails } = useContext(ContextAPI);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState("");
  const [partialAmount, setPartialAmount] = useState("");
  const [advanceJobDetails, setAdvanceJobDetails] = useState({
    jobDescription: props.jobDescription,
    tax: null,
  });

  useEffect(() => {
    const status = statusOptions.find(({ label }) => label === props?.status);
    setJobStatus(() => status);
  }, [props?.job_status]);

  const updateAdvanceJob = async () => {
    const vatValue = advanceJobDetails.tax
      ? Number(advanceJobDetails.tax.value)
      : null; // Get the value of the first selected tax option
    const body = {
      current_user: localStorage.getItem("userId") || userDetails?.member_id,
      invoice_id: props.id,
      team_id: props.teamId,
      status_id: jobStatus?.value,
      job_description: advanceJobDetails.jobDescription,
      vat_percent: vatValue,
      partial_paid_amount: jobStatus?.value === "2" ? partialAmount : "",
    };

    const url = `${mainURL}update/invoice-status/advance-billing`;

    setIsLoading(() => true);

    try {
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      props.onHide();
    }
  };

  const handleUpdateJob = (e) => {
    e.preventDefault();

    if (jobStatus?.value) {
      updateAdvanceJob();
    } else {
      if (!jobStatus?.value) {
        ReactHotToast("Please select status", "error");
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Update Advance Billing Job</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdateJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignJobName">Job Name:</label>
            <input
              id="assignJobName"
              name="assignJobName"
              placeholder="Eg: Audit Report"
              type="text"
              value={props.jobName}
              disabled
              readOnly
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobDescription">Job Description:</label>
            <textarea
              id="jobDescription"
              rows={3}
              placeholder="Enter job description"
              value={advanceJobDetails.jobDescription}
              onChange={(e) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  jobDescription: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label>Tax:</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={advanceJobDetails.tax}
              onChange={(option) =>
                setAdvanceJobDetails((prev) => ({
                  ...prev,
                  tax: option || null,
                }))
              }
              options={taxOptions}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignJobStatus">Status:</label>
            <Select
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable={false}
              isSearchable={true}
              name="assignJobStatus"
              value={jobStatus}
              onChange={(option) => setJobStatus(option)}
              options={statusOptions}
            />
          </div>
          {jobStatus?.value === "2" && (
            <div className="form-group mt-3 w-100">
              <label htmlFor="partialPaidAmount">Partial Paid Amount:</label>
              <input
                id="partialPaidAmount"
                type="number"
                value={partialAmount}
                onChange={(e) => setPartialAmount(e.target.value)}
                placeholder="Enter amount"
              />
            </div>
          )}
          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const UpdateAdvanceBillingJobInvoiceModal = ({
  id,
  status,
  teamId,
  setIsUpdated,
  jobName,
  jobDescription,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <EditSVG />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        status={status}
        id={id}
        jobName={jobName}
        jobDescription={jobDescription}
        teamId={teamId}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
