import React, { useState, useContext } from "react";
import axios from "axios";

// import { SendInvoiceModal } from ".././SendInvoiceModal";
import { SendInvoiceModal } from "../../../Invoices/Components/SendInvoiceModal";
import { invoiceIcon1 } from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../.././../../Context/ApiContext/ApiContext";
import {
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";

const PageHeader = ({
  isPreview,
  setIsPreview,
  invoice,
  assignId,
  invoiceMeta,
  isInvoicePreview,
  setInvoice,
  setIsUpdated,
  minimumBalanceEmail,
}) => {
  const { mainURL, logout, userDetails } = useContext(ContextAPI);
  const userId =
    localStorage.getItem("userId") ?? userDetails?.member_id ?? null;

  const [isLoading, setIsLoading] = useState(false);

  // Once Invoice is Edited & Previewed, User can Save the same
  // const handleSaveInvoice = async () => {
  //   try {
  //     setIsLoading(true);

  //     // Check if any other job details are missing
  //     const isAnyOtherJobEmpty = invoice?.newOtherJobs.some(
  //       (job) =>
  //         !job.jobId ||
  //         !job.taskAssignId ||
  //         !job.description ||
  //         !job.billingRate
  //     );
  //     if (isAnyOtherJobEmpty) {
  //       ReactHotToast(
  //         "Please avoid adding empty job details before saving the invoice.",
  //         "error"
  //       );
  //       return;
  //     }

  //     // Determine payment status
  //     const paymentStatus =
  //       invoice?.paymentStatus?.value === "3"
  //         ? "3"
  //         : invoice?.paymentStatus?.value === "2"
  //         ? "2"
  //         : "1";

  //     const calculateTax = invoice?.tax?.reduce((acc, curr) => {
  //       const values = Object.values(curr);
  //       const key = values[0].split(" ")[0];
  //       const value = values[0].split(" ")[1];

  //       // Calculate tax for each VAT percentage
  //       const taxAmount = +((+values[1] * invoice.subTotal) / 100).toFixed(2);

  //       const newObj = {
  //         [key]: value,
  //         tax_ammount: taxAmount,
  //       };

  //       acc = [...acc, newObj];

  //       return acc;
  //     }, []);

  //     // Process other jobs with consistent 2 decimal precision
  //     const otherJobsArray = invoice?.newOtherJobs.map((job) => ({
  //       job_id: job.jobId,
  //       task_assign_id: job.taskAssignId,
  //       job_description: job.description,
  //       total_amount: job.totalAmount
  //         ? parseFloat(job.totalAmount).toFixed(2)
  //         : "0.00",
  //       minimum_balance: job.billingRate
  //         ? parseFloat(job.billingRate).toFixed(2)
  //         : "0.00",
  //     }));

  //     // Prepare the request body with 2 decimal precision
  //     const body = {
  //       minimum_balance: parseFloat(invoice?.servicePrice).toFixed(2),
  //       partial_amount: parseFloat(invoice?.clientHasPaid).toFixed(2),
  //       current_user: +userId,
  //       job_assignment_id: +assignId,
  //       invoice_id: +invoiceMeta?.invoice_id,
  //       total_hours: +invoice?.hoursTime,
  //       total_minutes: +invoice?.minsTime,
  //       sub_total_amount: parseFloat(invoice?.subTotal).toFixed(2),
  //       base_amount: invoice?.totalPrice.toFixed(2),
  //       total_ammount: parseFloat(invoice?.finalTotal).toFixed(2),
  //       other_jobs: otherJobsArray,
  //       tax_array: calculateTax,
  //       payment_status: +paymentStatus,
  //       note: invoice?.note,
  //     };

  //     const url = `${mainURL}save/invoice/minimum-balance`;
  //     const result = await axios.put(url, body, {
  //       headers: headerOptions(),
  //     });

  //     if (result.status === 200) {
  //       ReactHotToast(result.data.message, "success");
  //       setIsPreview(true);
  //       setInvoice((prev) => ({ ...prev, isInvoiceGenerated: true }));
  //       setIsUpdated((prev) => !prev);
  //     }
  //   } catch (e) {
  //     console.log("Error saving invoice:", e);
  //     handleAPIError(e, logout);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSaveInvoice = async () => {
    try {
      setIsLoading(true);

      // Check if any other job details are missing
      const isAnyOtherJobEmpty = invoice?.newOtherJobs.some(
        (job) =>
          !job.jobId ||
          !job.taskAssignId ||
          !job.description ||
          !job.billingRate
      );
      if (isAnyOtherJobEmpty) {
        ReactHotToast(
          "Please avoid adding empty job details before saving the invoice.",
          "error"
        );
        return;
      }

      // Update the payment status and partial payment amount
      const paymentStatus =
        invoice?.paymentStatus?.value === "3"
          ? "3"
          : invoice?.paymentStatus?.value === "2"
          ? "2"
          : "1";

      // If the payment status is "Paid", update partial amount to full
      const partialAmount = paymentStatus === "2" ? invoice.clientHasPaid : 0;

      const calculateTax = invoice?.tax?.reduce((acc, curr) => {
        const values = Object.values(curr);
        const key = values[0].split(" ")[0];
        const value = values[0].split(" ")[1];

        // Calculate tax for each VAT percentage
        const taxAmount = +((+values[1] * invoice.subTotal) / 100).toFixed(2);

        const newObj = {
          [key]: value,
          tax_ammount: taxAmount,
        };

        acc = [...acc, newObj];
        return acc;
      }, []);

      // Process other jobs with consistent 2 decimal precision
      const otherJobsArray = invoice?.newOtherJobs.map((job) => ({
        job_id: job.jobId,
        task_assign_id: job.taskAssignId,
        job_description: job.description,
        total_amount: job.totalAmount
          ? parseFloat(job.totalAmount).toFixed(2)
          : "0.00",
        minimum_balance: job.billingRate
          ? parseFloat(job.billingRate).toFixed(2)
          : "0.00",
      }));

      // Prepare the request body with 2 decimal precision
      const body = {
        minimum_balance: parseFloat(invoice?.servicePrice).toFixed(2),
        partial_amount: parseFloat(partialAmount).toFixed(2),

        // partial_amount: partialAmount ? parseFloat(partialAmount).toFixed(2) : "0.00",
        current_user: +userId,
        job_assignment_id: +assignId,
        invoice_id: +invoiceMeta?.invoice_id,
        total_hours: +invoice?.hoursTime,
        total_minutes: +invoice?.minsTime,
        sub_total_amount: parseFloat(invoice?.subTotal).toFixed(2),
        base_amount: invoice?.totalPrice.toFixed(2),
        total_ammount: parseFloat(invoice?.finalTotal).toFixed(2),
        other_jobs: otherJobsArray,
        tax_array: calculateTax,
        payment_status: +paymentStatus,
        note: invoice?.note,
      };

      const url = `${mainURL}save/invoice/minimum-balance`;
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        setIsPreview(true);
        setInvoice((prev) => ({ ...prev, isInvoiceGenerated: true }));
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      console.log("Error saving invoice:", e);
      handleAPIError(e, logout);
    } finally {
      setIsLoading(false);
    }
  };

  // if the invoice is generated already then hide save and edit and show only Mail button
  // if the invoice is not generated then showcasing Edit and Save and preview button along with Mail button
  if (isPreview) {
    // show mail
    // hide edit, preview and save buttons
  }

  if (!isPreview || !isInvoicePreview) {
    // showcase all edit preview save and mail buttons
  }

  return (
    <section
      className={`main-content_header add-border-bottom custom-border-bottom`}
    >
      <div className="d-flex justify-content-center align-items-center page-heading">
        <img src={invoiceIcon1} alt={"Invoice"} />
        <p className="m-0 fs-4">Invoice</p>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-3">
        {invoice.isInvoiceGenerated
          ? null
          : !isInvoicePreview && (
              <button
                type="submit"
                className="custom-btn"
                onClick={() => {
                  setIsPreview((prev) => !prev);
                }}
              >
                {isPreview ? "Edit" : "Preview"}
              </button>
            )}

        {isPreview ? (
          <>
            {invoice.isInvoiceGenerated
              ? null
              : !isInvoicePreview && (
                  <button
                    type="submit"
                    onClick={handleSaveInvoice}
                    className="custom-btn"
                  >
                    {isLoading ? <SpinningLoader /> : "Save"}
                  </button>
                )}

            {invoice.isInvoiceGenerated ? (
              <SendInvoiceModal
                minimumBalanceEmail={minimumBalanceEmail}
                assignId={assignId}
                invoiceId={invoiceMeta?.invoice_id}
                invoice={invoice}
                data={invoice}
                setIsUpdated={setIsUpdated}
              />
            ) : (
              ""
            )}
          </>
        ) : null}
      </div>
    </section>
  );
};

export default PageHeader;
