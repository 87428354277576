import React, { createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  handleAPIError,
  headerOptions,
} from "../../utils/utilities/utilityFunctions";
import dayjs from "dayjs";
export const ContextAPI = createContext();
// export const nnAPIKey = `fcbf2fee98cee0be1da58b8a2066b7d3826f335def2261d693208e16275dde5b`;
export const nnAPIKey = process.env.REACT_APP_NN_API;

export const ApiContext = ({ children }) => {
  const navigate = useNavigate();

  // states
  const [initialState, setInitialState] = useState({
    jobCategories: [],
    billingServicesList: [],
    clientsList: [],
    adHocClientsList: [],
    membersList: [],
    teamsList: [],
    myTeams: {},
    advanceBillingTeamJobs: [],
    jobs: [],
    allTimeEntries: [],
    allBillableTimeEntries: [],
    myAllTimeEntries: [],
    myteamTimeEntries: [],
    assignJobsList: [],
    myJobs: [],
    postDraftChangesJobs: [],
    holidayList: [],
    allLogs: [],
    myLogs: [],
    getAllInvoice: [],
    allReports: [],
    myAllTimeEntriesBasedOnDate: [],
    isLoading: false,
    loadingStates: {},
    totalPages: 0,
    userRoleCount: [],
    advanceBillingJobList: [],
    advanceBillingReportList: [],
    singleTeam: {},
    singleClient: {},
    moreJobs: {},
    myTeamDetails: {},
    myTeamID: "",
    myTeamHasMore: "",
    moreAdvacedBillingJobs: {},
    unassignedMembersList: {},
    advanceBillingHasMore: "",
    allClientsList: [],
    memberNotAssignedAnyTeamMessage: "",
    singleTeamId: "",
  });
  const [initialStateLoading, setInitialStateLoading] = useState({
    getAllBillingServicesIsLoading: false,
    getAllClientsListIsLoading: false,
    getAllTeamsIsLoading: false,
    getAllJobsIsLoading: false,
    getAllAdvanceBillingJobsPaginationIsLoading: false,
    getAllAssignJobsForPaginationIsLoading: false,
    getTeamJobsIsLoading: false,
    getTeamDetailsByMemberIdIsLoading: false,
    getAllClientsIsLoading: false,
    getAllTimeEntriesForPaginationIsLoading: false,
    getAllMyTeamTimeEntriesIsLoading: false,
    getJobsDetailsByMemberIdIsLoading: false,
    getMyLogsIsLoading: false,
    getAllInvoiceIsLoading: false,
    getAllAdHocClientsIsLoading: false,
    getAllClientsReportDataIsLoading: false,
    getAdvanceBillingReportDataIsLoading: false,
    getAllBillableTimeEntriesIsLoading: false,
    getAllJobCategoriesIsLoading: false,
    getUserDetailsIsLoading: false,
    getSingleClientsIsLoading: false,
    getAllMembersIsLoading: false,
    getTeamIdOnlyIsLoading: false,
  });

  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState(localStorage.getItem("userId") ?? null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const formattedStartDate = dayjs(selectedStartDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");

  const setLoading = (isLoading) => {
    setInitialState((prev) => ({
      ...prev,
      isLoading,
    }));
  };

  const mainURL = "https://www.doshipms.com/api/"; // for live server & vercel both?
  // const mainURL = "https://doshipms-replica.neelnetworks.in/";
  // const mainURL = "https://doshipms.testing.neelnetworks.in/"
  // let  mainURL;
  //   if (process.env.NODE_ENV === 'development') {
  //     mainURL = "https://www.doshipms.com/api/";
  // } else {
  //     mainURL = "https://doshipms-replica.neelnetworks.in/";
  // }

  // logout function

  // const mainURL = "https://doshipms.testing.neelnetworks.in/";

  const logout = () => {
    if (localStorage.getItem("token")) {
      alert("Your token is Expired, Please login again!");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");
      localStorage.removeItem("username");
      navigate("/");
    }
  };

  // --------------------- FOR Single Member ---------------------------------------------------
  // getting logged in user details API
  const getUserDetails = async () => {
    try {
      const url = `${mainURL}single/member/${userId}`;
      const result = await axios.get(url, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        setUserDetails(result?.data?.member_details[0]);
      }
    } catch (e) {
      handleAPIError(e, logout);
    }
  };

  // -----------common structure for GET method api calling-----------------
  const getData = async (url, successCallback) => {
    try {
      setInitialState((prev) => ({ ...prev, isLoading: true }));
      const result = await axios.get(url, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        successCallback(result);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setInitialState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  // --------------------- FOR JOB CATEGORY ---------------------------------------------------
  // getting all job categories API
  // const getAllJobCategories = () => {
  //   setInitialStateLoading((prev) => ({
  //     ...prev,
  //     getAllJobCategoriesIsLoading: true,
  //   }));
  //   const url = `${mainURL}get/all-job-type/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       // jobCategories: result?.data?.job_category_list ?? [],
  //       jobCategories: result?.data?.job_category_list?.data ?? [],
  //       totalPages: result?.data?.job_category_list?.total_pages,
  //     }));
  //     setInitialStateLoading((prev) => ({
  //       ...prev,
  //       getAllJobCategoriesIsLoading: false,
  //     }));
  //   });
  // };

  const getAllJobCategories = (page, limit, searchQuery) => {
    // Check if both page and limit are provided
    if (page && limit) {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllJobCategoriesIsLoading: true,
      }));

      // Construct URL with page and limit
      let url = `${mainURL}get/all-job-type/${userId}?page=${page}&limit=${limit}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }

      // Fetch the data with pagination and optional search query
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          jobCategories: result?.data?.job_category_list?.data ?? [],
          totalPages: result?.data?.job_category_list?.total_pages,
        }));

        // Update loading state
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllJobCategoriesIsLoading: false,
        }));
      });
    } else {
      // If page and limit are not provided, call API without pagination
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllJobCategoriesIsLoading: true,
      }));
      const url = `${mainURL}get/all-job-type/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          // jobCategories: result?.data?.job_category_list ?? [],
          jobCategories: result?.data?.job_category_list?.data ?? [],
          totalPages: result?.data?.job_category_list?.total_pages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllJobCategoriesIsLoading: false,
        }));
      });
    }
  };

  // const getAllJobCategories = async () => {
  //   const key = "getAllJobCategories";
  //   const url = `${mainURL}get/all-job-type/${userId}`;
  //   getData(url, key, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       jobCategories: result?.data?.job_category_list ?? [],
  //     }));
  //   });
  // };

  // const getAllAdvanceBillingJobs = () => {
  //   const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
  //     }));
  //   });
  // };

  const getAllAdvanceBillingJobs = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
          totalPages: result?.data?.assign_list?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingJobList: result?.data?.assign_list?.assign_list ?? [],
        }));
      });
    }
  };
  const getAllAdvanceBillingJobsPagination = (
    page,
    limit,
    searchQuery,
    filters
  ) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllAdvanceBillingJobsPaginationIsLoading: true,
    }));
    let url = `${mainURL}assign-jobs/advance-billing-jobs/${userId}`;

    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);
    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    if (filters?.status?.value)
      queryParams.append("job_status", filters.status.value);

    const finalUrl = `${url}?${queryParams.toString()}`;

    try {
      getData(finalUrl, (result) => {
        if (!result?.data?.assign_list) {
          console.error("Invalid response format");
          return;
        }

        // Extract the jobs list and apply any sorting if necessary
        const advanceBillingJobs = result.data.assign_list.assign_list ?? [];
        const sortedJobs = [...advanceBillingJobs].sort((a, b) => {
          if (a.job_status === "In Progress" && b.job_status !== "In Progress")
            return -1;
          if (a.job_status !== "In Progress" && b.job_status === "In Progress")
            return 1;
          return 0;
        });

        setInitialState((prev) => ({
          ...prev,
          advanceBillingJobList: sortedJobs,
          totalPages: result.data.assign_list?.totalPages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllAdvanceBillingJobsPaginationIsLoading: false,
        }));
      });
    } catch (error) {
      console.error("Error fetching advance billing jobs:", error);
    }
  };

  // --------------------- FOR BILLING SERVICES ---------------------------------------------------
  // getting all billing services API
  // const getAllBillingServices = (page,limit,searchQuery) => {

  //   setInitialStateLoading((prev) => ({
  //     ...prev,
  //     getAllBillingServicesIsLoading: true,
  //   }));
  //   let url = `${mainURL}services/billing/${userId}?page=${page}&limit=${limit}`;
  //   if (searchQuery) {
  //     url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //   }

  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       billingServicesList: result?.data?.services_list?.data ?? [],
  //       totalPages: result?.data?.services_list?.totalPages,
  //       hasMore:result?.data?.services_list?.has_more

  //     }));
  //     setInitialStateLoading((prev) => ({
  //       ...prev,
  //       getAllBillingServicesIsLoading: false,
  //     }));
  //   });
  // };

  // const getAllBillingServices = (page, limit, searchQuery) => {

  //   if (page && limit && searchQuery) {
  //     setInitialStateLoading((prev) => ({
  //       ...prev,
  //       getAllBillingServicesIsLoading: true,
  //     }));

  //     let url = `${mainURL}services/billing/${userId}?page=${page}&limit=${limit}`;
  //     if (searchQuery) {
  //       url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //     }

  //     // Fetch the data
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         billingServicesList: result?.data?.services_list?.data ?? [],
  //         totalPages: result?.data?.services_list?.totalPages,
  //         hasMore: result?.data?.services_list?.has_more,
  //       }));

  //       // Update page number in localStorage after data fetch

  //       setInitialStateLoading((prev) => ({
  //         ...prev,
  //         getAllBillingServicesIsLoading: false,
  //       }));
  //     });
  //   } else {
  //     const url = `${mainURL}services/billing/${userId}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //       billingServicesList: result?.data?.services_list ?? [],
  //       }));
  //     });
  //   }
  // };

  const getAllBillingServices = (page, limit, searchQuery) => {
    // Check if both page and limit are provided
    if (page && limit) {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllBillingServicesIsLoading: true,
      }));

      // Construct URL with page and limit
      let url = `${mainURL}services/billing/${userId}?page=${page}&limit=${limit}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }

      // Fetch the data with pagination and optional search query
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          billingServicesList: result?.data?.services_list?.data ?? [],
          totalPages: result?.data?.services_list?.totalPages,
          hasMore: result?.data?.services_list?.has_more,
        }));

        // Update loading state
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllBillingServicesIsLoading: false,
        }));
      });
    } else {
      // If page and limit are not provided, call API without pagination
      const url = `${mainURL}services/billing/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          billingServicesList: result?.data?.services_list ?? [],
        }));
      });
    }
  };

  // getting all invoice services API
  // const getAllInvoice = () => {
  //   const url = `${mainURL}all/invoice/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       getAllInvoice: result?.data?.invoice_list ?? [],
  //     }));
  //   });
  // };

  const getAllInvoice = (page, limit, searchQuery, filters) => {
    // if (page && limit) {
    // Construct the base URL
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllInvoiceIsLoading: true,
    }));
    let url = `${mainURL}all/invoice/${userId}?page=${page}&limit=${limit}`;

    // Include search query if provided
    if (searchQuery) {
      url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
    }

    // Add filters to the URL if they exist
    if (filters) {
      if (filters.status && filters.status.value) {
        url += `&invoice_status=${encodeURIComponent(filters.status.value)}`;
      }
    }
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        getAllInvoice: result?.data?.invoice_list ?? [],
        totalPages: result?.data?.totalPages,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllInvoiceIsLoading: false,
      }));
    });
    // } else {

    //   const url = `${mainURL}all/invoice/${userId}`;
    //   getData(url, (result) => {
    //     setInitialState((prev) => ({
    //       ...prev,
    //       getAllInvoice: result?.data?.invoice_list ?? [],

    //     }));
    //   });
    // }
  };

  // --------------------- FOR ALL JOBS ---------------------------------------------------
  // getting all jobs API (For Admin and Manager User Roles)
  // const getAllJobs = () => {
  //   const url = `${mainURL}get/all-job/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       jobs: result?.data?.job_list ?? [],
  //     }));
  //   });
  // };
  // getting all jobs API (For Admin and Manager User Roles)

  const getAllClientsList = () => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllClientsListIsLoading: true,
    }));
    const url = `${mainURL}/list/all-client/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allClientsList: result?.data?.client_list?.client_data ?? [],
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllClientsListIsLoading: false,
      }));
    });
  };

  const getAllAssignJobsForPagination = (page, limit, searchQuery, filters) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllAssignJobsForPaginationIsLoading: true,
    }));
    let url = `${mainURL}assign-jobs/${userId}`;

    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);
    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    if (filters.status?.value)
      queryParams.append("job_status", filters.status.value);
    if (filters.assignedTo?.value)
      queryParams.append("assign_to", filters.assignedTo.value);

    const finalUrl = `${url}?${queryParams.toString()}`;
    try {
      getData(finalUrl, (result) => {
        if (!result?.data?.assign_list) {
          console.error("Invalid response format");
          return;
        }
        // Sort jobs - prioritize "In Progress"
        const assignJobs = result.data.assign_list.assign_list ?? [];

        setInitialState((prev) => ({
          ...prev,
          assignJobsList: assignJobs,
          totalPages: result.data.assign_list?.totalPages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllAssignJobsForPaginationIsLoading: false,
        }));
      });
    } catch (error) {
      console.error("Error fetching assign jobs:", error);
    }
  };

  const getAllJobs = (page, limit, searchQuery) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllJobsIsLoading: true,
    }));
    if (page && limit) {
      // Construct URL with pagination and optional search query
      const url = `${mainURL}get/all-job/${userId}?page=${page}&limit=${limit}${
        searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : ""
      }`;

      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          jobs: result?.data?.job_list?.jobs ?? [],
          totalPages: result?.data?.job_list?.totalPages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllJobsIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllJobsIsLoading: true,
      }));
      // Construct URL without pagination
      const url = `${mainURL}get/all-job/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          jobs: result?.data?.job_list ?? [],
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllJobsIsLoading: false,
        }));
      });
    }
  };

  // const getAllJobs = (page, limit) => {
  //   if (page && limit) {
  //     const url = `${mainURL}get/all-job/${userId}?page=${page}&limit=${limit}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         jobs: result?.data?.job_list?.jobs ?? [],
  //         totalPages: result?.data?.job_list?.totalPages,
  //       }));
  //     });
  //   } else {
  //     const url = `${mainURL}get/all-job/${userId}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         jobs: result?.data?.job_list ?? [],
  //       }));
  //     });
  //   }
  // };
  const getMoreAdvancedBillingJobs = (teamId, pageNo) => {
    if (teamId && pageNo) {
      const url = `${mainURL}/get-more/advance-billing-jobs/${userId}/${teamId.toString()}${
        pageNo ? `?page=${pageNo.toString()}` : ""
      }`;

      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          moreAdvacedBillingJobs: result?.data?.job_data ?? {},
        }));
      });
    }
  };
  // GetMoreJobs
  const getMoreJobs = (teamId, pageNo) => {
    if (teamId && pageNo) {
      // const url = `${mainURL}get-more/jobs/${userId}/`;
      const url = `${mainURL}get-more/jobs/${userId}/${teamId.toString()}${
        pageNo ? `?page=${pageNo.toString()}` : ""
      }`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          moreJobs: result?.data?.job_data ?? {},
        }));
      });
    }
  };

  // getting all jobs API (For Member and Team Leader User Roles)
  const getJobsDetailsByMemberId = () => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getJobsDetailsByMemberIdIsLoading: true,
    }));
    const url = `${mainURL}my-jobs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myJobs: result?.data?.job_data?.my_single_jobs ?? [],
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getJobsDetailsByMemberIdIsLoading: false,
      }));
    });
  };

  const getJobsDetailsByMemberIdPagination = (
    page,
    limit,
    searchQuery,
    filters
  ) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getJobsDetailsByMemberIdPaginationIsLoading: true,
    }));
    const url = `${mainURL}my-jobs/${userId}`;
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);
    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    if (filters.status?.value)
      queryParams.append("job_status", filters.status.value);
    // if (filters.assignedTo?.value) queryParams.append("assign_to", filters.assignedTo.value);

    const finalUrl = `${url}?${queryParams.toString()}`;
    getData(finalUrl, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myJobs: result?.data?.job_data,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getJobsDetailsByMemberIdPaginationIsLoading: false,
      }));
    });
  };

  // --------------------- FOR POST DRAFT CHANGES ---------------------------------------------------
  // getting all jobs whose invoice is created API
  const getJobsForPostDraftChanges = () => {
    const url = `${mainURL}generated_invoices/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        postDraftChangesJobs: result?.data?.invoices ?? [],
      }));
    });
  };

  // --------------------- FOR ALL ASSIGN JOBS ---------------------------------------------------
  // getting all assign jobs API (For Admin and Manager User Roles)

  // const getAllAssignJobs = () => {
  //   const url = `${mainURL}assign-jobs/${userId}`;
  //   getData(url, (result) => {
  //     const assignJobs = result?.data?.assign_list ?? [];
  //     const sortedJobs = assignJobs.sort((a, b) => {
  //       if (a.job_status === "In Progress" && b.job_status !== "In Progress")
  //         return -1;
  //       if (a.job_status !== "In Progress" && b.job_status === "In Progress")
  //         return 1;
  //       return 0;
  //     });

  //     setInitialState((prev) => ({
  //       ...prev,
  //       assignJobsList: sortedJobs,
  //     }));
  //   });
  // };
  // const getAllAssignJobs = () => {
  //   const url = `${mainURL}assign-jobs/${userId}`;
  //   getData(url, (result) => {
  //     const assignJobs = result?.data?.assign_list?.assign_list ?? [];
  //     const sortedJobs = assignJobs.sort((a, b) => {
  //       if (a.job_status === "In Progress" && b.job_status !== "In Progress")
  //         return -1;
  //       if (a.job_status !== "In Progress" && b.job_status === "In Progress")
  //         return 1;
  //       return 0;
  //     });

  //     setInitialState((prev) => ({
  //       ...prev,
  //       assignJobsList: sortedJobs,
  //     }));
  //   });
  // };
  const getAllAssignJobs = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}assign-jobs/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        const assignJobs = result?.data?.assign_list?.assign_list ?? [];
        // const assignJobs = result?.data?.assign_list ?? [];
        const sortedJobs = assignJobs.sort((a, b) => {
          if (a.job_status === "In Progress" && b.job_status !== "In Progress")
            return -1;
          if (a.job_status !== "In Progress" && b.job_status === "In Progress")
            return 1;
          return 0;
        });
        setInitialState((prev) => ({
          ...prev,
          assignJobsList: sortedJobs,
          totalPages: result?.data?.assign_list?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}assign-jobs/${userId}`;
      getData(url, (result) => {
        const assignJobs = result?.data?.assign_list?.assign_list ?? [];
        const sortedJobs = assignJobs.sort((a, b) => {
          if (a.job_status === "In Progress" && b.job_status !== "In Progress")
            return -1;
          if (a.job_status !== "In Progress" && b.job_status === "In Progress")
            return 1;
          return 0;
        });

        setInitialState((prev) => ({
          ...prev,
          assignJobsList: sortedJobs,
        }));
      });
    }
  };
  // --------------------- FOR CLIENTS ---------------------------------------------------------
  // fetch all clients API
  // const getAllClients = (pageNo, limit, searchQuery) => {
  //   if ((pageNo && limit) || (pageNo && limit && searchQuery)) {
  //     const url = `${mainURL}list/all-client${userId}?limit=${limit}&page=${pageNo}${
  //       searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : ""
  //     }`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         clientsList: result?.data?.client_list?.client_data ?? [],
  //       }));
  //     });
  //   } else {
  //     const url = `${mainURL}/list/all-client/${userId}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         clientsList: result?.data?.client_list?.client_data ?? [],
  //       }));
  //     });
  //   }
  // };

  const getAllClients = (page, limit, searchQuery) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllClientsIsLoading: true,
    }));
    if ((page && limit) || (page && limit && searchQuery)) {
      let url = `${mainURL}list/all-client/${userId}?limit=${limit}&page=${page}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`; // Append search query
      }

      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          clientsList: result?.data?.client_list?.client_data ?? [],
          totalPages: result?.data?.client_list?.totalPages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllClientsIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllClientsIsLoading: true,
      }));
      const url = `${mainURL}/list/all-client/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          clientsList: result?.data?.client_list?.client_data ?? [],
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllClientsIsLoading: false,
        }));
      });
    }
  };

  const getSingleClients = (clientId) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getSingleClientsIsLoading: true,
    }));
    const url = `${mainURL}/single/client/${userId}/${clientId}`;
    getData(url, (result) => {
      const SingleClientData = result?.data?.client_list?.client_data
        ? result?.data?.client_list?.client_data[0]
        : {};
      setInitialState((prev) => ({
        ...prev,
        singleClient: SingleClientData,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getSingleClientsIsLoading: false,
      }));
    });
  };

  // const getAllAdHocClients = () => {
  //   setInitialStateLoading((prev) => ({
  //     ...prev,
  //     getAllAdHocClientsIsLoading: true,
  //   }));
  //   const url = `${mainURL}get/invoice/ad-hoc-billing/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       adHocClientsList: result?.data?.ad_hoc_billing_lists?.data ?? [],
  //     }));
  //     setInitialStateLoading((prev) => ({
  //       ...prev,
  //       getAllAdHocClientsIsLoading: false,
  //     }));
  //   });
  // };

  const getAllAdHocClients = (page, limit, searchQuery) => {
    if (page && limit) {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllAdHocClientsIsLoading: true,
      }));

      let url = `${mainURL}get/invoice/ad-hoc-billing/${userId}?page=${page}&limit=${limit}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          adHocClientsList: result?.data?.ad_hoc_billing_lists?.data ?? [],
          totalPages: result?.data?.ad_hoc_billing_lists?.total_pages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllAdHocClientsIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllAdHocClientsIsLoading: true,
      }));
      const url = `${mainURL}get/invoice/ad-hoc-billing/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          adHocClientsList: result?.data?.ad_hoc_billing_lists?.data ?? [],
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllAdHocClientsIsLoading: false,
        }));
      });
    }
  };

  // --------------------- FOR MEMBERS ---------------------------------------------------------
  // getting all members API
  // const getAllMembers = () => {
  //   const url = `${mainURL}list/all-member/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       membersList: result?.data?.members_list?.users ?? [],
  //     }));
  //   });
  // };
  // const getAllMembers = (page, limit,searchQuery) => {
  //   if ((page && limit) || (page && limit && searchQuery)) {
  //     let url = `${mainURL}list/all-member/${userId}?page=${page}&limit=${limit}`;
  //     if (searchQuery) {
  //       url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //     }
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         membersList: result?.data?.members_list?.users ?? [],
  //         totalPages: result?.data?.members_list?.totalPages,
  //         userRoleCount: result?.data?.members_list?.user_role_count,
  //       }));
  //     });
  //   } else {
  //     const url = `${mainURL}list/all-member/${userId}`;
  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         membersList: result?.data?.members_list?.users ?? [],
  //       }));
  //     });
  //   }
  // };
  const getAllMembers = (page, limit, searchQuery) => {
    if (page && limit) {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllMembersIsLoading: true,
      }));
      let url = `${mainURL}list/all-member/${userId}?page=${page}&limit=${limit}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          membersList: result?.data?.members_list?.users ?? [],
          totalPages: result?.data?.members_list?.totalPages,
          userRoleCount: result?.data?.members_list?.user_role_count,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllMembersIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllMembersIsLoading: true,
      }));
      const url = `${mainURL}list/all-member/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          membersList: result?.data?.members_list?.users ?? [],
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllMembersIsLoading: false,
        }));
      });
    }
  };
  // --------------------- FOR TEAMS ---------------------------------------------------------
  // getting all teams API (For Admin and Manager User Roles)
  // const getAllTeams = () => {
  //   const url = `${mainURL}get/team/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       teamsList: result?.data?.team_list ?? [],
  //     }));
  //   });
  // };

  const getAllTeams = (page, limit, searchQuery) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllTeamsIsLoading: true,
    }));
    if ((page && limit) || (page && limit && searchQuery)) {
      let url = `${mainURL}get/team/${userId}?limit=${limit}&page=${page}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }

      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          teamsList: result?.data?.team_list?.team_data ?? [],
          totalPages: result?.data?.team_list?.totalPages ?? 1,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllTeamsIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllTeamsIsLoading: true,
      }));
      const url = `${mainURL}get/team/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          teamsList: result?.data?.team_list ?? [],
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllTeamsIsLoading: false,
        }));
      });
    }
  };
  // getting all teams API (For Admin and Manager User Roles)
  const getSingleTeamDetails = (teamId) => {
    const url = `${mainURL}single/team/${userId}/${teamId}`;
    getData(url, (result) => {
      const teamData = result?.data?.team_list
        ? result?.data?.team_list[0]
        : {};
      setInitialState((prev) => ({
        ...prev,
        singleTeam: teamData,
      }));
    });
  };

  // getting all teams API (For Member and Team Leader User Roles)
  // const getTeamDetailsByMemberId = () => {
  //   const url = `${mainURL}my-teams/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       myTeams: result?.data?.team_data ? result?.data?.team_data[0] : {},
  //       advanceBillingList: result?.data?.team_data?.advance_billing_list ?? [],
  //     }));
  //   });
  // };

  // const getTeamDetailByMemberId = (
  //   page,
  //   limit,
  //   teamId,taskType,
  //   searchQuery, filters,

  const getTeamJobs = (page, limit, teamId, taskType, searchQuery, filters) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getTeamJobsIsLoading: true,
    }));
    let url = `${mainURL}get/team-jobs/${userId}/${teamId}?page=${page}&limit=${limit}&task_type=${taskType}`;
    if (searchQuery) {
      url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
    }
    // Add filters to the URL
    if (filters && filters.status && filters.status.value) {
      url += `&job_status=${encodeURIComponent(filters.status.value)}`;
    }
    // setInitialStateLoading((prev) => ({
    //   ...prev,
    //   getTeamJobsIsLoading: true,
    // }));
    getData(url, (result) => {
      const teamData = result?.data?.filterData?.data ?? [];
      setLoading(false);
      setInitialState((prev) => ({
        ...prev,
        myTeamsJobs: teamData,
        totalPages: result?.data?.filterData?.totalPages,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getTeamJobsIsLoading: false,
      }));
    });
  };

  const getTeamDetailsByMemberId = (page, limit) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getTeamDetailsByMemberIdIsLoading: true,
    }));
    // ==============================
    if (page && limit) {
      const url = `${mainURL}my-teams/${userId}?page=${page}&limit=${limit}`;

      getData(url, (result) => {
        const teamData = result?.data?.team_data
          ? result?.data?.team_data[0]
          : {};
        setInitialState((prev) => ({
          ...prev,
          myTeamID: teamData?.id,
          myTeamDetails: teamData,
          myTeams: teamData?.assigned_jobs?.data,
          myTeamHasMore: teamData?.assigned_jobs?.has_more,
          advanceBillingTeamJobs: teamData?.advance_billing_list?.data || [],
          advanceBillingHasMore: teamData?.advance_billing_list?.has_more,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getTeamDetailsByMemberIdIsLoading: false,
        }));
      });
    } else {
      setInitialStateLoading((prev) => ({
        ...prev,
        getTeamDetailsByMemberIdIsLoading: true,
      }));
      const url = `${mainURL}my-teams/${userId}`;
      getData(url, (result) => {
        const teamData = result?.data?.team_data
          ? result?.data?.team_data[0]
          : {};
        const TeamNotifyMessage = result?.data?.message;
        setInitialState((prev) => ({
          ...prev,
          myTeams: teamData?.assigned_jobs?.data,
          myTeamID: teamData?.id,
          myTeamDetails: teamData,
          myTeamHasMore: teamData?.assigned_jobs?.has_more,
          advanceBillingTeamJobs: teamData?.advance_billing_list?.data || [],
          advanceBillingHasMore: teamData.advance_billing_list?.has_more,
          memberNotAssignedAnyTeamMessage: TeamNotifyMessage,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getTeamDetailsByMemberIdIsLoading: false,
        }));
      });
    }
  };
  const getTeamIdOnly = () => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getTeamIdOnlyIsLoading: true,
    }));
    const url = `${mainURL}/my-teams-ids/${userId}`;
    getData(url, (result) => {
      // console.log("ressss", result);
      const teamIdData = result?.data?.team_ids
        ? result?.data?.team_ids[0]
        : {};
      setInitialState((prev) => ({
        ...prev,
        singleTeamId: teamIdData,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getTeamIdOnlyIsLoading: false,
      }));
    });
  };

  const getTeamDetailsByMemberI = () => {
    const url = `${mainURL}my-teams/${userId}`;
    setLoading(true);
    getData(url, (result) => {
      const teamData = result?.data?.team_data
        ? result?.data?.team_data[0]
        : {};

      setInitialState((prev) => ({
        ...prev,
        myTeams: {
          data: teamData?.assigned_jobs?.data || [],
          has_more: teamData?.assigned_jobs?.has_more || false,
          teamId: teamData.id,
          total: teamData?.assigned_jobs?.total || 0,
        },
        advanceBillingTeamJobs: {
          data: teamData?.advance_billing_list?.data || [],
          has_more: teamData?.advance_billing_list?.has_more || false,
          total: teamData?.advance_billing_list?.total || 0,
        },
      }));
    });
  };
  const fetchPaginatedDataMemberMyTeam = (page, limit, teamId, callback) => {
    const url = `${mainURL}get-more/jobs/${userId}/${teamId}?page=${page}&limit=${limit}`;
    setLoading(true);
    getData(url, (result) => {
      if (result?.data?.job_data) {
        const newTeamData = result.data.job_data;

        setInitialState((prev) => ({
          ...prev,
          myTeams: {
            ...prev.myTeams,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };

  //  const fetchForSearchForMemberMyTeam = (page, limit,searchQuery, filters, callback) => {
  //       const url =`${mainURL}search/jobs-data/79/13?page=${page}&limit=${limit}&task_type=billable_hours&searchQuery=${searchQuery}`;
  //       getData(url, (result) => {
  //       if (result?.data?.job_data) {
  //         const newTeamData = result.data.job_data;

  //         setInitialState((prev) => ({
  //           ...prev,
  //           myTeams: {
  //             ...prev.myTeams,
  //             data: newTeamData?.data || [], // Replace with new data
  //             has_more: newTeamData?.has_more,
  //           },
  //         }));

  //         if (callback) callback(result);
  //       }
  //     });
  //   };

  const fetchForSearchForMemberMyTeam = (
    teamId,
    page,
    limit,
    searchQuery,
    filters,
    callback
  ) => {
    let url = `${mainURL}search/jobs-data/${userId}/${teamId}`;
    setLoading(true);
    const queryParams = new URLSearchParams();
    queryParams.append("page", page);
    queryParams.append("limit", limit);
    queryParams.append("task_type", "billable_hours");

    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    // if (filters?.status) {
    //   queryParams.append("job_status", filters.status); // Only add job_status if filters.status is not null
    // }

    if (filters?.status) {
      // If filters.status is an object, use its 'value' property; otherwise use the status directly
      const statusValue = filters.status?.value || filters.status;
      queryParams.append("job_status", statusValue);
    }
    // if (filters?.status?.value)
    //   queryParams.append("job_status", filters.value);
    // if (filters?.entryAs)
    //   queryParams.append("entries_as", filters.entryAs.value);

    // Combine the base URL and query parameters
    const finalUrl = `${url}?${queryParams.toString()}`;

    getData(finalUrl, (result) => {
      if (result?.data?.searchData) {
        const newTeamData = result.data.searchData;

        setInitialState((prev) => ({
          ...prev,
          myTeams: {
            ...prev.myTeams,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };

  const fetchPaginatedDataMemberAdvanceBilling = (
    page,
    limit,
    teamId,
    callback
  ) => {
    const url = `${mainURL}/get-more/advance-billing-jobs/${userId}/${teamId}?page=${page}&limit=${limit}`;
    setLoading(true);
    getData(url, (result) => {
      if (result?.data?.job_data) {
        const newTeamData = result.data.job_data;

        setInitialState((prev) => ({
          ...prev,
          advanceBillingTeamJobs: {
            ...prev.advanceBillingTeamJobs,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };
  // const fetchForSearchForMemberAdvanceBilling = (
  //   page,
  //   limit,
  //   searchQuery,
  //   filters,
  //   callback
  // ) => {
  // const url =`${mainURL}search/jobs-data/79/13?page=${page}&limit=${limit}task_type=advance_billing&searchQuery=$${searchQuery}`;
  //   getData(url, (result) => {
  //     if (result?.data?.job_data) {
  //       const newTeamData = result.data.job_data;

  //       setInitialState((prev) => ({
  //         ...prev,
  //         advanceBillingTeamJobs: {
  //           ...prev.advanceBillingTeamJobs,
  //           data: newTeamData?.data || [], // Replace with new data
  //           has_more: newTeamData?.has_more,
  //         },
  //       }));

  //       if (callback) callback(result);
  //     }
  //   });
  // };
  // --------------------- FOR ALL HOLIDAYS ---------------------------------------------------------
  // getting all holidays

  const fetchForSearchForMemberAdvanceBilling = (
    teamId,
    page,
    limit,
    searchQuery,
    filters,
    callback
  ) => {
    let url = `${mainURL}search/jobs-data/${userId}/${teamId}`;
    setLoading(true);
    // Create URLSearchParams for query parameters
    const queryParams = new URLSearchParams();
    queryParams.append("page", page);
    queryParams.append("limit", limit);
    queryParams.append("task_type", "advance_billing");

    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    if (filters?.status?.value)
      queryParams.append("status", filters.status.value);
    if (filters?.entryAs)
      queryParams.append("entries_as", filters.entryAs.value);

    // Combine the base URL and query parameters
    const finalUrl = `${url}?${queryParams.toString()}`;

    getData(finalUrl, (result) => {
      if (result?.data?.searchData) {
        const newTeamData = result.data.searchData;
        setInitialState((prev) => ({
          ...prev,
          advanceBillingTeamJobs: {
            ...prev.advanceBillingTeamJobs,
            data: newTeamData?.data || [], // Replace with new data
            has_more: newTeamData?.has_more,
          },
        }));

        if (callback) callback(result);
      }
    });
  };

  const getAllHolidays = () => {
    const url = `${mainURL}all/holiday`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        holidayList: result?.data?.holiday_list ?? [],
      }));
    });
  };

  // --------------------- FOR ALL TIME ENTRIES ---------------------------------------------------------
  // getting all time entries API for (Admin and Manager)
  // const getAllTimeEntries = () => {
  //   const url = `${mainURL}get/all-time-entries/${userId}`;
  //   getData(url, (result) => {
  //     const entries = result?.data?.time_entries_data?.time_entries ?? [];

  //     setInitialState((prev) => ({
  //       ...prev,
  //       allTimeEntries: entries,
  //     }));
  //   });
  // };
  const getAllTimeEntries = (page, limit) => {
    if (page && limit) {
      const url = `${mainURL}get/all-time-entries/${userId}?page=${page}&limit=${limit}`;
      getData(url, (result) => {
        const entries = result?.data?.time_entries_data?.time_entries ?? [];
        setInitialState((prev) => ({
          ...prev,
          allTimeEntries: entries,
          totalPages: result?.data?.time_entries_data?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}get/all-time-entries/${userId}`;
      getData(url, (result) => {
        const entries = result?.data?.time_entries_data?.time_entries ?? [];

        setInitialState((prev) => ({
          ...prev,
          allTimeEntries: entries,
        }));
      });
    }
  };

  const getAllTimeEntriesForPagination = (
    page,
    limit,
    searchQuery,
    filters
  ) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllTimeEntriesForPaginationIsLoading: true,
    }));
    let url = `${mainURL}get/all-time-entries/${userId}`;
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);
    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    if (filters?.status?.value)
      queryParams.append("time_entries_status", filters.status.value);
    if (filters?.entryAs)
      queryParams.append("entries_as", filters.entryAs.value);
    if (filters?.showDiscountedHoursEntries)
      queryParams.append("adjusted_hours", filters.showDiscountedHoursEntries);
    const finalUrl = `${url}?${queryParams.toString()}`;

    try {
      getData(finalUrl, (result) => {
        if (!result?.data?.time_entries_data) {
          console.error("Invalid response format");
          return;
        }

        const entries = result.data.time_entries_data.time_entries ?? [];

        setInitialState((prev) => ({
          ...prev,
          allTimeEntries: entries,
          totalPages: result.data.time_entries_data?.totalPages,
        }));
        setInitialStateLoading((prev) => ({
          ...prev,
          getAllTimeEntriesForPaginationIsLoading: false,
        }));
      });
    } catch (error) {
      console.error("Error fetching time entries:", error);
    }
  };

  //   const getAllBillableTimeEntries = (page, limit,searchQuery) => {
  //     if ((page && limit) || (page && limit && searchQuery)) {
  //      let url = `${mainURL}get/all-billable-hours-time-entries/${userId}?page=${page}&limit=${limit}`;
  //      if (searchQuery) {
  //        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //      }
  //      getData(url, (result) => {
  //        setInitialState((prev) => ({
  //          ...prev,
  //          allBillableTimeEntries:
  //            result?.data?.time_entries_data?.time_entries ?? [],
  //          totalPages: result?.data?.time_entries_data?.totalPages,
  //        }));
  //      });
  //    } else {
  //      const url = `${mainURL}get/all-billable-hours-time-entries/${userId}`;
  //      getData(url, (result) => {
  //        setInitialState((prev) => ({
  //          ...prev,
  //          allBillableTimeEntries:
  //            result?.data?.time_entries_data?.time_entries ?? [],
  //        }));
  //      });
  //    }
  //  };
  const getAllBillableTimeEntries = (page, limit, searchQuery, filters) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllBillableTimeEntriesIsLoading: true,
    }));
    let url = `${mainURL}get/all-billable-hours-time-entries/${userId}?page=${page}&limit=${limit}`;

    // Include search query if provided
    if (searchQuery) {
      url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
    }

    // Add filters to the URL
    if (filters) {
      if (filters.status && filters.status.value) {
        url += `&time_entries_status=${encodeURIComponent(
          filters.status.value
        )}`;
      }

      if (filters.entryAs && filters.entryAs.value) {
        url += `&entries_as=${encodeURIComponent(filters.entryAs.value)}`;
      }
      if (filters.showDiscountedHoursEntries === true) {
        url += `&adjusted_hours=true`;
      }
      if (filters.reviewer && filters.reviewer.value) {
        url += `&reviewer=${encodeURIComponent(filters.reviewer.value)}`;
      }
    }

    // Fetch data with the constructed URL
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allBillableTimeEntries:
          result?.data?.time_entries_data?.time_entries ?? [],
        totalPages: result?.data?.time_entries_data?.totalPages,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllBillableTimeEntriesIsLoading: false,
      }));
    });
  };

  // const getAllBillableTimeEntries = (page, limit, searchQuery, filters) => {
  //   let url = `${mainURL}get/all-billable-hours-time-entries/${userId}?page=${page}&limit=${limit}`;

  //   // Include search query if provided
  //   if (searchQuery) {
  //     url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
  //   }

  //   // Add filters to the URL
  //   if (filters) {
  //     if (filters.status && filters.status.value) {
  //       url += `&time_entries_status=${encodeURIComponent(
  //         filters.status.value
  //       )}`;
  //     }

  //     if (filters.entryAs && filters.entryAs.value) {
  //       url += `&entries_as=${encodeURIComponent(filters.entryAs.value)}`;
  //     }

  //     if (filters.showDiscountedHoursEntries !== undefined) {
  //       url += `&adjusted_hours=${
  //         filters.showDiscountedHoursEntries ? "true" : "false"
  //       }`;
  //     }

  //     if (filters.reviewer && filters.reviewer.value) {
  //       url += `&reviewer=${encodeURIComponent(filters.reviewer.value)}`;
  //     }
  //   }

  //   // Fetch data with the constructed URL
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       allBillableTimeEntries:
  //         result?.data?.time_entries_data?.time_entries ?? [],
  //       totalPages: result?.data?.time_entries_data?.totalPages,
  //     }));
  //   });
  // };

  // getting all time entries API for (Member and Team Leaders)
  const getAllMyTimeEntries = () => {
    const url = `${mainURL}get/my-time-entries/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myAllTimeEntries: result?.data?.time_entries_data ?? [],
      }));
    });
  };

  // getting all time entries API for (Member and Team Leaders)
  const getAllMyTimeEntriesBasedOnDate = (date) => {
    const url = `${mainURL}get/my-time-entries/${userId}/${date}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myAllTimeEntriesBasedOnDate: result?.data?.time_entries_data ?? [],
      }));
    });
  };

  // getting all time entries API for (Team Leaders)
  // const getAllMyTeamTimeEntries = (teamId, page, limit) => {
  //   if (teamId) {
  //     const url = `${mainURL}get/team-time-entries/${userId}/${teamId}?page=${page}&limit=${limit}`;
  //     getData(url, (result) => {
  //       console.log("resultresultresultresult", result);
  //       setInitialState((prev) => ({
  //         ...prev,
  //         myteamTimeEntries:
  //           result?.data?.time_entries_data?.time_entries ?? [],
  //         totalPages: result?.data?.time_entries_data?.totalPages,
  //       }));
  //     });
  //   }
  // };
  const getAllMyTeamTimeEntries = (
    teamId,
    page,
    limit,
    searchQuery,
    filters
  ) => {
    if (teamId) {
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllMyTeamTimeEntriesIsLoading: true,
      }));
      let url = `${mainURL}get/team-time-entries/${userId}/${teamId}`;

      const queryParams = new URLSearchParams();
      if (page) queryParams.append("page", page);
      if (limit) queryParams.append("limit", limit);
      if (searchQuery) queryParams.append("searchQuery", searchQuery);
      if (filters?.status?.value)
        queryParams.append("time_entries_status", filters.status.value);
      if (filters?.entryAs)
        queryParams.append("entries_as", filters.entryAs.value);
      if (filters?.showDiscountedHoursEntries)
        queryParams.append(
          "minimum_billing",
          filters.showDiscountedHoursEntries
        );
      const finalUrl = `${url}?${queryParams.toString()}`;

      try {
        getData(finalUrl, (result) => {
          if (!result?.data?.time_entries_data) {
            console.error("Invalid response format");
            return;
          }

          const entries = result.data.time_entries_data.time_entries ?? [];

          setInitialState((prev) => ({
            ...prev,
            myteamTimeEntries: entries,
            totalPages: result.data.time_entries_data?.totalPages,
          }));
          setInitialStateLoading((prev) => ({
            ...prev,
            getAllMyTeamTimeEntriesIsLoading: false,
          }));
        });
      } catch (error) {
        console.error("Error fetching time entries:", error);
      }
    }
  };

  const getAllMemberWiseReportData = (
    formattedStartDate,
    formattedEndDate,
    page,
    limit
  ) => {
    const url = `${mainURL}/reports/member-wise/${userId}/${formattedStartDate}/${formattedEndDate}?page=${page}&limit=${limit}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allMemberReports: result?.data?.member_wise_reports.reports_data ?? [],
        totalPages: result?.data?.time_entries_data?.totalPages,
      }));
    });
  };

  // --------------------- FOR USER LOGS ---------------------------------------------------------
  // getting all user logs API for (Admin and Manager)
  const getAllLogs = () => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllLogsIsLoading: true,
    }));
    const url = `${mainURL}login-logs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allLogs: result?.data?.logs_list ?? [],
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllLogsIsLoading: false,
      }));
    });
  };

  // getting my logs API for (Member and Team Leaders)
  const getMyLogs = () => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getMyLogsIsLoading: true,
    }));
    const url = `${mainURL}my-login-logs/${userId}`;
    getData(url, (result) => {
      setInitialState((prev) => ({
        ...prev,
        myLogs: result?.data?.logs_list ?? [],
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getMyLogsIsLoading: false,
      }));
    });
  };

  // ------------------ FOR REPORT SECTION --------------------------------
  // getting all clients Report Data (Admin and Manager)
  // const getAllClientsReportData = () => {
  //   setInitialStateLoading((prev) => ({
  //     ...prev,
  //     getAllClientsReportDataIsLoading: true,
  //   }));
  //   const url = `${mainURL}reports/client-wise/${userId}`;
  //   getData(url, (result) => {
  //     setInitialState((prev) => ({
  //       ...prev,
  //       allReports: result?.data?.client_wise_reports?.client_data ?? [],
  //     }));
  //     setInitialStateLoading((prev) => ({
  //       ...prev,
  //       getAllClientsReportDataIsLoading: false,
  //     }));
  //   });
  // };

  const getAdvanceBillingReportData = (page, limit, searchQuery, filters) => {
    if (page && limit) {
      let url = `${mainURL}reports/advanced-billing/${userId}?page=${page}&limit=${limit}`;
      if (searchQuery) {
        url += `&searchQuery=${encodeURIComponent(searchQuery)}`;
      }
      if (filters) {
        if (filters.status && filters.status.value) {
          url += `&job_status=${encodeURIComponent(filters.status.value)}`;
        }
      }
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingReportList:
            result?.data?.advance_billing_jobs_reports?.jobs ?? [],
          totalPages: result?.data?.advance_billing_jobs_reports?.totalPages,
        }));
      });
    } else {
      const url = `${mainURL}reports/advanced-billing/${userId}`;
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          advanceBillingReportList:
            result?.data?.advance_billing_jobs_reports?.jobs ?? [],
        }));
      });
    }
  };

  // getMembersFreeFromTeam
  // const getMembersFreeFromTeam = (pageNo) => {
  //   if (pageNo) {
  //     // const url = `${mainURL}get-more/jobs/${userId}/`;
  //     const url = `${mainURL}get/member-without-team/${userId}${
  //       pageNo ? `?page=${pageNo.toString()}` : ""
  //     }`;

  //     getData(url, (result) => {
  //       setInitialState((prev) => ({
  //         ...prev,
  //         unassignedMembersList: result?.data?.unassigned_member_list ?? {},
  //       }));
  //     });
  //   }
  // };
  const getMembersFreeFromTeam = (pageNo, searchQuery = "") => {
    if (pageNo) {
      const url = `${mainURL}get/member-without-team/${userId}${
        pageNo ? `?page=${pageNo.toString()}` : ""
      }${searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : ""}`;

      // Make the API call
      getData(url, (result) => {
        setInitialState((prev) => ({
          ...prev,
          unassignedMembersList: result?.data?.unassigned_member_list ?? {},
        }));
      });
    }
  };

  const getAllClientsReportData = (page, limit, searchQuery) => {
    setInitialStateLoading((prev) => ({
      ...prev,
      getAllClientsReportDataIsLoading: true,
    }));

    const url = `${mainURL}reports/client-wise/${userId}`;
    const queryParams = new URLSearchParams();

    // Adding query parameters for pagination and filters
    if (page) queryParams.append("page", page);
    if (limit) queryParams.append("limit", limit);
    if (searchQuery) queryParams.append("searchQuery", searchQuery);
    const finalUrl = `${url}?${queryParams.toString()}`;

    getData(finalUrl, (result) => {
      setInitialState((prev) => ({
        ...prev,
        allReports: result?.data?.client_wise_reports,
      }));
      setInitialStateLoading((prev) => ({
        ...prev,
        getAllClientsReportDataIsLoading: false,
      }));
    });
  };

  return (
    <ContextAPI.Provider
      value={{
        mainURL,
        nnAPIKey,
        logout,
        initialStateLoading,
        getUserDetails,
        getAllJobCategories,
        getAllBillingServices,
        getAllAdvanceBillingJobs,
        getAllMembers,
        getAllClients,
        getAllAdHocClients,
        getAdvanceBillingReportData,
        getAllTeams,
        getTeamDetailsByMemberId,
        getTeamJobs,
        initialState,
        userDetails,
        getAllJobs,
        getJobsForPostDraftChanges,
        getJobsDetailsByMemberId,
        getAllAssignJobs,
        getAllHolidays,
        getAllMyTimeEntries,
        getAllTimeEntries,
        getAllBillableTimeEntries,
        getAllMyTeamTimeEntries,
        getAllMemberWiseReportData,
        getAllLogs,
        getMyLogs,
        setUserId,
        getAllInvoice,
        getAllClientsReportData,
        getAllMyTimeEntriesBasedOnDate,
        getSingleTeamDetails,
        getSingleClients,
        getMoreJobs,
        fetchPaginatedDataMemberAdvanceBilling,
        fetchPaginatedDataMemberMyTeam,
        getTeamDetailsByMemberI,
        getMoreAdvancedBillingJobs,
        getMembersFreeFromTeam,
        getAllAssignJobsForPagination,
        getAllAdvanceBillingJobsPagination,
        getAllTimeEntriesForPagination,
        getAllClientsList,
        fetchForSearchForMemberMyTeam,
        fetchForSearchForMemberAdvanceBilling,
        userId,
        getJobsDetailsByMemberIdPagination,
        getTeamIdOnly,
      }}
    >
      {children}
    </ContextAPI.Provider>
  );
};
