import React, { useContext, useMemo, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  fileIcon,
  searchIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import { UpdateTimeEntryModal } from "./UpdateTimeEntryModal";
import TimeEntriesFilter from "../../../Components/TimeEntriesFilter";
import { AddTimeEntryModal } from "./AddTimeEntryModal";
import {
  formatDate,
  formatTime,
} from "../../../../../utils/utilities/utilityFunctions";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { CheckTimeEntryDetails } from "./CheckTimeEntryDetails";
import BillableEntriesTable from "../../../Components/BilllableEntriesTable";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Jobs",
    pageURL: "/jobs",
  },
  {
    pageName: "Billable Time Entries",
    pageURL: "/jobs/billable-jobs",
  },
];

// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Member", key: "member_name" },
    { label: "Job", key: "task_name" },
    { label: "Team", key: "team_name" },
    { label: "Reviewer", key: "reviewer_name" },
    { label: "Status", key: "time_entries_status" },
    { label: "Date", key: "working_date" },
    { label: "Time", key: "working_time" },
    { label: "Description", key: "work_description" },
  ],
  fileName: "Billable Time Entries",
};

const BillableTimeEntriesContent = ({
  billableTimeEntriesPropsData,
  setIsUpdated,
  searchInput,
  handleInputChange,
  handleSearch,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  filters,
  setFilters,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  // const {
  //   getAllJobCategories,
  //   getAllTimeEntries,
  //   getAllBillableTimeEntries,
  //   initialState,
  // } = useContext(ContextAPI);
  // const [isUpdated, setIsUpdated] = useState(false);
  const [billableTimeEntriesData, setBillableTimeEntriesData] = useState([]);
  // const [filters, setFilters] = useState({
  //   status: null,
  //   entryAs: null,
  //   showDiscountedHoursEntries: false,
  //   reviewer: "",
  // });

  // useEffect(() => {
  //   getAllJobCategories();
  //   // getAllTimeEntries();
  //   getAllBillableTimeEntries();
  // }, [isUpdated]);

  const filterDiscountedData = (dataset) => {
    return dataset?.filter((entry) =>
      Boolean(entry?.adjustment_hours_reason?.trim())
    );
  };
  // useEffect(() => {
  //   setBillableTimeEntriesData(billableTimeEntriesPropsData);
  // }, [billableTimeEntriesPropsData]);

  useEffect(() => {
    const filteredEntries =
      billableTimeEntriesPropsData &&
      billableTimeEntriesPropsData?.filter((entry) => {
        const status = filters.status
          ? entry.time_entries_status === filters.status.value
          : true;
        const entryAs = filters.entryAs
          ? entry.entries_as === filters.entryAs.value
          : true;

        return status && entryAs;
      });

    let entriesData = filters.showDiscountedHoursEntries
      ? filterDiscountedData(filteredEntries)
      : filteredEntries;

    setBillableTimeEntriesData(entriesData);
  }, [filters, billableTimeEntriesPropsData]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
      Cell: ({ row }) => row.original.sr_no,
    },
    {
      Header: "Member",
      accessor: "member_name",
    },
    {
      Header: "Job",
      accessor: "task_name",
      Cell: ({ row }) => row.original.task_name ?? "---",
    },
    {
      Header: "Team",
      accessor: "team_name",
      Cell: ({ row }) => row.original.team_name ?? "---",
    },
    {
      Header: "Reviewer",
      accessor: "reviewer_name",
      Cell: ({ row }) => row.original.reviewer_name ?? "---",
    },
    {
      Header: "Status",
      accessor: "time_entries_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-start">
          <Stack direction="horizontal">
            {row.original.time_entries_status === "approved" ? (
              <Badge bg="success">Approved</Badge>
            ) : (
              <Badge bg="danger">Pending</Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Working Date",
      accessor: "working_date",
      Cell: ({ row }) => {
        const date = formatDate(row.original.working_date);
        const time = formatTime(row.original.working_time);
        return (
          <div className="d-flex flex-column justify-content-start  gap-1">
            <p className="m-0">{date}</p>
            <p className="m-0">{time}</p>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "work_description",
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <>
          {row.original.time_entries_status === "pending" && (
            <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
              <Tooltip
                id="edit-time-entry-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="edit-time-entry-tooltip"
                data-tooltip-content="Update Time Entry"
                data-tooltip-place="top"
              >
                <UpdateTimeEntryModal
                  setIsUpdated={setIsUpdated}
                  timeEntryData={row.original}
                />
              </div>
            </div>
          )}

          {row.original.time_entries_status === "approved" &&
          Boolean(row.original.adjustment_hours_reason?.trim()) ? (
            <>
              <Tooltip
                id="check-entry-details-tooltip"
                style={{
                  background: "#000",
                  color: "#fff",
                }}
                opacity={0.9}
              />
              <div
                data-tooltip-id="check-entry-details-tooltip"
                data-tooltip-content="Check Entry Details"
                data-tooltip-place="top"
              >
                <CheckTimeEntryDetails timeEntry={row.original} />
              </div>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no.",
    "Member",
    "Job",
    "Team",
    "Reviewer",
    "Status",
    "Description",
    "Working Date",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(
    () => billableTimeEntriesData,
    [billableTimeEntriesData]
  );

  // const tableInstance = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );
  const handlePageChange = (page) => {
    setCurrentPage(page);

    localStorage.setItem("currentPage", page); // Save the page number
    setIsUpdated((prev) => !prev); // Trigger data fetch
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update the component's state
    tableInstance.setPageSize(newSize); // Update the React Table's internal state
    setCurrentPage(1); // Reset to the first page
    localStorage.setItem("pageSize", newSize); // Save the page size
    localStorage.setItem("currentPage", 1); // Save the new page number
    setIsUpdated((prev) => !prev); // Trigger data fetch or re-render
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>
      {/* Top header section */}
      {/* <PageHeader
        tableInstance={tableInstance}
        icon={fileIcon}
        headerTitle={"Billable Time Entries"}
      >
      <AddTimeEntryModal setIsUpdated={setIsUpdated} />
      </PageHeader> */}
      <section className="main-content_header add-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={fileIcon} alt="client" />
          <p className="m-0 fs-4">Billable Time Entries</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
          {/* <button
            onClick={handleSearch}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            {loading1 ? <SpinningLoader /> : "  Search"}
          </button> */}
          <AddTimeEntryModal setIsUpdated={setIsUpdated} />
        </div>
      </section>
      <TimeEntriesFilter
        filters={filters}
        setFilters={setFilters}
        forTeamLeader={false}
        timeEntries={billableTimeEntriesPropsData}
        filterDiscountedData={filterDiscountedData}
      />

      {/* Time Entries Table */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <BillableEntriesTable
            tableInstance={tableInstance}
            headers={headers}
            billableTimeEntriesData={billableTimeEntriesData}
            columnHeaders={columnHeaders}
          />
          <div
            className="d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "45%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "48px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                // disabled={
                //   (billableTimeEntriesPropsData &&
                //     billableTimeEntriesPropsData?.length < pageSize) ||
                //   totalPages === 1
                // }
                disabled={
                  billableTimeEntriesPropsData?.length < pageSize ||
                  totalPages === 1 ||
                  currentPage === totalPages
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BillableTimeEntriesContent;
