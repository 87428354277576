import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

import { projectsIcon } from "../../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import {
  handleAPIError,
  headerOptions,
  presentDate,
  timeOptions,
} from "../../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";
// import TimePickerSection from "./TimePickerSection";
import TimePickerSection from "../../../MemberOrTeamLeaderJobs/JobEntry/Components/TimePickerSection";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, logout, userId, userDetails } = useContext(ContextAPI);

  const [isLoading, setIsLoading] = useState(false);
  const [teamId, setTeamId] = useState();
  const [entryDetails, setEntryDetails] = useState({
    entryDuration: "",
    entryDescription: "",
    job: "",
    employee: "",
    entryAs: "",
    teamId: teamId,
    entryDate: "",
    startTime: "",
    endTime: "",
  });

  const handleEntryAs = (e) => {
    setEntryDetails((prev) => ({
      ...prev,
      entryAs: e.target.value,
    }));
  };
  const handleClear = () => {
    setEntryDetails((prev) => ({
      ...prev,
      entryDuration: "",
      entryDescription: "",
      job: "",
      employee: "",
      entryAs: "",
      teamId: "",
      jobOptions: [],
      // employeeOptions: [],
      entryDate: new Date(),
    }));
  };
  const addTimeEntry = async () => {
    setIsLoading(() => true);
    try {
      const formattedEndDate = moment(
        entryDetails.entryDate.dateString,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      let body = {
        current_user:
          localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
        entry_for_user: entryDetails.employee.value,
        task_id: entryDetails.job.value,
        entries_as: entryDetails.entryAs,
        // working_date: entryDetails.entryDate.dateString,
        working_date: formattedEndDate,
        work_description: entryDetails.entryDescription,
        work_start_time: entryDetails?.startTime?.value,
        work_end_time: entryDetails?.endTime?.value,
      };

      if (entryDetails?.entryAs === "Team") {
        body.team_id = entryDetails.teamId;
      }

      const url = `${mainURL}add/time-entries-as-admin`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 201) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
        handleClear();
        props.onHide();
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };
  const handleAddTimeEntry = (e) => {
    e.preventDefault();

    const {
      entryDescription,
      job,
      employee,
      entryAs,
      startTime,
      endTime,
      entryDate,
    } = entryDetails;

    const bool =
      entryDescription &&
      job?.value &&
      employee?.value &&
      entryAs &&
      startTime?.value &&
      endTime?.value &&
      entryDate?.dateString;

    if (bool) {
      addTimeEntry();
    } else {
      const conditions = {
        [!entryDescription]: "Please input time entry description!",
        [!endTime.value]: "Please select Entry End Time!",
        [!startTime.value]: "Please select Entry Start Time!",
        [!entryDate.dateString]: "Please Select Entry Date!",
        [!job.value]: "Please select a Job!",
        [!entryAs]: "Please select entry as either team or individual!",
        [!employee.value]: "Please Select Employee!!",
      };

      const errorMessage = conditions[true];

      if (errorMessage) {
        ReactHotToast(errorMessage, "error");
      }
    }
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const handleChange = (date, dateString, element) => {
    onChangeDate(element, date, dateString);
  };
  const onChangeDate = (element, date, dateString) => {
    setEntryDetails((prev) => ({
      ...prev,
      [element]: { date, dateString },
    }));
  };

  //***************************************** */
  const [valueForEmployee, setValueForEmployee] = useState(null);
  const [value, setValue] = useState(null);
  const [valueForIndividual, setValueForIndividual] = useState(null);
  const [diable, setdiable] = useState(false);
  const [employeeUserId, setEmployeeUserId] = useState(entryDetails);
  useEffect(() => {
    // if (!employeeUserId) {
    //   ReactHotToast("Please select an employee.","error");
    // }
    if (entryDetails?.entryAs === "Team") {
      setdiable(true);
      const fetchTeamId = async () => {
        try {
          const response = await axios.get(
            `${mainURL}my-teams/${employeeUserId}`,
            {
              headers: headerOptions(),
            }
          );

          if (response.data?.team_data && response.data.team_data.length > 0) {
            const teamId = response.data.team_data[0]?.id;
            setTeamId(teamId);
            setEntryDetails((prev) => ({
              ...prev,
              teamId: teamId,
            }));
          }
        } catch (error) {
          console.error("Error fetching team data:", error);
          setdiable(false);
        } finally {
          setdiable(false);
        }
      };

      fetchTeamId();
    }
  }, [entryDetails?.entryAs, employeeUserId]);
  useEffect(() => {
    if (entryDetails?.entryAs === "Member") {
      setValue(null);
    }
    if (entryDetails?.entryAs === "Team") {
      setValueForIndividual(null);
    }
  }, [entryDetails.entryAs]);
  useEffect(() => {
    if (valueForEmployee?.value) {
      setEmployeeUserId(valueForEmployee.value);
    }
  }, [valueForEmployee]);

  async function UsersLoadOptions(search, loadedOptions, { page }) {
    console.log("page", page);
    try {
      // Make the API request to fetch the data
      const response = await axios.get(
        `${mainURL}list/all-member/${userId || userDetails?.member_id}`,
        {
          params: {
            page,
            limit: 10,
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );
      // Transform the data into the required format
      // const transformedData = response.data?.members_list?.users.map((item) => {
      //   try {
      //     // Parse the additional client data

      //     // Create the label for the job
      //     const label = `${item.member_name || "N/A"}`;

      //     return {
      //       value: item.member_id,
      //       label: label,
      //     };
      //   } catch (parseError) {
      //     console.error("Error parsing item:", parseError);
      //     return {
      //       value: item.member_id,
      //       label: "Parsing Error - Invalid Job Data",
      //     };
      //   }
      // });
      const transformedData = response.data?.members_list?.users
        ?.filter(
          (item) =>
            item.member_role !== "it_member" &&
            item.member_role !== "operation_member"
        ) // Filter out unwanted roles
        .map((item) => {
          try {
            // Create the label for the member
            const label = `${item.member_name || "N/A"}`;

            return {
              value: item.member_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.member_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        });
      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];
      const hasMore =
        Array.isArray(response.data?.members_list?.users) &&
        response.data.members_list.users.length > 0;

      return {
        options: combinedOptions,
        hasMore: response.data?.members_list?.hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  async function TeamloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${employeeUserId}/${teamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
            job_status: "In Progress",
          },
          headers: headerOptions(),
        }
      );

      const newOptions = Array.isArray(response.data.filterData.data)
        ? response.data.filterData.data.map((item) => {
            try {
              const additionalData = item.additional_data
                ? JSON.parse(item.additional_data)
                : {};

              const label = `${additionalData.bpo_no || "N/A"} - ${
                item.task_name || "N/A"
              } (Period Start Date: ${
                item.assigned_on || "N/A"
              } Period End Date: ${item.due_on || "N/A"})`;

              return {
                value: item.task_id,
                label: label,
              };
            } catch (parseError) {
              console.error("Error parsing item:", parseError);
              return {
                value: item.task_id,
                label: "Parsing Error - Invalid Job Data",
              };
            }
          })
        : []; // Return an empty array if filterData.data is not an array

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [
              ...(Array.isArray(loadedOptions.options)
                ? loadedOptions.options
                : []),
              ...newOptions,
            ];

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  async function MemberloadOptions(search, loadedOptions, { page }) {
    try {
      // Make the API request to fetch the data
      const response = await axios.get(`${mainURL}my-jobs/${employeeUserId}`, {
        params: {
          job_status: "In Progress",
          page,
          limit: 10,
          searchQuery: search || "",
        },
        headers: headerOptions(),
      });

      // Transform the data into the required format
      const transformedData = response.data.job_data.my_single_jobs.map(
        (item) => {
          try {
            // Parse the additional client data
            const additionalData = item.client_data
              ? JSON.parse(item.client_data)
              : {};

            // Create the label for the job
            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.job_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.assign_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.assign_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        }
      );

      // Combine options for pagination (for subsequent pages)
      const combinedOptions =
        page === 1
          ? transformedData
          : [...(loadedOptions.options || []), ...transformedData];

      return {
        options: combinedOptions,
        hasMore: response?.data?.job_data?.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: Array.isArray(loadedOptions.options)
          ? loadedOptions.options
          : [],
        hasMore: false,
      };
    }
  }

  useEffect(() => {
    if (!props.show) {
      setEntryDetails({
        startTime: "",
        endTime: "",
        entryDescription: "",
        job: "",
        entryAs: "",
        teamId: "",
        jobOptions: [],
        advancedBillingJobOptions: [],
        postDraftJobListOptions: [],
        entryDate: {
          date: dayjs(),
          dateString: dayjs().format("DD-MM-YYYY"),
        },
      });
      setValue("");
      setEmployeeUserId("");
      setValueForIndividual("");
      setValueForEmployee("");
    }
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Add Time Entry</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleAddTimeEntry}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {/* Select Employee */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobType">Select Employee:</label>
            <AsyncPaginate
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              value={valueForEmployee}
              loadOptions={UsersLoadOptions}
              onChange={(selectedOption) => {
                setValueForEmployee(selectedOption);
                setEntryDetails((prev) => ({
                  ...prev,
                  employee: selectedOption,
                }));
              }}
              additional={{
                page: 1,
              }}
              placeholder={`Select Employee`}
              debounceTimeout={300} // Prevent excessive API calls
              // Enhanced User Experience Features
              noOptionsMessage={({ inputValue }) =>
                inputValue
                  ? `No Employee found for "${inputValue}"`
                  : "No Employee found"
              }
              onError={(error) => {
                ReactHotToast("Error loading jobs", "error");
                console.error("Async Paginate Error:", error);
              }}
              // Custom Styles for Better Readability
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#007bff" : "white",
                  cursor: "pointer",
                  color: state.isSelected ? "white" : "black",
                  ":hover": {
                    backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "black",
                }),
              }}
            />
          </div>

          {/* Entry as */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignee-radio-group">Entry as:</label>
            <div
              name="assignee-radio-group"
              className="radio-group mt-2 d-flex justify-content-start"
            >
              <label htmlFor="Individual">
                <input
                  type="radio"
                  id="Individual"
                  value="Member"
                  name="assignee"
                  className="radio-btn"
                  checked={entryDetails?.entryAs === "Member"}
                  onChange={(e) => handleEntryAs(e)}
                />
                <span>Individual</span>
              </label>
              <label htmlFor="Team">
                <input
                  type="radio"
                  id="Team"
                  value="Team"
                  name="assignee"
                  className="radio-btn"
                  checked={entryDetails?.entryAs === "Team"}
                  onChange={(e) => handleEntryAs(e)}
                />
                <span>Team</span>
              </label>
            </div>
          </div>

          {/* Job Name */}
          <div className="form-group mt-4 w-100">
            <label htmlFor="jobName">Job Name:</label>
            {!entryDetails?.entryAs && (
              <div className="form-group  w-100">
                <AsyncPaginate
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                  placeholder={`Select Individual or Team `}
                />
              </div>
            )}
            {entryDetails?.entryAs == "Member" && (
              <AsyncPaginate
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={value}
                loadOptions={MemberloadOptions}
                onChange={(selectedOption) => {
                  setValue(selectedOption);
                  setEntryDetails((prev) => ({
                    ...prev,
                    job: selectedOption,
                  }));
                }}
                additional={{
                  page: 1,
                }}
                placeholder={`Select ${
                  entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                } Job`}
                debounceTimeout={300} // Prevent excessive API calls
                // Enhanced User Experience Features
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No jobs found for "${inputValue}"`
                    : "No jobs found"
                }
                onError={(error) => {
                  ReactHotToast("Error loading jobs", "error");
                  console.error("Async Paginate Error:", error);
                }}
                // Custom Styles for Better Readability
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            )}
            {entryDetails?.entryAs == "Team" && (
              <AsyncPaginate
                isDisabled={diable}
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={valueForIndividual}
                loadOptions={TeamloadOptions}
                onChange={(selectedOption) => {
                  setValueForIndividual(selectedOption);
                  setEntryDetails((prev) => ({
                    ...prev,
                    job: selectedOption,
                  }));
                }}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300} // Prevent excessive API calls
                placeholder={`Select ${
                  entryDetails?.entryAs === "Team" ? "Team" : "Individual"
                } Job`}
                // Enhanced User Experience Features
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No jobs found for "${inputValue}"`
                    : "No jobs found"
                }
                onError={(error) => {
                  ReactHotToast("Error loading jobs", "error");
                }}
                // Custom Styles for Better Readability
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                }}
              />
            )}
          </div>

          {/* Entry Date */}
          <div className="form-group mt-3 w-100">
            <label htmlFor="billingRates">Entry Date:</label>
            <DatePicker
              className="form-control datepicker"
              popupClassName="pop-up-box"
              onChange={(date, dateString) =>
                handleChange(date, dateString, "entryDate")
              }
              name="entryDate"
              placeholder="Select / Enter date in YYYY-MM-DD format"
              value={entryDetails.entryDate.date}
              disabledDate={disabledDate}
              format="DD-MM-YYYY"
            />
          </div>

          {/* Entry Duration */}
          <div className=" gap-4 align-items-center w-100">
            <div className="form-group mt-4 w-100">
              <label htmlFor="startTime">Start Time (hh:mm):</label>
              <TimePickerSection
                value={entryDetails?.startTime?.time}
                name="startTime"
                onChange={(time) => {
                  setEntryDetails((prevState) => ({
                    ...prevState,
                    startTime: {
                      value: `${time}`,
                    },
                  }));
                }}
              />
            </div>
            <div className="form-group mt-4 w-100">
              <label htmlFor="endTime">End Time (hh:mm):</label>
              <TimePickerSection
                onChange={(time) => {
                  setEntryDetails((prevState) => ({
                    ...prevState,
                    endTime: {
                      value: `${time}`,
                    },
                  }));
                }}
                name="endTime"
                value={entryDetails?.endTime?.time}
              />
            </div>
          </div>

          {/* Entry Description */}
          <div className="form-group mt-4 w-100">
            <label htmlFor="jobDescription">Entry Description:</label>
            <textarea
              id="jobDescription"
              name="jobDescription"
              className="w-100"
              rows={3}
              placeholder="Eg. Auditing Report (2022/23 Financial Year) for Doshi Accounting Company"
              value={entryDetails?.entryDescription}
              onChange={(e) =>
                setEntryDetails((prev) => ({
                  ...prev,
                  entryDescription: e.target.value,
                }))
              }
            />
          </div>

          <button type="submit" className="custom-btn mt-3">
            {isLoading ? <SpinningLoader /> : "Add Time Entry"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const AddTimeEntryModal = ({ setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <button className="custom-btn d-flex justify-content-center align-items-center gap-2">
          Add Time Entry <span className="fw-light fs-4">+</span>
        </button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
