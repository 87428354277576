import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {
  EditSVG,
  projectsIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import {
  headerOptions,
  handleAPIError,
} from "../../../../utils/utilities/utilityFunctions";

const statusOptions = [
  {
    label: "In Progress",
    value: 1,
  },
  {
    label: "Completed",
    value: 2,
  },
  {
    label: "On Hold",
    value: 3,
  },
];

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, logout, initialState, userDetails} = useContext(ContextAPI);

  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState("");
  const [price, setPrice] = useState("");
  const [isMiniBillable, setIsMiniBillable] = useState(false);

  useEffect(() => {
    const status = statusOptions.find(
      ({ label }) => label === props?.teamJobData?.job_status
    );
    setJobStatus(() => status);
  }, [props?.teamJobData?.job_status]);

  // updating team job api
  const updateTeamJob = async () => {
    const isMinimumBilling = isMiniBillable && price ? "yes" : "no";

    const body = {
      current_user: localStorage.getItem("userId") || userDetails?.member_id,
      assign_job_id: props.teamJobData?.task_id,
      status: jobStatus?.value,
      // team_id: props.teamId,
      is_minimum_billing: isMinimumBilling,
      team_id:initialState?.myTeamID,
      minimum_price: isMiniBillable ? price : "0",
    };

    const url = props.teamJobData?.allocated_hours
      ? `${mainURL}update/assign-advance-billing-job-status`
      : `${mainURL}update/assign-job-status`;

    setIsLoading(() => true);

    try {
      const result = await axios.put(url, body, {
        headers: headerOptions(),
      });

      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
      props.onHide();
    }
  };

  const handleUpdateTeamJob = (e) => {
    e.preventDefault();
    if (jobStatus?.value) {
      updateTeamJob();
    } else {
      if (!jobStatus?.value) {
        ReactHotToast("Please select status", "error");
      }
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <img src={projectsIcon} height={20} width={20} alt="user-icon" />
            <span className="modal-title">Update Team Job</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleUpdateTeamJob}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-3 w-100">
            <label htmlFor="assignJobName">Job Name:</label>
            <input
              id="assignJobName"
              name="assignJobName"
              placeholder="Eg: job name"
              type="text"
              value={props.teamJobData?.task_name}
              disabled
              readOnly
            />
          </div>
          {!props.teamJobData?.allocated_hours && (
            <div className="form-group mt-3 w-100">
              <label htmlFor="assignJobName">Billable Hours:</label>
              <input
                id="assignJobName"
                name="assignJobName"
                placeholder="Eg: billable hours"
                type="text"
                value={props.teamJobData?.total_billable_hours}
                disabled
                readOnly
              />
            </div>
          )}

          {!props.teamJobData?.allocated_hours &&
            props.teamJobData?.job_status === "In Progress" &&
            jobStatus?.value === 2 && (
              <>
                <div className="form-group mt-3 w-100">
                  <label htmlFor="miniBillable">Is Minimum Balance?</label>
                  <input
                    style={{ marginTop: "-17px", marginLeft: "-37px" }}
                    type="checkbox"
                    id="miniBillable"
                    name="miniBillable"
                    checked={isMiniBillable}
                    onChange={() => setIsMiniBillable((prev) => !prev)}
                  />
                </div>

                {isMiniBillable && (
                  <div className="form-group mt-3 w-100">
                    <label htmlFor="price">Price:</label>
                    <input
                      id="price"
                      name="price"
                      placeholder="Eg: price"
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                )}
              </>
            )}
          <div className="form-group mt-3 w-100">
            <label htmlFor="jobStatus">Status:</label>
            <Select
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
              isClearable={false}
              isSearchable={true}
              name="jobStatus"
              value={jobStatus}
              onChange={(option) => setJobStatus(option)}
              options={statusOptions}
            />
          </div>

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Update"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const EditTeamJobModal = ({ teamJobData, teamId, setIsUpdated }) => {

  const [modalShow, setModalShow] = useState(false);


  return (
    <>
      <span
        onClick={() => {
          setModalShow(true);
        }}
      >
        <EditSVG />
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        teamJobData={teamJobData}
        setIsUpdated={setIsUpdated}
        teamId={teamId}
      />
    </>
  );
};

// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import {
//   EditSVG,
//   projectsIcon,
// } from "../../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../../utils/utilities/utilityFunctions";

// const statusOptions = [
//   {
//     label: "In Progress",
//     value: 1,
//   },
//   {
//     label: "Completed",
//     value: 2,
//   },
//   {
//     label: "On Hold",
//     value: 3,
//   },
// ];

// const MyVerticallyCenteredModal = (props) => {
//   const { mainURL, logout, initialState } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);
//   const [jobStatus, setJobStatus] = useState("");
//   const [price, setPrice] = useState("");
//   const [isMiniBillable, setIsMiniBillable] = useState(false);

//   useEffect(() => {
//     const status = statusOptions.find(
//       ({ label }) => label === props?.teamJobData?.job_status
//     );
//     setJobStatus(() => status);
//   }, [props?.teamJobData?.job_status]);
//   const updateTeamJob = async () => {
//     const body = {
//       current_user: localStorage.getItem("userId"),
//       assign_job_id: props.teamJobData?.task_id,
//       status: jobStatus?.value,
//       team_id: props.teamId || initialState?.myTeams.id,
//       is_minimum_billing: "yes",
//       minimum_price: isMiniBillable ? price : "0",
//     };

//     const url = props.teamJobData?.allocated_hours
//       ? `${mainURL}update/assign-advance-billing-job-status`
//       : `${mainURL}update/assign-job-status`;

//     setIsLoading(true);

//     try {
//       const result = await axios.put(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");

//         // Create updated job data
//         const updatedJob = {
//           ...props.teamJobData,
//           job_status: jobStatus.label,
//         };
//         console.log("updatejob", updatedJob);

//         // Call the update function from props
//         props.onUpdateSuccess(updatedJob);
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(false);
//       props.onHide();
//     }
//   };

//   const handleUpdateTeamJob = (e) => {
//     e.preventDefault();
//     if (jobStatus?.value) {
//       updateTeamJob();
//     } else {
//       if (!jobStatus?.value) {
//         ReactHotToast("Please select status", "error");
//       }
//     }
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Update Team Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleUpdateTeamJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobName">Job Name:</label>
//             <input
//               id="assignJobName"
//               name="assignJobName"
//               placeholder="Eg: job name"
//               type="text"
//               value={props.teamJobData?.task_name}
//               disabled
//               readOnly
//             />
//           </div>
//           {!props.teamJobData?.allocated_hours && (
//             <div className="form-group mt-3 w-100">
//               <label htmlFor="assignJobName">Billable Hours:</label>
//               <input
//                 id="assignJobName"
//                 name="assignJobName"
//                 placeholder="Eg: billable hours"
//                 type="text"
//                 value={props.teamJobData?.total_billable_hours}
//                 disabled
//                 readOnly
//               />
//             </div>
//           )}

//           {!props.teamJobData?.allocated_hours &&
//             props.teamJobData?.job_status === "In Progress" &&
//             jobStatus?.value === 2 && (
//               <>
//                 <div className="form-group mt-3 w-100">
//                   <label htmlFor="miniBillable">Is Minimum Balance?</label>
//                   <input
//                     style={{ marginTop: "-17px", marginLeft: "-37px" }}
//                     type="checkbox"
//                     id="miniBillable"
//                     name="miniBillable"
//                     checked={isMiniBillable}
//                     onChange={() => setIsMiniBillable((prev) => !prev)}
//                   />
//                 </div>

//                 {isMiniBillable && (
//                   <div className="form-group mt-3 w-100">
//                     <label htmlFor="price">Price:</label>
//                     <input
//                       id="price"
//                       name="price"
//                       placeholder="Eg: price"
//                       type="number"
//                       value={price}
//                       onChange={(e) => setPrice(e.target.value)}
//                     />
//                   </div>
//                 )}
//               </>
//             )}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobStatus">Status:</label>
//             <Select
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//               isClearable={false}
//               isSearchable={true}
//               name="jobStatus"
//               value={jobStatus}
//               onChange={(option) => setJobStatus(option)}
//               options={statusOptions}
//             />
//           </div>

//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Update"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const EditTeamJobModal = ({
//   teamJobData,
//   teamId,
//   setIsUpdated,
//   onUpdateSuccess,
// }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <span onClick={() => setModalShow(true)}>
//         <EditSVG />
//       </span>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         teamJobData={teamJobData}
//         setIsUpdated={setIsUpdated}
//         teamId={teamId}
//         onUpdateSuccess={onUpdateSuccess}
//       />
//     </>
//   );
// };

// import React, { useState, useContext, useEffect } from "react";
// import axios from "axios";
// import Modal from "react-bootstrap/Modal";
// import Select from "react-select";
// import {
//   EditSVG,
//   projectsIcon,
// } from "../../../../utils/ImportingImages/ImportingImages";
// import { SpinningLoader } from "../../../../Components/SpinningLoader/SpinningLoader";
// import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
// import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
// import {
//   headerOptions,
//   handleAPIError,
// } from "../../../../utils/utilities/utilityFunctions";

// const statusOptions = [
//   {
//     label: "In Progress",
//     value: 1,
//   },
//   {
//     label: "Completed",
//     value: 2,
//   },
//   {
//     label: "On Hold",
//     value: 3,
//   },
// ];

// const MyVerticallyCenteredModal = (props) => {
//   const { mainURL, logout, initialState } = useContext(ContextAPI);
//   const [isLoading, setIsLoading] = useState(false);
//   const [jobStatus, setJobStatus] = useState("");
//   const [price, setPrice] = useState("");
//   const [isMiniBillable, setIsMiniBillable] = useState(false);

//   useEffect(() => {
//     const status = statusOptions.find(
//       ({ label }) => label === props?.teamJobData?.job_status
//     );
//     setJobStatus(() => status);
//   }, [props?.teamJobData?.job_status]);

//   const updateTeamJob = async () => {
//     const body = {
//       current_user: localStorage.getItem("userId"),
//       assign_job_id: props.teamJobData?.task_id,
//       status: jobStatus?.value,
//       team_id: props.teamId || initialState?.myTeamID,
//       is_minimum_billing: "yes",
//       minimum_price: isMiniBillable ? price : "0",
//     };

//     const url = props.teamJobData?.allocated_hours
//       ? `${mainURL}update/assign-advance-billing-job-status`
//       : `${mainURL}update/assign-job-status`;

//     setIsLoading(true);

//     try {
//       const result = await axios.put(url, body, {
//         headers: headerOptions(),
//       });

//       if (result.status === 200) {
//         ReactHotToast(result.data.message, "success");

//         // Create updated job data
//         const updatedJob = {
//           ...props.teamJobData,
//           job_status: jobStatus.label,
//         };
//         console.log("updatejob", updatedJob);

//         // Call the update function from props
//         props.onUpdateSuccess(updatedJob);
//       }
//     } catch (e) {
//       handleAPIError(e, logout);
//     } finally {
//       setIsLoading(false);
//       props.onHide();
//     }
//   };

//   const handleUpdateTeamJob = (e) => {
//     e.preventDefault();

//     // Check if the status is 'Completed' and if the checkbox is checked (isMiniBillable is true)
//     if (jobStatus?.value === 2 && isMiniBillable) {
//       // If minimum billing is selected, ensure price is provided
//       if (!price) {
//         ReactHotToast("Please enter a price before submitting.", "error");
//         return; // Prevent form submission if price is not provided
//       }
//     }

//     // If there's a selected status and we're allowed to submit
//     if (jobStatus?.value) {
//       updateTeamJob();
//     } else {
//       ReactHotToast("Please select status", "error");
//     }
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.onHide}
//       size="md"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header className="pt-3 pb-1" closeButton>
//         <Modal.Title className="w-100" id="contained-modal-title-vcenter">
//           <div className="d-flex justify-content-center align-items-center gap-3">
//             <img src={projectsIcon} height={20} width={20} alt="user-icon" />
//             <span className="modal-title">Update Team Job</span>
//           </div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="modal-body">
//         <form
//           onSubmit={handleUpdateTeamJob}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="assignJobName">Job Name:</label>
//             <input
//               id="assignJobName"
//               name="assignJobName"
//               placeholder="Eg: job name"
//               type="text"
//               value={props.teamJobData?.task_name}
//               disabled
//               readOnly
//             />
//           </div>
//           {!props.teamJobData?.allocated_hours && (
//             <div className="form-group mt-3 w-100">
//               <label htmlFor="assignJobName">Billable Hours:</label>
//               <input
//                 id="assignJobName"
//                 name="assignJobName"
//                 placeholder="Eg: billable hours"
//                 type="text"
//                 value={props.teamJobData?.total_billable_hours}
//                 disabled
//                 readOnly
//               />
//             </div>
//           )}
//           {!props.teamJobData?.allocated_hours && jobStatus?.value === 2 && (
//             <>
//               <div className="form-group mt-3 w-100">
//                 <label htmlFor="miniBillable">Is Minimum Balance?</label>
//                 <input
//                   style={{ marginTop: "-17px", marginLeft: "-37px" }}
//                   type="checkbox"
//                   id="miniBillable"
//                   name="miniBillable"
//                   checked={isMiniBillable}
//                   onChange={() => setIsMiniBillable((prev) => !prev)}
//                 />
//               </div>

//               {isMiniBillable && (
//                 <div className="form-group mt-3 w-100">
//                   <label htmlFor="price">Price:</label>
//                   <input
//                     id="price"
//                     name="price"
//                     placeholder="Eg: price"
//                     type="number"
//                     value={price}
//                     onChange={(e) => setPrice(e.target.value)}
//                   />
//                 </div>
//               )}
//             </>
//           )}
//           <div className="form-group mt-3 w-100">
//             <label htmlFor="jobStatus">Status:</label>
//             <Select
//               className="react-select-custom-styling__container"
//               classNamePrefix="react-select-custom-styling"
//               isClearable={false}
//               isSearchable={true}
//               name="jobStatus"
//               value={jobStatus}
//               onChange={(option) => setJobStatus(option)}
//               options={statusOptions}
//             />
//           </div>

//           <button type="submit" className="custom-btn mt-4">
//             {isLoading ? <SpinningLoader /> : "Update"}
//           </button>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export const EditTeamJobModal = ({
//   teamJobData,
//   teamId,
//   setIsUpdated,
//   onUpdateSuccess,
// }) => {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <span onClick={() => setModalShow(true)}>
//         <EditSVG />
//       </span>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         teamJobData={teamJobData}
//         setIsUpdated={setIsUpdated}
//         teamId={teamId}
//         onUpdateSuccess={onUpdateSuccess}
//       />
//     </>
//   );
// };
