import React, { useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";

import MemberList from "./MemberList";
import { useLocation, useNavigate } from "react-router-dom";
// import BillingServicesContent from "./components/BillingServicesContent";

const GetAllMembers = () => {
  const { getAllMemberWiseReportData, initialState } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("currentPage")) || 1
  );
  const [pageSize, setPageSize] = useState(
    Number(localStorage.getItem("pageSize")) || 10
  );

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("pageSize", pageSize);
    setCurrentPage(1); // Reset to first page when changing page size
  }, [pageSize]);


  return (
    <MemberList
      isLoading={initialState?.isLoading}
      setIsUpdated={setIsUpdated}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      isUpdated={isUpdated}
    />
  );
};

export default GetAllMembers;
// const GetAllMembers = () => {
//   const { initialState } = useContext(ContextAPI);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [isUpdated, setIsUpdated] = useState(false);
//   useEffect(() => {
//     const storedPage = localStorage.getItem("currentPage");
//     const storedPageSize = localStorage.getItem("pageSize");

//     if (storedPage) {
//       setCurrentPage(Number(storedPage));
//     }
//     if (storedPageSize) {
//       setPageSize(Number(storedPageSize));
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("currentPage", currentPage);
//   }, [currentPage]);

//   useEffect(() => {
//     localStorage.setItem("pageSize", pageSize);
//   }, [pageSize]);

//   const handlePageChange = (newPage) => {
//     if (newPage > 0) {
//       setCurrentPage(newPage);
//     }
//   };

//   return (
//     <MemberList
//       allMemberReports={initialState.allMemberReports}
//       isLoading={initialState?.isLoading}
//       currentPage={currentPage}
//       setIsUpdated={setIsUpdated}
//       setCurrentPage={handlePageChange} // Pass the handler to update page
//       pageSize={pageSize}
//       setPageSize={setPageSize}
//     />
//   );
// };

// export default GetAllMembers;
