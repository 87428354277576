import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Badge from "react-bootstrap/Badge";
import { InvoiceSendMail } from "./InvoiceSendMail";
import styles from "./Invoice.module.css";
import {
  InvoiceIcon,
  reportsIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { headerOptions } from "../../../../utils/utilities/utilityFunctions";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, userId } = useContext(ContextAPI);
  const [clientData, setClientData] = useState({});
  const [filters, setFilters] = useState({ status: null });
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [activeTab, setActiveTab] = useState("invoice");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJobsByTab, setSelectedJobsByTab] = useState({
    invoice: [],
    advanceBilling: [],
    adhocBilling: [],
  });
  const statusOptions = [
    { label: "Unpaid", value: "1" },
    { label: "Partially Paid", value: "2" },
    { label: "Paid", value: "3" },
  ];
  const handleTabChange = (newTab) => {
    if (newTab === activeTab) return; // Prevent unnecessary fetch if same tab is clicked again
    setActiveTab(newTab);
    setPage(1);
    setHasMore(true);
    setClientData((prev) => ({
      ...prev,
      [newTab]: [],
    }));
    fetchData(1, newTab);
  };
  useEffect(() => {
    if (props.show) {
      setActiveTab("invoice");
      setClientData({
        invoice: [],
        advanceBilling: [],
        adhocBilling: [],
      });
      setFilters((prevFilters) => ({ ...prevFilters, status: null }));
      setPage(1);
      setHasMore(true);
      setSelectAll(false);
      setSelectedJobsByTab({
        invoice: [],
        advanceBilling: [],
        adhocBilling: [],
      });
      fetchData(1, activeTab);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show || filters.status !== null){
      setPage(1);
      setHasMore(true);
      fetchData(1, activeTab);
    }
  }, [activeTab, filters.status]);

  const invoiceTypeMapping = {
    invoice: "regular",
    advanceBilling: "advance_billing",
    adhocBilling: "adhoc_billing",
  };
  const getJobsByTab = (tab) => {
    const { advanceBilling, invoice, adhocBilling } = clientData || {};

    switch (tab) {
      case "invoice":
        return invoice || [];
      case "advanceBilling":
        // console.log("Advance Billing Data:", advanceBilling);
        return advanceBilling || [];
      case "adhocBilling":
        return adhocBilling || [];
      default:
        return [];
    }
  };
  const handleSelectAllChange = () => {
    const currentTabJobs = getJobsByTab(activeTab).filter(
      (job) => job.payment_status !== "paid"
    );
    if (selectAll) {
      setSelectedJobsByTab((prev) => ({
        ...prev,
        [activeTab]: [],
      }));
    } else {
      const allJobIds = currentTabJobs.map((job) => job.id || job.invoice_id);
      setSelectedJobsByTab((prev) => ({
        ...prev,
        [activeTab]: allJobIds,
      }));
    }
    setSelectAll(!selectAll);
  };
  const handleCheckboxChange = (jobId) => {
    setSelectedJobsByTab((prev) => {
      const newSelected = prev[activeTab].includes(jobId)
        ? prev[activeTab].filter((id) => id !== jobId)
        : [...prev[activeTab], jobId];

      return { ...prev, [activeTab]: newSelected };
    });
  };
  const renderTable = () => {
    let jobs = getJobsByTab(activeTab);
    // if (jobs.length === 0) {
    //   console.log("No jobs to display");
    // }
    if (filters.status) {
      jobs = jobs.filter((job) => {
        switch (filters.status.value) {
          case "1":
            return job.payment_status === "unpaid";
          case "2":
            return job.payment_status === "partial_paid";
          case "3":
            return job.payment_status === "paid";
          default:
            return true;
        }
      });
    }
    return (
      <div
        className={styles.gridtable}
        style={{
          height: "calc(100vh - 350px)",
          overflowY: "hidden",
          marginBottom: "12px",
        }}
      >
        <InfiniteScroll
          style={{ overflowY: "hidden" }}
          dataLength={jobs.length}
          next={() => {
            setPage(page + 1);
            fetchData(page + 1);
          }}
          hasMore={hasMore}
          // endMessage={
          //   page !== 1 && <div className="text-center">No more data</div>
          // }
          scrollableTarget="scrollableDiv"
        >
          <table className="table table-bordered table-hover mt-2 w-100">
            <thead>
              <tr>
                <th style={{ textAlign: "center", width: "50px" }}>
                  {jobs.filter((job) => job.payment_status !== "paid").length >
                    0 && (
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  )}
                </th>
                <th>Invoice Code</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody
              id="scrollableDiv"
              style={{ paddingBottom: "12px", height: "460px" }}
            >
              {isLoading && page == 1 ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <Spinner animation="border" />
                  </td>
                </tr>
              ) : jobs.length > 0 ? (
                jobs.map((item) => (
                  <tr key={item.id}>
                    <td style={{ textAlign: "center", width: "50px" }}>
                      {item.payment_status !== "paid" && (
                        <input
                          type="checkbox"
                          checked={selectedJobsByTab[activeTab].includes(
                            item.id || item.invoice_id
                          )}
                          onChange={() =>
                            handleCheckboxChange(item.id || item.invoice_id)
                          }
                        />
                      )}
                    </td>
                    <td>{item.invoice_code || item.invoice_no || "N/A"}</td>
                    <td>{item.job_description || "N/A"}</td>
                    <td>{item.total_amount || "N/A"}</td>
                    <td style={{ textAlign: "center" }}>
                      <Badge bg={getStatusBadge(item.payment_status)}>
                        {item.payment_status}
                      </Badge>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No data found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
        {isLoading && page !== 1 && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </div>
        )}
      </div>
    );
  };
  const getStatusBadge = (status) => {
    switch (status.toLowerCase()) {
      case "unpaid":
        return "danger";
      case "partial_paid":
        return "warning";
      case "paid":
        return "success";
      default:
        return "secondary";
    }
  };
  const resetSelectedJobs = () => {
    setSelectedJobsByTab({
      invoice: [],
      advanceBilling: [],
      adhocBilling: [],
    });
    setSelectAll(false);
  };
  const fetchData = async (page, tab = activeTab) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${mainURL}invoice/client-wise/${userId}/${props.clientData?.client_id}`,
        {
          params: {
            invoice_type: invoiceTypeMapping[tab],
            page,
            limit: 10,
            payment_status: filters.status?.value || "",
          },
          headers: headerOptions(),
        }
      );
      const newJobs = response?.data?.filterData?.invoice_data || [];
      setHasMore(response?.data?.filterData?.has_more || false);
      setClientData((prev) => ({
        ...prev,
        [tab]: page === 1 ? newJobs : [...(prev[tab] || []), ...newJobs],
      }));
      setPage(page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Client's Invoice</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className=" mt-2 d-flex justify-content-start align-items-center gap-2">
          <div className="relative-wrapper">
            <img className="search-icon" src={reportsIcon} alt="search-icon" />
            <Select
              closeMenuOnSelect={true}
              isClearable={true}
              options={statusOptions}
              onChange={(option) => setFilters({ status: option })}
              value={filters.status}
              placeholder="Select status"
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <button
            className={`btn ${
              activeTab === "invoice" ? "btn-light" : "btn-secondary"
            }`}
            style={{
              backgroundColor: activeTab === "invoice" ? "#00263d" : "",
              color: activeTab === "invoice" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => handleTabChange("invoice")}
          >
            Regular Invoice
          </button>
          <button
            className={`btn ${
              activeTab === "advanceBilling" ? "btn-light" : "btn-secondary"
            }`}
            style={{
              backgroundColor: activeTab === "advanceBilling" ? "#00263d" : "",
              color: activeTab === "advanceBilling" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => handleTabChange("advanceBilling")}
          >
            Advance Billing
          </button>
          <button
            className={`btn ${
              activeTab === "adhocBilling" ? "btn-light" : "btn-secondary"
            }`}
            style={{
              backgroundColor: activeTab === "adhocBilling" ? "#00263d" : "",
              color: activeTab === "adhocBilling" ? "white" : "",
              fontWeight: "500",
            }}
            onClick={() => handleTabChange("adhocBilling")}
          >
            Adhoc Billing
          </button>
          {selectedJobsByTab[activeTab].length > 0 && (
            <InvoiceSendMail
              show={showSendMailModal}
              onHide={() => {
                setShowSendMailModal(false);
                resetSelectedJobs();
              }}
              invoiceIds={selectedJobsByTab[activeTab]}
              // clientId={clientData.client_id}
              clientId={props.clientData?.client_id}
              activeTab={activeTab}
              emailOption={props.clientData.email}
              resetSelectedJobs={resetSelectedJobs}
            />
          )}
        </div>

        {renderTable()}
      </Modal.Body>
    </Modal>
  );
};
export const ClientWiseInvoiceListModal = ({ clientData, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <div className="">
          <InvoiceIcon />
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        clientData={clientData}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};