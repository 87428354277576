import React, { useContext, useEffect, useState } from "react";

// import ClientWiseContent from "./Components/ClientWiseContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import TeamWiseContent from "./components/TeamWiseContent";

const TeamWise = () => {
  const { initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  return <TeamWiseContent setIsUpdated={setIsUpdated} />;
};

export default TeamWise;
