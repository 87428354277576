// import React, { useContext, useEffect, useState, useCallback } from "react";
// import TeamsContent from "./components/TeamsContent";
// import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
// import debounce from "lodash/debounce";

// const MemberOrTeamLeaderTeams = () => {
//   const {
//     initialState,
//     getTeamDetailsByMemberId,getTeamIdOnly,
//     initialStateLoading,
//     getTeamJobs,
//   } = useContext(ContextAPI);

//   const [isUpdated, setIsUpdated] = useState(false);
//   const [searchInput, setSearchInput] = useState("");
//   const [activeTab, setActiveTab] = useState("myJobs");
//   const [filters, setFilters] = useState({ status: null });
//   const [isLoadingData, setIsLoadingData] = useState(false);

//   useEffect(() => {
//     // getTeamDetailsByMemberId();
//     getTeamIdOnly();
//   }, []);
//   const storedPage = localStorage.getItem("currentPage");
//   const [currentPage, setCurrentPage] = useState(storedPage ? Number(storedPage) : 1);

//   const storedPageSize = localStorage.getItem("pageSize");
//   const [pageSize, setPageSize] = useState(storedPageSize ? Number(storedPageSize) : 20);

//   useEffect(() => {
//     const handleRefresh = () => {
//       setCurrentPage(1);
//       setPageSize(20);
//       localStorage.setItem("currentPage", 1);
//       localStorage.setItem("pageSize", 20);
//     };

//     window.addEventListener("beforeunload", handleRefresh);

//     return () => {
//       localStorage.removeItem("currentPage");
//       localStorage.removeItem("pageSize");
//       window.removeEventListener("beforeunload", handleRefresh);
//     };
//   }, []);


//   const handleTabChange = (newTab) => {
//     setActiveTab(newTab);
//     setSearchInput(""); 
//     setCurrentPage(1); 
//   };

//   const fetchData = async (taskType, searchTerm) => {
//     try {
//       await getTeamJobs(currentPage, pageSize, initialState?.singleTeamId?.id, taskType, searchTerm, filters);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       // setIsLoadingData(false);
//     }
//   };

//   // Debounced search handler
//   const debouncedSearch = useCallback(
//     debounce(async (searchTerm) => {
//       const taskType = activeTab === "myJobs" ? "billable_hours" : "advance_billing";
//       if (initialState?.singleTeamId?.id) {
//         // setIsLoadingData(true); 
//         await fetchData(taskType, searchTerm); 
//       }
//     }, 500), 
//     [activeTab, initialState?.singleTeamId?.id, filters, currentPage, pageSize]
//   );

//   useEffect(() => {
//     if (searchInput !== "") {
//       debouncedSearch(searchInput);
//     } else {
//       const taskType = activeTab === "myJobs" ? "billable_hours" : "advance_billing";
//       if (initialState?.singleTeamId?.id) {
//         fetchData(taskType, ""); 
//       }
//     }
//   }, [searchInput, currentPage, pageSize, filters, activeTab, initialState?.singleTeamId?.id, debouncedSearch,isUpdated]);

//   const handleInputChange = (e) => {
//     const trimmedInput = e.target.value.replace(/^\s+/, '');
//     setSearchInput(trimmedInput); 
//     setCurrentPage(1);

//   };
//   useEffect(() => {
//     setCurrentPage(1); 
//   }, [filters]);

//   return (
//     <>
//       <TeamsContent
//         activeTab={activeTab}
//         handleTabChange={handleTabChange}
//         teamData={initialState?.myTeamsJobs || []}
//         setIsUpdated={setIsUpdated}
//         isUpdated={isUpdated}
//         totalPages={initialState?.totalPages}
//         setFilters={setFilters}
//         filters={filters}
//         currentPage={currentPage}
//         pageSize={pageSize}
//         setCurrentPage={setCurrentPage}
//         setPageSize={setPageSize}
//         searchInput={searchInput}
//         setSearchInput={setSearchInput}
//         handleInputChange={handleInputChange}
//         isLoading={initialStateLoading?.getTeamJobsIsLoading}
//         setIsLoadingData={setIsLoadingData}
//       />
//     </>
//   );
// };

// export default MemberOrTeamLeaderTeams;
import React, { useContext, useEffect, useState, useCallback } from "react";
import TeamsContent from "./components/TeamsContent";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import debounce from "lodash/debounce";

const MemberOrTeamLeaderTeams = () => {
  const {
    initialState,
    getTeamDetailsByMemberId,
    getTeamIdOnly,
    initialStateLoading,
    getTeamJobs,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [activeTab, setActiveTab] = useState("myJobs");
  const [filters, setFilters] = useState({ status: null });
  const [isLoadingData, setIsLoadingData] = useState(false);

  // Page state without using localStorage
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [pageSize, setPageSize] = useState(20); // Default to 20 items per page

  // Get team details on initial load
  useEffect(() => {
    // getTeamIdOnly(); // or any other data fetching you need
    getTeamDetailsByMemberId()
  }, []);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSearchInput(""); // Clear search when tab changes
    setCurrentPage(1);  // Reset to the first page when tab changes
  };

  const fetchData = async (taskType, searchTerm) => {
    try {
      await getTeamJobs(
        currentPage,
        pageSize,
        initialState?.myTeamID,
        taskType,
        searchTerm,
        filters
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingData(false);
    }
  };

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      const taskType = activeTab === "myJobs" ? "billable_hours" : "advance_billing";
      if (  initialState?.myTeamID) {
        setIsLoadingData(true); // Start loading
        await fetchData(taskType, searchTerm);
      }
    }, 500),
    [activeTab,   initialState?.myTeamID, filters, currentPage, pageSize]
  );

  useEffect(() => {
    if (searchInput !== "") {
      debouncedSearch(searchInput);
    } else {
      const taskType = activeTab === "myJobs" ? "billable_hours" : "advance_billing";
      if (initialState?.myTeamID) {
        fetchData(taskType, ""); // Fetch data without search term
      }
    }
  }, [
    searchInput,
    currentPage,
    pageSize,
    filters,
    activeTab,
    initialState?.myTeamID,
    debouncedSearch,
    isUpdated,
  ]);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value.replace(/^\s+/, "");
    setSearchInput(trimmedInput);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when filters change
  }, [filters]);

  return (
    <TeamsContent
      activeTab={activeTab}
      handleTabChange={handleTabChange}
      teamData={initialState?.myTeamsJobs || []}
      setIsUpdated={setIsUpdated}
      isUpdated={isUpdated}
      totalPages={initialState?.totalPages}
      setFilters={setFilters}
      filters={filters}
      currentPage={currentPage}
      pageSize={pageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
      isLoading={initialStateLoading?.getTeamJobsIsLoading}
      setIsLoadingData={setIsLoadingData}
    />
  );
};

export default MemberOrTeamLeaderTeams;

