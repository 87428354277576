import React, { useEffect } from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const AdHocTable = ({
  tableInstance,
  headers,
  adHocClientsList,
  columnHeaders,
}) => {
  return (
    <section className="">
      <ReactTable tableInstance={tableInstance} columnHeaders={columnHeaders} />
      <ReactTableFooter
        headers={headers}
        data={adHocClientsList}
        tableInstance={tableInstance}
        text="adHocBilling"
      />
    </section>
  );
};

export default AdHocTable;
