import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import {
  EyeIconWithCircle,
  reportsIcon,
} from "../../../../utils/ImportingImages/ImportingImages";
import Select from "react-select";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { headerOptions } from "../../../../utils/utilities/utilityFunctions";
import styles from "./Invoice.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, userId } = useContext(ContextAPI);
  const [jobs, setJobs] = useState([]);
  const [hasMoreData, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: null,
  });

  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];

  const fetchJobs = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${mainURL}/get/all-client-job/${userId}/${props.clientData?.client_id}`,
        {
          params: {
            job_status: filters.status?.value || "",
            page: page,
            limit: 10,
          },
          headers: headerOptions(),
        }
      );

      const newJobs = response.data.client_jobs.data || [];
      setJobs((prevJobs) => (page === 1 ? newJobs : [...prevJobs, ...newJobs]));
      setHasMore(response.data.client_jobs.has_more || false);
      setPage(page);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.show) {
      setFilters((prevFilters) => ({ ...prevFilters, status: null }));
      setJobs([]);
      setPage(1);
      setHasMore(true);
      fetchJobs(1);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show || filters.status !== null) {
      setJobs([]);
      setPage(1);
      setHasMore(true);
      fetchJobs(1);
    }
  }, [filters]);

  // useEffect(() => {
  //   if (props.show) {
  //     setJobs([]);
  //     setPage(1);
  //     setHasMore(true);
  //     fetchJobs(1);
  //   }
  // }, [props.show,filters]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Client's Jobs</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="relative-wrapper w-25 mb-1">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) =>
              setFilters((prevFilters) => ({ ...prevFilters, status: option }))
            }
            value={filters.status}
            placeholder="Select status"
            style={{ paddingLeft: "35px" }}
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
        <div
          className={styles.gridtable}
          // id="scrollableDiv"
          style={{
            height: "calc(100vh - 350px)",
            overflowY: "hidden",
            marginBottom: "12px",
          }}
        >
          <InfiniteScroll
            style={{ overflowY: "hidden" }}
            dataLength={jobs.length}
            next={() => {
              setPage(page + 1);
              fetchJobs(page + 1);
            }}
            hasMore={hasMoreData}
            scrollableTarget="scrollableDiv"
            // endMessage={<div className="text-center">No more data</div>}
          >
            <table className="table table-bordered table-hover w-100">
              <thead>
                <tr>
                  <th>Sr no</th>
                  <th>Job Name</th>
                  <th>Assign To</th>
                  <th>BPO No.</th>
                  <th>Job Code</th>
                  <th>Job Status</th>
                </tr>
              </thead>
              <tbody id="scrollableDiv" style={{ paddingBottom: "12px" }}>
                {isLoading && page === 1 ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : jobs.length > 0 ? (
                  jobs.map((item, index) => (
                    <tr key={item?.client_code}>
                      <td>{index + 1}</td>
                      <td>{item.job_name}</td>
                      <td
                        style={{ color: item.assign_to ? "inherit" : "grey" }}
                      >
                        {item.assign_to || "N/A"}
                      </td>
                      <td>{item.bpo_no || "N/A"}</td>
                      <td>{item.job_code}</td>
                      <td>
                        <Stack direction="horizontal">
                          {item.job_status === "Completed" ? (
                            <Badge bg="success">Completed</Badge>
                          ) : item.job_status === "On Hold" ? (
                            <Badge bg="danger">On Hold</Badge>
                          ) : (
                            <Badge bg="warning" text="dark">
                              In Progress
                            </Badge>
                          )}
                        </Stack>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
          {isLoading && page !== 1 && (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ClientWiseReportModal = ({ clientData, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <EyeIconWithCircle />
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        clientData={clientData}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};