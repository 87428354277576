import React, { useContext, useState, useEffect, useCallback } from "react";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import AllTimeEntriesContent from "./AdminOrManager/TimeEntries/AllTimeEntriesContent";
import MyTeamTimeEntriesContent from "./MemberOrTeamLeaderJobs/TimeEntries/MyTeamTimeEntriesContent";
import { debounce } from "lodash";

// const TimeEntries = () => {
//   const { userDetails } = useContext(ContextAPI);
//   const {
//     getAllTimeEntriesForPagination,
//     getTeamDetailsByMemberId,
//     getAllJobCategories,
//     getAllMyTeamTimeEntries,
//     initialState,
//   } = useContext(ContextAPI);

//   const [isUpdated, setIsUpdated] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filters, setFilters] = useState({
//     status: null,
//     entryAs: null,
//     showDiscountedHoursEntries: false,
//     reviewer: null,
//     myTeamID: null,
//   });

//   // console.log("fitergdfgdf",);

//   console.log("serch ", searchQuery);
//   console.log("filter", filters);

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((query, currentFilters, page, limit) => {
//       if (
//         userDetails?.member_role === "it_member" ||
//         userDetails?.member_role === "operation_member"
//       ) {
//         getAllTimeEntriesForPagination(page, limit, query, currentFilters);
//       } else {
//         getAllMyTeamTimeEntries(
//           currentFilters.myTeamID,
//           page,
//           limit,
//           query,
//           currentFilters
//         );
//       }
//     }, 500),
//     []
//   );

//   useEffect(() => {
//     getTeamDetailsByMemberId();
//   }, []);

//   useEffect(() => {
//     debouncedSearch(searchQuery, filters, currentPage, pageSize);
//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [searchQuery, filters, currentPage, pageSize]);

//   useEffect(() => {
//     if (
//       userDetails?.member_role === "it_member" ||
//       userDetails?.member_role === "operation_member"
//     ) {
//       const timer = setTimeout(() => {
//         getAllJobCategories();
//         getAllTimeEntriesForPagination(
//           currentPage,
//           pageSize,
//           searchQuery,
//           filters
//         );
//       }, 100);
//       return () => clearTimeout(timer);
//     }
//   }, [isUpdated, currentPage, pageSize]);

//   const handleFilterChange = (type, value) => {
//     setFilters((prev) => ({
//       ...prev,
//       [type]: value,
//     }));
//   };

//   const handleSearchChange = (value) => {
//     setSearchQuery(value);
//   };
//   // Reset pagination when filters or search changes
//   useEffect(() => {
//     setCurrentPage(1);
//   }, [searchQuery, filters]);

//   const isAdminOrManager =
//     userDetails?.member_role === "it_member" ||
//     userDetails?.member_role === "operation_member";
//   return (
//     <>
//       {isAdminOrManager ? (
//         <AllTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesList={initialState?.allTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//           searchQuery={searchQuery}
//           onSearchChange={handleSearchChange}
//           filters={filters}
//           onFilterChange={handleFilterChange}
//         />
//       ) : (
//         <MyTeamTimeEntriesContent
//           setIsUpdated={setIsUpdated}
//           isLoading={initialState?.isLoading}
//           fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
//           totalPages={initialState?.totalPages}
//           currentPage={currentPage}
//           setCurrentPage={setCurrentPage}
//           pageSize={pageSize}
//           setPageSize={setPageSize}
//           searchQuery={searchQuery}
//           onSearchChange={handleSearchChange}
//           filters={filters}
//           onFilterChange={handleFilterChange}
//         />
//       )}
//     </>
//   );
// };

const TimeEntries = () => {
  const {
    getAllTimeEntriesForPagination,
    getTeamDetailsByMemberId,
    getAllJobCategories,
    getAllMyTeamTimeEntries,
    initialState,
    initialStateLoading,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    status: null,
    entryAs: null,
    showDiscountedHoursEntries: false,
    reviewer: null,
    myTeamID: null,
  });

  const storedRole = localStorage.getItem("userRole");
  useEffect(() => {
    getTeamDetailsByMemberId();
    getAllJobCategories();
  }, []);

  // // Debounced search function
  // const debouncedSearch = useCallback(
  //   debounce((query, currentFilters, page, limit) => {
  //     if (
  //       userDetails?.member_role === "it_member" ||
  //       userDetails?.member_role === "operation_member"
  //     ) {
  //       // Admin/Manager roles API call
  //       getAllTimeEntriesForPagination(page, limit, query, currentFilters);
  //     } else if (
  //       userDetails?.member_role === "member" ||
  //       userDetails?.member_role === "leader"
  //     ) {
  //       // Member/Leader roles API call
  //       getAllMyTeamTimeEntries(
  //         currentFilters.myTeamID,
  //         page,
  //         limit,
  //         query,
  //         currentFilters
  //       );
  //     }
  //   }, 500),
  //   []
  // );

  // useEffect(() => {
  //   debouncedSearch(searchQuery, filters, currentPage, pageSize);
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [searchQuery, filters, currentPage, pageSize]);

  useEffect(() => {
    if (storedRole === "it_member" || storedRole === "operation_member") {
      const timer = setTimeout(() => {
        getAllTimeEntriesForPagination(
          currentPage,
          pageSize,
          searchQuery,
          filters
        );
      }, 100);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        // Member and Leader specific API call
        if (initialState?.myTeamID) {
          getAllMyTeamTimeEntries(
            initialState?.myTeamID,
            currentPage,
            pageSize,
            searchQuery,
            filters
          );
          getAllJobCategories();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [
    isUpdated,
    currentPage,
    pageSize,
    searchQuery,
    filters,
    initialState?.myTeamID,
    storedRole,
  ]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // Reset pagination when filters or search changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filters]);

  return (
    <>
      {storedRole === "it_member" || storedRole === "operation_member" ? (
        <AllTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={
            initialStateLoading?.getAllTimeEntriesForPaginationIsLoading
          }
          fetchedTimeEntriesList={initialState?.allTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      ) : (
        <MyTeamTimeEntriesContent
          setIsUpdated={setIsUpdated}
          isLoading={initialStateLoading?.getAllMyTeamTimeEntriesIsLoading}
          fetchedTimeEntriesListTeam={initialState?.myteamTimeEntries}
          totalPages={initialState?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      )}
    </>
  );
};

export default TimeEntries;
