import React, { useContext, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import PageHeader from "./PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import styles from "./Invoices.module.css";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { Tooltip } from "react-tooltip";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import { PlusIconSVG } from "../../../utils/ImportingImages/ImportingImages";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceFooter from "../../Invoices/Components/InvoiceFooter";
import InvoiceClientDetails from "../../Invoices/Components/InvoiceClientDetails";
import { EditSVG } from "../../../utils/ImportingImages/ImportingImages";
import removeImg from "../../../assets/images/remove.png";
import AdHocBillingClientDetails from "./AdHocBillingClientDetails";
const animatedComponents = makeAnimated();
const breadCrumbs = [
  {
    pageName: "Ad Hoc",
    pageURL: "/add-hoc",
  },
  {
    pageName: "Ad Hoc Invoice",
    pageURL: "/invoice",
  },
];

const taxOptions = [
  {
    label: "VAT 5%",
    value: "5",
  },
  {
    label: "VAT 10%",
    value: "10",
  },
  {
    label: "VAT 15%",
    value: "15",
  },
  {
    label: "VAT 20%",
    value: "20",
  },
];

const paymentOptions = [
  { label: "Unpaid", value: "1" },
  { label: "Partially Paid", value: "2" },
  { label: "Paid", value: "3" },
];

const AdHocBillingInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const {
    invoiceMeta,
    assignId,
    isInvoicePreview,
    id,
    invoiceNo,
    adHocMailInvoice,
  } = location.state || {};
  const [isPreview, setIsPreview] = useState(isInvoicePreview ?? false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editable, setEditable] = useState(true);
  const [invoice, setInvoice] = useState({
    totalPrice: null,
    subTotal: null,
    paymentStatus: "",
    tax: [taxOptions.find((option) => option.value === "20")],
    clientHasPaid: 0,
    amount: 0,
    serviceName: "",
    finalTotal: 0,
  });

  // useEffect(() => {
  //   const calculateTotals = () => {
  //     const subTotal = Math.round(invoice.amount);
  //     const totalTaxAmount = invoice.tax.reduce((acc, tax) => acc + Math.round((tax.value * subTotal) / 100), 0);
  //     const finalTotal = Math.round(subTotal + totalTaxAmount);
  //     setInvoice((prev) => ({ ...prev, subTotal, finalTotal }));
  //   };

  //   if (!isInvoicePreview) calculateTotals();
  // }, [invoice.amount, invoice.tax, isInvoicePreview]);

  useEffect(() => {
    const calculateTotals = () => {
      const subTotal = parseFloat(invoice.amount).toFixed(2);
      const totalTaxAmount = invoice.tax.reduce(
        (acc, tax) =>
          acc + parseFloat(((tax.value * subTotal) / 100).toFixed(2)),
        0
      );
      const finalTotal = (parseFloat(subTotal) + totalTaxAmount).toFixed(2); 
      setInvoice((prev) => ({
        ...prev,
        subTotal,
        totalTaxAmount,
        finalTotal,
      }));
    };

    if (!isInvoicePreview) calculateTotals();
  }, [invoice.amount, invoice.tax, isInvoicePreview]);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setInvoice((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditBillingRateClick = () => {
    setEditable(!editable);
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <PageHeader
        id={id}
        adHocMailInvoice={adHocMailInvoice}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
        invoice={invoice}
        assignId={assignId}
        invoiceMeta={invoiceMeta}
        isInvoicePreview={isInvoicePreview}
        setInvoice={setInvoice}
        setIsUpdated={setIsUpdated}
      />

      {/* Actual Invoice Content */}
      <section className={`mr-40 mt-5 ml-30 mb-15`}>
        <div className="card">
          {/* invoice header */}
          <InvoiceHeader invoice={invoice} invoiceNo={invoiceNo} />
          {/* invoice body */}
          <div className="card-body">
            <AdHocBillingClientDetails />
            {/* billing details table */}
            <div className={`table-responsive-sm ${styles.invoiceTable}`}>
              <table className={`table table-striped`}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Service Name</th>
                    <th>Description</th>
                    <th style={{ width: "100px" }}>Amount</th>
                    <th style={{ width: "100px" }}></th>
                    <th style={{ width: "100px" }}>Total</th>
                    <th style={{ width: "100px" }}></th>
                  </tr>
                </thead>
                <tbody
                  style={{ verticalAlign: "middle" }}
                  className="primary-font-color"
                >
                  <>
                    <tr>
                      <td>1</td>
                      <td>
                        {editable && !isPreview ? (
                          <textarea
                            className="w-100"
                            rows={1}
                            type="text"
                            value={invoice.serviceName}
                            onChange={(e) =>
                              handleInputChange(e, "serviceName")
                            }
                          />
                        ) : (
                          <span>
                            {invoice.serviceName || " No service name"}
                          </span>
                        )}
                      </td>

                      <td>
                        {editable && !isPreview ? (
                          <textarea
                            className="w-100"
                            rows={1}
                            type="text"
                            value={invoice.description}
                            onChange={(e) =>
                              handleInputChange(e, "description")
                            }
                          />
                        ) : (
                          <span>{invoice.description || "No Description"}</span>
                        )}
                      </td>

                      <td>
                        {editable && !isPreview ? (
                          <input
                            type="number"
                            value={invoice.amount}
                            onChange={(e) => handleInputChange(e, "amount")}
                          />
                        ) : (
                          <span>£ {invoice.amount}</span>
                        )}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        ></div>
                      </td>
                      <td>£ {invoice.amount}</td>
                      {!isPreview ? (
                        <td>
                          <div className="d-flex justify-content-around gap-2">
                            <div onClick={handleEditBillingRateClick}>
                              <EditSVG />
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-5">
                <div className="pt-3 d-flex flex-column gap-3">
                  <div className="form-group">
                    <label className={styles.heading}>Payment Status: </label>
                    {!isPreview ? (
                      <>
                        <Select
                          name="billingService"
                          className="react-select-custom-styling__container"
                          classNamePrefix="react-select-custom-styling"
                          isClearable={false}
                          onChange={(option) => {
                            setInvoice((prev) => ({
                              ...prev,
                              paymentStatus: option,
                            }));
                          }}
                          value={invoice.paymentStatus}
                          options={paymentOptions}
                        />
                      </>
                    ) : (
                      <span
                        className={`text mb-0 ${styles.status} ${
                          invoice.paymentStatus?.value === "3"
                            ? styles.statusGreen
                            : invoice.paymentStatus?.value === "2"
                            ? styles.statusYellow
                            : styles.statusRed
                        }`}
                      >
                        {invoice.paymentStatus?.label ?? "Unpaid"}
                      </span>
                    )}
                  </div>
                  {!isPreview && invoice.paymentStatus?.value === "2" && (
                    <div className="form-group">
                      <label className={styles.heading}>
                        Client has Paid:{" "}
                      </label>
                      <input
                        value={invoice.clientHasPaid}
                        onChange={(e) =>
                          setInvoice((prev) => ({
                            ...prev,
                            clientHasPaid: +e.target.value,
                          }))
                        }
                        max={invoice?.finalTotal}
                        min={0}
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </div>
                  )}

                  {!isPreview && (
                    <div className="form-group">
                      <label className={styles.heading}>Tax: </label>
                      <Select
                        name="billingService"
                        className="react-select-custom-styling__container"
                        classNamePrefix="react-select-custom-styling"
                        isMulti
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={invoice.tax}
                        onChange={(option) => {
                          setInvoice((prev) => ({ ...prev, tax: option }));
                        }}
                        options={taxOptions}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* Invoice Payment and Final Calculations */}
              <div className="col-lg-4 col-sm-5 ml-auto">
                <table className={`table table-clear ${styles.invoiceTable}`}>
                  <tbody>
                    <tr>
                      <td className={styles.heading}>Subtotal</td>
                      {(isInvoicePreview || isPreview) &&
                      invoice.isInvoiceGenerated ? (
                        <td>£{invoice?.amount}</td>
                      ) : (
                        <td>£{invoice?.amount}</td>
                      )}
                    </tr>
                    {invoice.tax.map((tax, index) => (
                      <tr key={index}>
                        <td>{tax.label}</td>
                        {/* <td>£{Math.round((tax.value * invoice.amount) / 100)}</td>
                         */}
                        <td>
                          £{((tax.value * invoice.amount) / 100).toFixed(2)}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td className={styles.heading}>Total</td>
                      <td className={styles.heading}>£{invoice?.finalTotal}</td>
                    </tr>
                    {isPreview && invoice.paymentStatus?.value === "2" ? (
                      <>
                        <tr>
                          <td className={styles.heading}>Already Paid</td>
                          <td className={styles.heading}>
                            {/* £
                            {invoice.clientHasPaid
                              ? invoice.clientHasPaid
                              : invoice.partialPaidAmount} */}
                            £
                            {invoice.clientHasPaid
                              ? invoice.clientHasPaid.toFixed(2)
                              : invoice.partialPaidAmount.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles.heading}>Due Amount</td>
                         
                            <td className={styles.heading}>
                              £
                              {!invoice.isInvoiceGenerated
                                ? (
                                    invoice.finalTotal - invoice.clientHasPaid
                                  ).toFixed(2)
                                : invoice.dueAmount
                                ? invoice.dueAmount.toFixed(2)
                                : "0.00"}
                            </td>
                            {/* {console.log("invoice.dueAmount",invoice.dueAmount)} */}
                            {/* £
                            {!invoice.isInvoiceGenerated
                              ? invoice?.finalTotal - invoice.clientHasPaid
                              : invoice?.dueAmount} */}
                   
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* invoice footer */}
          <InvoiceFooter
            isPreview={isPreview}
            invoice={invoice}
            setInvoice={setInvoice}
          />
        </div>
      </section>
    </div>
  );
};

export default AdHocBillingInvoice;
