import React, { useContext, useEffect, useState } from "react";

import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdHocContent from "./AdHocContent";

const AdHoc = () => {
  const {
    getAllBillingServices,
    getAllAdHocClients,
    initialState,
    initialStateLoading,
  } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      setPageSize(10);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };
    // Reset pagination on component mount
    setCurrentPage(1);
    setPageSize(10);
    localStorage.setItem("currentPage", 1);
    localStorage.setItem("pageSize", 10);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      // Clear localStorage values when component unmounts
      localStorage.removeItem("currentPage");
      localStorage.removeItem("pageSize");
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleInputChange = (e) => {
    const trimmedInput = e.target.value;
    setSearchInput(trimmedInput);
    setSearchTriggered(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInput === "") {
      setSearchTriggered(false);
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    // Check if `searchInput` is empty and call the API immediately.
    if (searchInput === "" && !searchTriggered) {
      getAllAdHocClients(currentPage, pageSize, "");
      return; // Exit the useEffect early to skip the setTimeout.
    }

    // Otherwise, use the setTimeout for delayed API calls.
    const timer = setTimeout(() => {
      if (searchTriggered && searchInput !== "") {
        getAllAdHocClients(currentPage, pageSize, searchInput);
      }
    }, 500);

    // Cleanup the timer to avoid memory leaks.
    return () => clearTimeout(timer);
  }, [currentPage, pageSize, searchInput, searchTriggered, isUpdated]);

  return (
    <AdHocContent
      adHocClientsList={initialState.adHocClientsList}
      isLoading={initialStateLoading?.getAllAdHocClientsIsLoading}
      setIsUpdated={setIsUpdated}
      totalPages={initialState?.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      handleInputChange={handleInputChange}
    />
  );
};

export default AdHoc;
