import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import PageHeader from "../../../../templates/PageHeader";
import ReportsTable from "../../components/ReportsTable";
import ClientWiseTable from "./ClientWiseTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import {
  DownloadSVG,
  fileIcon,
  reportsIcon,
  userIcon1,
} from "../../../../utils/ImportingImages/ImportingImages";
import { ClientWiseReportModal } from "./ClientWiseReportModel";
import { ClientWiseInvoiceListModal } from "./ClientWiseInvoiceListModel";
import { CSVLink } from "react-csv";

// Bread Crumbs
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  // {
  //   pageName: "Reports",
  //   pageURL: "/reports",
  // },
  {
    pageName: "Client Wise",
    pageURL: "/client-wise",
  },
];

// constructing Headers for React Skelton
const columnHeaders = ["Sr no.", "Client Code", "Name", "Email ID", "Action"];

// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Client Code", key: "client_code" },
    { label: "Email ID", key: "email" },
    { label: "Name", key: "name" },
  ],
  fileName: "Clients List",
};

const ClientWiseContent = ({
  clientsReportData,
  setIsUpdated,
  isLoading,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  setPageSize,
  searchQuery,
  onSearchChange,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_no",
    },
    {
      Header: "Client Code",
      accessor: "client_code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email ID",
      accessor: "email",
      Cell: ({ row }) =>
        JSON.parse(row.original?.additional_data)?.primary_email,
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            // style={{ marginRight: "19%", cursor: "pointer" }}
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Show more"
            data-tooltip-place="top"
          >
            <ClientWiseReportModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
          <Tooltip
            id="invoice-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            style={{ cursor: "pointer" }}
            data-tooltip-id="invoice-client-tooltip"
            data-tooltip-content="Get Invoices"
            data-tooltip-place="top"
          >
            <ClientWiseInvoiceListModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);

  const data = useMemo(() => clientsReportData, [clientsReportData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  //************************* */
  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
    setIsUpdated((prev) => !prev);
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    tableInstance.setPageSize(newSize);
    setCurrentPage(1);
    localStorage.setItem("pageSize", newSize);
    localStorage.setItem("currentPage", 1);
    setIsUpdated((prev) => !prev);
  };
  useEffect(() => {
    tableInstance.setPageSize(pageSize);
  }, [pageSize, tableInstance]);

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}

      <div className="d-flex justify-content-between align-items-center  mr-40 ml-30 mb-15 ">
        {/* Title on the Left */}
        <div className="d-flex justify-content-start align-items-center page-heading">
          <img src={fileIcon} alt="" />
          <p className="m-0 fs-4">Client Wise</p>
        </div>

        {/* Right side: Search bar and Button */}
        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ width: "80%" }}
        >
          <div className="relative-wrapper w-25">
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search..."
              style={{ width: "90%" }}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Clients Table  */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : (
        <>
          <ClientWiseTable
            tableInstance={tableInstance}
            headers={headers}
            clientWise={clientsReportData}
          />
          <div
            className=" mt-4 d-flex justfy-content-center align-items-center gap-3 smallText"
            style={{
              width: "78%",
              justifyContent: "space-between",
              position: "absolute",
              marginLeft: "90px",
              bottom: "18px",
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              <button
                className="cursor-pointer"
                style={{ border: "none", background: "transparent" }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
              <input
                className="gotoPage-input-field"
                type="number"
                defaultValue={currentPage}
                readOnly
              />
              <span className="px-2">/</span>

              <span> {totalPages}</span>
              <button
                style={{ border: "none", background: "transparent" }}
                className="cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  clientsReportData?.length < pageSize || totalPages === 1
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="px-1 smallText">Rows /page</span>
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <span>Download List</span>
              <span>
                {data ? (
                  <CSVLink
                    data={data}
                    headers={headers?.headings}
                    filename={`${headers.fileName}.csv`}
                  >
                    <DownloadSVG />
                  </CSVLink>
                ) : null}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientWiseContent;
