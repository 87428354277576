import React, { useState, useEffect, useContext, useCallback } from "react";

import JobsContent from "./components/JobsContent";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { debounce } from "lodash";

const MemberOrTeamLeaderJobs = () => {
  const {
    getJobsDetailsByMemberIdPagination,
    initialState,
    initialStateLoading,
  } = useContext(ContextAPI);

  const [isUpdated, setIsUpdated] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    status: null,
  });
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      getJobsDetailsByMemberIdPagination(currentPage, pageSize, query, filters);
    }, 500),
    [currentPage, pageSize, filters]
  );

  // Trigger the debounced search whenever the search input changes
  useEffect(() => {
    if (searchQuery !== "") {
      debouncedSearch(searchQuery);
    } else {
      getJobsDetailsByMemberIdPagination(currentPage, pageSize, "", filters);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, currentPage, pageSize, filters, debouncedSearch, isUpdated]);

  // Handle filter changes
  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
    setCurrentPage(1);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);
  // Handle search changes
  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };
  return (
    <JobsContent
      jobsData={initialState?.myJobs?.my_single_jobs || []}
      setIsUpdated={setIsUpdated}
      isLoading={
        initialStateLoading?.getJobsDetailsByMemberIdPaginationIsLoading
      }
      totalPages={initialState?.myJobs.totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      searchQuery={searchQuery}
      onSearchChange={handleSearchChange}
      filters={filters}
      onFilterChange={handleFilterChange}
    />
  );
};

export default MemberOrTeamLeaderJobs;
