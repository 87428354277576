import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { EyeIconWithCircle } from "../../../../utils/ImportingImages/ImportingImages";
import axios from "axios";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import {
  formatDate,
  handleAPIError,
  headerOptions,
} from "../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../Components/ReactHotToast/ReactHotToast";
import { AsyncPaginate } from "react-select-async-paginate";

const MyVerticallyCenteredModal = (props) => {
  const { mainURL, initialState, userDetails } = useContext(ContextAPI);
  const { teamWiseData, selectedTeamId } = props;
  const [parentJobFilterData, setParentJobFilterData] = useState([]);
  const [
    parentAdvancedBillingJobFilterData,
    setParentAdvancedBillingJobFilterData,
  ] = useState([]);

  const userID = localStorage.getItem("userId") || userDetails?.member_id;

  const [updatedTeamData, setUpdatedTeamData] = useState({
    jobs: initialState?.singleTeam?.assigned_jobs_list,
    advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
  });

  useEffect(() => {
    if (Object.entries(initialState)) {
      setUpdatedTeamData({
        jobs: initialState?.singleTeam?.assigned_jobs_list,
        advancedBillingJobs: initialState?.singleTeam?.advance_billing_list,
        selectedJob: null,
        selectedAdvacedBillingJob: null,
      });
    }
  }, [initialState?.singleTeam]);

  const getadvacedBillingJobDetails = () => {
    return parentAdvancedBillingJobFilterData?.find(
      (job) =>
        job?.task_id === updatedTeamData?.selectedAdvacedBillingJob?.value
    );
  };

  const getJobDetails = () => {
    return parentJobFilterData?.find(
      (job) => job?.task_id === updatedTeamData?.selectedJob?.value
    );
  };
  // =====================================================

  const handleAdvanceBillingJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedAdvacedBillingJob: option,
    }));
  };
  const handleAssignJobSelectFunction = (option) => {
    setUpdatedTeamData((prev) => ({
      ...prev,
      selectedJob: option,
    }));
  };

  // =====================================================================
  // =====================================================================
  // async paginate

  const [selectedAssignedJobValue, setSelectedAssignedJobValue] = useState("");
  const [selectedAdvancedJobValue, setSelectedAdvancedJobValue] = useState("");

  async function assignJobLoadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${selectedTeamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "billable_hours",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      console.log("responseAssign", response);

      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${
              item.assigned_on || "N/A"
            } Period End Date: ${item.due_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAssignJobs = response.data.filterData.data;

      let combinedAssignedjobs = [...parentJobFilterData, ...newAssignJobs];
      setParentJobFilterData(combinedAssignedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  async function advanceBillingloadOptions(search, loadedOptions, { page }) {
    try {
      const response = await axios.get(
        `${mainURL}get/team-jobs/${userID}/${selectedTeamId}`,
        {
          params: {
            page,
            limit: 10,
            task_type: "advance_billing",
            searchQuery: search || "",
          },
          headers: headerOptions(),
        }
      );

      // Defensive parsing with fallback values
      const newOptions = response.data.filterData.data
        .map((item) => {
          try {
            // Safely parse additional_data
            const additionalData = item.additional_data
              ? JSON.parse(item.additional_data)
              : {};

            const label = `${additionalData.bpo_no || "N/A"} - ${
              item.task_name || "N/A"
            } (Period Start Date: ${item.assigned_on || "N/A"})`;

            return {
              value: item.task_id,
              label: label,
            };
          } catch (parseError) {
            console.error("Error parsing item:", parseError);
            return {
              value: item.task_id,
              label: "Parsing Error - Invalid Job Data",
            };
          }
        })
        .filter((option) => option.value);

      // Combine options for pagination
      const combinedOptions =
        page === 1
          ? newOptions
          : [...(loadedOptions.options || []), ...newOptions];

      const newAdvancedJobs = response?.data?.filterData?.data;

      let combinedAdvancedjobs = [
        ...parentAdvancedBillingJobFilterData,
        ...newAdvancedJobs,
      ];
      setParentAdvancedBillingJobFilterData(combinedAdvancedjobs);

      return {
        options: combinedOptions,
        hasMore: response.data.filterData.has_more,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Comprehensive Error Loading Options:", {
        message: error.message,
        response: error.response,
        request: error.request,
      });

      return {
        options: loadedOptions.options || [],
        hasMore: false,
      };
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-xl"
      style={{ height: "100%" }}
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
        <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <span className="modal-title">Team Wise Report Data</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div
          className="d-flex flex-column justify-content-center"
          style={{ padding: "10px" }}
        >
          <div className="flex">
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>Total Team Members</th>
                  <th>Team Member's Name</th>
                  <th>Team Leader Email</th>
                  <th>Total Time</th>
                  <th>Total Completed Jobs</th>
                  <th>Total Pending Jobs</th>
                  <th>On Hold Jobs</th>
            
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{teamWiseData?.member_count}</td>
                  <td>
                    <ul
                      style={{
                        height: "200px",
                        overflowY: "auto",
                        width: "187px",
                      }}
                    >
                      {teamWiseData?.member_names.split(", ").map((name) => (
                        <li
                          key={name}
                          style={{
                            listStyle: "none",
                            textAlign: "left",
                            marginBottom: "7px",
                          }}
                        >
                          {name.replace(/\(Leader\)$/, "")}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>{teamWiseData?.leader_email}</td>
                  <td>{teamWiseData?.total_time}</td>
                  <td>{teamWiseData?.completed_jobs}</td>
                  <td>{teamWiseData?.in_progress_jobs}</td>
                  <td>{teamWiseData?.on_hold_jobs}</td>
                </tr>
              </tbody>
            </Table>

            <div className="form-group mt-4">
              <label htmlFor="clientName">Assigned Jobs List:</label>
              {/* <Select
                isClearable={true}
                isSearchable={true}
                name="assignJobs"
                closeMenuOnSelect={true}
                options={assignedJobs}
                onMenuScrollToBottom={
                  updatedTeamData?.jobs?.has_more && handleMenuScrollToBottom
                }
                // onChange={(option) =>
                //   setUpdatedTeamData((prev) => ({
                //     ...prev,
                //     selectedJob: option,
                //   }))
                // }
                onChange={(option) => handleAssignJobSelectFunction(option)}
                value={updatedTeamData.selectedJob}
                onInputChange={(inputValue) => {
                  debouncedHandleSearch(inputValue, "assignJob");
                }}
                isLoading={isDropdownLoading?.assignJob}
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
              /> */}
              <AsyncPaginate
                isClearable
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={selectedAssignedJobValue}
                loadOptions={assignJobLoadOptions}
                onChange={(selectedOption) => {
                  // setUpdatedTeamData((prev) => ({
                  //   ...prev,
                  //   selectedJob: selectedOption,
                  // }));
                  setSelectedAssignedJobValue(selectedOption);
                  handleAssignJobSelectFunction(selectedOption);
                }}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300} // Prevent excessive API calls
                placeholder={`Select Assigned Job`}
                // Enhanced User Experience Features
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No Job found for "${inputValue}"`
                    : "No Job found"
                }
                onError={(error) => {
                  ReactHotToast("Error loading Job", "error");
                }}
                // Custom Styles for Better Readability
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>

            {updatedTeamData.selectedJob && (
              <Table bordered hover className="mt-2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-center">
                      <span className="fw-bold fs-5">Task Name : </span>
                      <span>{updatedTeamData.selectedJob?.label}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <Stack direction="horizontal">
                        {getJobDetails()?.job_status === "Completed" ? (
                          <Badge bg="success">Completed</Badge>
                        ) : getJobDetails()?.job_status === "On Hold" ? (
                          <Badge bg="danger">On Hold</Badge>
                        ) : (
                          <Badge bg="warning" text="dark">
                            In Progress
                          </Badge>
                        )}
                      </Stack>
                    </td>
                  </tr>
                  {getJobDetails()?.job_description && (
                    <tr>
                      <th>Job Description</th>
                      <td>{getJobDetails()?.job_description}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Period Start Date - Period End Date</th>
                    <td>
                      {formatDate(getJobDetails()?.assigned_on)} -{" "}
                      {formatDate(getJobDetails()?.due_on)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}

            <div className="form-group mt-4">
              <label htmlFor="advanceBillingJobs">
                Advanced Billing Job List:
              </label>
              {/* <Select
                isClearable={true}
                isSearchable={true}
                name="advanceBillingJobs"
                closeMenuOnSelect={true}
                options={advancedBillingJobOptions}
                onMenuScrollToBottom={
                  updatedTeamData?.advancedBillingJobs?.has_more &&
                  handleAdvancedJobMenuScrollToBottom
                }
                // onChange={(option) =>
                //   setUpdatedTeamData((prev) => ({
                //     ...prev,
                //     selectedAdvacedBillingJob: option,
                //   }))
                // }
                onChange={(option) =>
                  handleAdvanceBillingJobSelectFunction(option)
                }
                value={updatedTeamData?.selectedAdvacedBillingJob}
                onInputChange={(inputValue) => {
                  debouncedHandleSearch(inputValue, "advancedBillingJob");
                }}
                isLoading={isDropdownLoading?.advanceBillingJob}
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
              /> */}
              <AsyncPaginate
                isClearable
                className="react-select-custom-styling__container"
                classNamePrefix="react-select-custom-styling"
                value={selectedAdvancedJobValue}
                loadOptions={advanceBillingloadOptions}
                onChange={(selectedOption) => {
                  setSelectedAdvancedJobValue(selectedOption);
                  handleAdvanceBillingJobSelectFunction(selectedOption);
                }}
                additional={{
                  page: 1,
                }}
                placeholder={"Select job...."}
                debounceTimeout={300} // Prevent excessive API calls
                // Enhanced User Experience Features
                noOptionsMessage={({ inputValue }) =>
                  inputValue
                    ? `No jobs found for "${inputValue}"`
                    : "No jobs found"
                }
                onError={(error) => {
                  ReactHotToast("Error loading jobs", "error");
                  console.error("Async Paginate Error:", error);
                }}
                // Custom Styles for Better Readability
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007bff" : "white",
                    cursor: "pointer",
                    color: state.isSelected ? "white" : "black",
                    ":hover": {
                      backgroundColor: state.isSelected ? "#007bff" : "#f1f3f5",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "black",
                  }),
                  clearIndicator: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>

            {updatedTeamData?.selectedAdvacedBillingJob && (
              <Table bordered hover className="mt-2">
                <tbody>
                  <tr>
                    <td colSpan={2} className="text-center">
                      <span className="fw-bold fs-5">Task Name : </span>
                      <span>
                        {updatedTeamData.selectedAdvacedBillingJob?.label}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <Stack direction="horizontal">
                        {getadvacedBillingJobDetails()?.job_status ===
                        "Completed" ? (
                          <Badge bg="success">Completed</Badge>
                        ) : getadvacedBillingJobDetails()?.job_status ===
                          "On Hold" ? (
                          <Badge bg="danger">On Hold</Badge>
                        ) : (
                          <Badge bg="warning" text="dark">
                            In Progress
                          </Badge>
                        )}
                      </Stack>
                    </td>
                  </tr>
                  {getadvacedBillingJobDetails()?.job_description && (
                    <tr>
                      <th>Job Description</th>
                      <td>{getadvacedBillingJobDetails()?.job_description}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Period Start Date</th>
                    <td>
                      {formatDate(getadvacedBillingJobDetails()?.assigned_on)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const TeamWiseReportModal = ({ teamWiseData, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const handleTeamClick = (teamId) => {
    setSelectedTeamId(teamId);
    setModalShow(true);
  };
  return (
    <>
      <div className="d-flex" onClick={() => handleTeamClick(teamWiseData?.id)}>
        <div className="">
          <EyeIconWithCircle />
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectedTeamId={selectedTeamId}
        teamWiseData={teamWiseData}
        setIsUpdated={setIsUpdated}
      />
    </>
  );
};
